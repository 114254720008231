var document_ready = function() {
  submit_quick_registration();
};

const submit_quick_registration = function () {
  $("#report_channel_service_grade").prop('selectedIndex',1);
  
  $("#confirm_registration").on("click", function () {
    if (valid_form_fields()) {
      document.getElementById("installer_name").textContent = $("#user_name").val();
      document.getElementById("tvr_sid").textContent = $("#ultralink_sid").val();
      document.getElementById("tvr_sg").textContent = $("#report_channel_service_grade").val();
      $(".quick-registration").modal({ dynamic: true });
      return focus_modal_elem($(".quick-registration"));
    }
  });
  
  $("#submit_registration").on("click", function () {
    $(".quick-registration").modal("hide");
    $(".quick_registration.spinner").show();
    if (valid_form_fields()) {
      $("#submit_registration").prop( "disabled", true);
      $.ajax({
        url: "/quick_registrations",
        type: "POST",
        data: $("#quick_registration_form").serialize(), 
        success(data) {
          $(".quick_registration.spinner").hide();
          $("div#message-area").show();
          $("#submit_registration").prop( "disabled", false);
          if (data.result.status == true ) {
            $("#quick_registration_form")[0].reset();
            show_alert(data.result.message);
            $("div#message-area").toggleClass("alert-info", true);
          } else {
            show_error(data.result.message);
          }
        }
      });
    } else {
      $("#submit_registration").prop( "disabled", false);
      $("div#message-area").hide();
      $(".quick_registration.spinner").hide();
      return false;
    }
  });
}

var valid_form_fields = function () {
  result_value = 0
  var password_val = $("#user_password").val();
  var elements = document.querySelectorAll("#quick_registration_form input[type=text]")
  for (var i = 0; i<elements.length; i++ ) {
    if (elements[i].value == "") {
      result_value = result_value + 1
      if ($('#' + elements[i].id).next().hasClass('has-error') == false) {
        $('#' + elements[i].id).parent().addClass(" has-error");
        $('#' + elements[i].id).after("<div class='has-error'><span class='help-block'>" + I18n.t("not_blank") + "</span></div>" );
      }
    } else {
      if ($('#' + elements[i].id).next().hasClass('has-error') == true) {
        $('#' + elements[i].id).parent().removeClass("has-error");
        $('#' + elements[i].id).next().remove();
      }
    }
  }
  return (result_value == 0) && (password_val.length > 0)
}

jQuery(function() {
  run_ontag('quick_registrations', function() {
    document_ready();    
  });
});

