require("jquery-ui");
require("jquery-ui/ui/widget");
require("jquery-ui/ui/position");
require("jquery-ui/ui/data");
require("jquery-ui/ui/keycode");
require("jquery-ui/ui/scroll-parent");
require("jquery-ui/ui/unique-id");
require("jquery-ui/ui/widgets/sortable");
require("jquery-ui/ui/widgets/autocomplete");
require("jquery-ui/ui/widgets/menu");
require("jquery-ui/ui/widgets/mouse");
require("jquery-ui/ui/widgets/progressbar");