/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

const display_src_error = () =>
  $("div.batch_service_request_source_company_autocomplete").addClass(
    "has-error"
  );

const remove_src_error = () =>
  $("div.batch_service_request_source_company_autocomplete").removeClass(
    "has-error"
  );

const remove_sg_error = () =>
  $("div.batch_service_request_service_grade_autocomplete").removeClass(
    "has-error"
  );

const toggle_submit_button = function (checked) {
  if (checked) {
    return $("#new-form-submit-batch-service-request").attr("disabled", false);
  } else {
    return $("#new-form-submit-batch-service-request").attr("disabled", true);
  }
};

const batch_service_request_new_init = function () {
  const request_type = $("#batch_service_request_request_type").val();
  const requests_json = JSON.parse($("#request_values").val());
  const transfer_account = requests_json["transfer_account"]["value"];
  const deactivate_dealer_sites =
    requests_json["deactivate_dealer_sites"]["value"];
  const reactivate_dealer_sites =
    requests_json["reactivate_dealer_sites"]["value"];
  const deactivate_sub_distributor_sites =
    requests_json["deactivate_sub_distributor_sites"]["value"];
  const reactivate_sub_distributor_sites =
    requests_json["reactivate_sub_distributor_sites"]["value"];
  const change_format = requests_json["change_format"]["value"];
  const change_agency_reporting =
    requests_json["change_agency_reporting"]["value"];
  const move_company_group = requests_json["move_company_group"]["value"];
  const change_service_grade = requests_json["change_service_grade"]["value"];
  $("#sid_label").text(I18n.t('sid') + '/' + I18n.t('acctno'));
  $('input[data-role="tagsinput"]').tagsinput();

  var sg_inputtag = $('#sg_tagsinput')
  sg_inputtag.focus();
  sg_inputtag.tagsinput({
    maxTags: 100,
    placeholder: I18n.t("change_sg_label_sidacct", {max_limit: 100} ),
    delimiter: [',', ';']
  });

  sg_inputtag.on('itemAdded', function(event) {
    // To make agree checkbox disabled
    const req_checkbox = $("#batch_service_request_agree_change_service_grade");
    if($("#batch_service_request_service_grade_autocomplete").val() === "") {
      req_checkbox.prop("disabled", true);
    } else {
      req_checkbox.prop("disabled", false);
    }
  });

  sg_inputtag.on('itemRemoved', function(event) {
    // To make agree checkbox disabled
    // If its last tag check service grade value present or not
    var tagsArray = sg_inputtag.tagsinput('items');
    const req_checkbox = $("#batch_service_request_agree_change_service_grade");
    if(tagsArray.length == 0){
      $('.tagsinput_acct').attr('placeholder', I18n.t("change_sg_label_sidacct", {max_limit: 100}) );
      $('.tagsinput_acct').attr('style', 'width: 25em !important; height: 30px !important; color:  #555555 !important; font-size: 10px;');
      req_checkbox.prop("disabled", true);
    }
  });

  $(".bootstrap-tagsinput").on('focusout', function () {
    var tagsArray = sg_inputtag.tagsinput('items');
    const req_checkbox = $("#batch_service_request_agree_change_service_grade");
    if(tagsArray.length > 0)
    {
      $(".service_request_sg").show();
      sg_inputtag.prop('disabled', true);
      $("#new-form-submit-batch-service-request").attr("disabled", true);
      $.ajax({
        url: "/batch_service_requests/validate_filter_inputs",
        type: "GET",
        data: {
          batch_service_requests: {
            tagsinput: tagsArray
          },
        },
        success(data, status, xhr) {
          if (!sessionExpired(xhr)) {
            remove_sid_acts_error();
            $(".service_request_sg").hide();
            sg_inputtag.prop('disabled', false);
            if(data.invalid_tags.length > 0)
            {
              $.each(data.invalid_tags, function (index, value) {
                changeTagColor(value)
              })
            }
            $('.bootstrap-tagsinput').removeClass('bootstrap_tagsinput_error_msg');
            if ($("#batch_service_request_service_grade_autocomplete").val() !== "" &&
                                    $("#batch_service_request_agree_change_service_grade").prop("checked")){
              $("#new-form-submit-batch-service-request").attr("disabled", false);
            }
          }
        },
        error: function (xhr, status, error) {
          console.log("ajax failure")
        }
      })
    }else {
      $('.tagsinput_acct').attr('placeholder', I18n.t("change_sg_label_sidacct", {max_limit: 100}) );
      $('.tagsinput_acct').attr('style', 'width: 25em !important; height: 30px !important; color:  #555555 !important; font-size: 10px;');
    }
  });

  function changeTagColor(tagValue) {
    $('.bootstrap-tagsinput span:contains("'+ tagValue +'")').attr('style', 'background-color: red !important; color: white;');
  }

  $('.tagsinput_acct').on('contextmenu', function(e) {
    e.preventDefault();
  });

  var tagsInput = $('input[data-role="tagsinput"]');
  // $("#clearButton").hide();
  updateCounter();
  tagsInput.on('itemAdded', function(event) {
    var addedValue = event.item;
    counter++;
    updateCounter();
  });

  tagsInput.on('itemRemoved', function(event) {
    var removedValue = event.item;
    if(counter > 0){
      counter--;
      updateCounter();
    }
  });

  // $('#clearButton').off("click").on("click", function (e) {
  //   var tagsInput = $('input[data-role="tagsinput"]');
  //   tagsInput.tagsinput('removeAll');
  //   counter = 0
  //   updateCounter();
  // });

  $('#validate_accounts').off("click").on("click", function (e) {
    var tagsInput = $('input[data-role="tagsinput"]');
    // var tags = tagsInput.tagsinput('items');
    var sid_acct = $("#filter_valid_sids").val();
    var src_branch = $("#source_id").val();
    var source_type = $("#source_type").val();

    if(sid_acct !== "" && src_branch !== "")
    {
      $.ajax({
        url: "/batch_service_requests/validate_filter_sids",
        type: "GET",
        data: {
          batch_service_requests: {
            source_id: src_branch,
            source_type: source_type,
            sids: sid_acct
          },
        },
        success(data, status, xhr) {
          if (!sessionExpired(xhr)) {
            $('.tagsinput_acct').attr('placeholder', '' );
            $("#msg_sid_validation").css("color", "red");
            if (data["invalid_source"] !== "") {
              $("#msg_sid_validation").text(data["invalid_source"]);
            }
            if (data["valid_sid"] !== "")  {
              $("#msg_sid_validation").text("")
              $("#filter_valid_sids").val('');
              var tagsInput = $('input[data-role="tagsinput"]');
              tagsInput.tagsinput('add', data["valid_sid"]);
            } else {
              $("#msg_sid_validation").text( I18n.t("invalid_input") );
            }
          }
        },
      });
    }
  });

  set_title();
  detect_backspace();
  remove_autocomplete_results();
  if (
    request_type === transfer_account.toString() ||
    request_type === change_agency_reporting.toString()
  ) {
    prepare_account_transfer();
  }
  if (
    request_type === deactivate_dealer_sites.toString() ||
    request_type === reactivate_dealer_sites.toString() ||
    request_type === deactivate_sub_distributor_sites.toString() ||
    request_type === reactivate_sub_distributor_sites.toString()
  ) {
    prepare_deactivate_reactivate();
  }
  if (request_type === change_format.toString()) {
    return prepare_change_format();
  }
  if (request_type === move_company_group.toString()) {
    prepare_move_company_group();
  }
  if (request_type === change_service_grade.toString()) {
    prepare_change_service_grade();
  }
};

var counter = 0;
function updateCounter() {
  if (counter > 0) {
    // $("#clearButton").show();
    var tagsInput = $('input[data-role="tagsinput"]');
    var tags = tagsInput.tagsinput('items');
    // if(!($("#batch_service_request_source_company_autocomplete").val() == "") && tags.length > 0 ) {
    //   $("#validate_accounts").show();
    // }

  } else {
    // $("#clearButton").hide();
    // $("#validate_accounts").hide();
  }
}

var remove_autocomplete_results = () =>
  $("body").on(
    "keyup",
    "#batch_service_request_source_company_autocomplete, #batch_service_request_destination_company_autocomplete",
    function (e) {
      if ($(this).val().length === $(this).attr("min-length") - 1) {
        var tagsInput = $('input[data-role="tagsinput"]');
        var tags = tagsInput.tagsinput('items');
        // if(!($("#batch_service_request_source_company_autocomplete").val() == "") && tags.length > 0 ) {
        //   $("#validate_accounts").show();
        // }
        return $(this).autocomplete("close");
      }
    }
  );

var detect_backspace = () =>
  $("body").on(
    "keydown",
    "#batch_service_request_source_company_autocomplete, #batch_service_request_destination_company_autocomplete",
    function (e) {
      if (e.keyCode === 8 || e.keyCode === 46) {
        return $(this).autocomplete("disable");
      } else {
        return $(this).autocomplete("enable");
      }
    }
  );

var set_title = function () {
  const title = $("#request_type_name").val();
  return $("#modal-header-new-batch-service-request").text(title);
};

var prepare_change_format = function () {
  const src_branch = "#modal-body-new-batch-service-request #source_id";
  const req_checkbox = $("#batch_service_request_agree_change_format");
  const modal_format = $(
    "#modal-body-new-batch-service-request #batch_service_request_format"
  );
  req_checkbox.attr("disabled", true);
  const submit_request = $("#new-form-submit-batch-service-request");
  submit_request.attr("disabled", true);
  modal_format.attr("disabled", true);

  submit_request.off("click").on("click", function (e) {
    if ($(src_branch).val() === "") {
      display_src_error();
      return e.preventDefault();
    } else if (modal_format.val() === "") {
      display_format_error();
      return e.preventDefault();
    } else {
      remove_src_error();
      remove_format_error();
      if ($("#batch_service_request_agree_change_format").is(":checked")) {
        $("#modal-body-new-batch-service-request form").submit();
        return avoid_multiple_submit(
          "new_batch_service_request",
          "new-form-submit-batch-service-request"
        );
      }
    }
  });

  $("#batch_service_request_source_company_autocomplete").on(
    "railsAutocomplete.select",
    function (e, data) {
      if (data.item.label === I18n.t("no_results_found")) {
        $("#batch_service_request_source_company_autocomplete").val("");
        $("#batch_service_request_source_company_autocomplete").attr(
          "placeholder",
          I18n.t("autocomplete_source_company_message")
        );
        return false;
      } else {
        const src_agency_id = data.item["source-agency-id"];
        const src_type = data.item["source-type"];
        $("#source_agency_id").val(src_agency_id);
        $("#source_type").val(src_type);
        req_checkbox.prop("disabled", false);
        return modal_format.attr("disabled", false);
      }
    }
  );

  $("body").on("change", "#batch_service_request_format", function () {
    req_checkbox.prop("disabled", false);
    if ($(modal_format).val() !== "") {
      return remove_format_error();
    }
  });

  return $("#batch_service_request_agree_change_format")
    .off("change")
    .on("change", function () {
      return toggle_submit_button($(this).prop("checked"));
    });
};

var display_format_error = () =>
  $("div.batch_service_request_format").addClass("has-error");

var remove_format_error = () =>
  $("div.batch_service_request_format").removeClass("has-error");

var prepare_deactivate_reactivate = function () {
  const src_branch = "#modal-body-new-batch-service-request #source_id";
  const req_checkbox = $(
    "#batch_service_request_agree_deactivation_reactivation"
  );
  req_checkbox.attr("disabled", true);
  const submit_request = $("#new-form-submit-batch-service-request");
  submit_request.attr("disabled", true);

  submit_request.off("click").on("click", function (e) {
    if ($(src_branch).val() === "") {
      display_src_error();
      return e.preventDefault();
    } else {
      remove_src_error();
      if (
        $("#batch_service_request_agree_deactivation_reactivation").is(
          ":checked"
        )
      ) {
        $("#modal-body-new-batch-service-request form").submit();
        return avoid_multiple_submit(
          "new_batch_service_request",
          "new-form-submit-batch-service-request"
        );
      }
    }
  });

  return $("#batch_service_request_source_company_autocomplete").on(
    "railsAutocomplete.select",
    function (e, data) {
      if (data.item.label === I18n.t("no_results_found")) {
        $("#batch_service_request_source_company_autocomplete").val("");
        $("#batch_service_request_source_company_autocomplete").attr(
          "placeholder",
          I18n.t("autocomplete_source_company_message")
        );
        return false;
      } else {
        const src_agency_id = data.item["agency-id"];
        req_checkbox.prop("disabled", false);
        if ($("#source_id").val() !== null) {
          remove_src_error();
          $("#source_agency_id").val(src_agency_id);
        }
        return req_checkbox.off("change").on("change", function () {
          return toggle_submit_button($(this).prop("checked"));
        });
      }
    }
  );
};

var prepare_change_service_grade = function () {
  const modal_src_id =
    "#modal-body-new-batch-service-request #batch_service_request_source_company_autocomplete";
  const modal_sg =
    "#modal-body-new-batch-service-request #batch_service_request_service_grade_autocomplete";

  const req_checkbox = $("#batch_service_request_agree_change_service_grade");
  req_checkbox.attr("disabled", true);
  const submit_request = $("#new-form-submit-batch-service-request");
  submit_request.attr("disabled", true);
  change_autocomplete_placeholder("#batch_service_request_service_grade_autocomplete",
                          I18n.t('autocomplete_service_grade_message'), I18n.t('service_grade'))

  submit_request.off("click").on("click", function (e) {
    if ($(modal_src_id).val() === "") {
      display_src_error();
      return e.preventDefault();
    }else if ($(modal_sg).val() === "") {
      display_sg_error();
      return e.preventDefault();
    }else if ($('#sg_tagsinput').tagsinput('items').length == 0) {
      display_sid_acts_error();
      return e.preventDefault();
    }else {
      remove_src_error();
      remove_sg_error();
      remove_sid_acts_error();
      if ($("#batch_service_request_agree_change_service_grade").is(":checked")) {
        $("#modal-body-new-batch-service-request form").submit();
        return avoid_multiple_submit(
          "new_batch_service_request",
          "new-form-submit-batch-service-request"
        );
      }
    }
  });

  $("#batch_service_request_source_company_autocomplete").on(
    "railsAutocomplete.select",
    function (e, data) {
      if (data.item.label === I18n.t("no_results_found")) {
        $("#batch_service_request_source_company_autocomplete").val("");
        $("#batch_service_request_source_company_autocomplete").attr(
          "placeholder",
          I18n.t("autocomplete_source_company_message")
        );
        return false;
      } else {
        const src_type = data.item["source-type"];
        $("#source_type").val(src_type);
        req_checkbox.prop("disabled", false);
        $(modal_sg).prop("disabled", false);
      }
    }
  );


  $("#batch_service_request_service_grade_autocomplete").on(
    "railsAutocomplete.select",
    function (e, data) {
      remove_match_error();
      if (data.item.label === I18n.t("no_results_found")) {
        $("#batch_service_request_service_grade_autocomplete").val("");
        $("#batch_service_request_service_grade_autocomplete").attr(
          "placeholder",
          I18n.t("autocomplete_service_grade_message")
        );
        return false;
      } else {
        const change_grade_name = data.item["change-grade-name"];
        $("#change_grade_name").val(change_grade_name);
        if ($('#sg_tagsinput').tagsinput('items').length > 0) {
          req_checkbox.prop("disabled", false);
        }
      }
    }
  );

  return $("#batch_service_request_agree_change_service_grade")
    .off("change")
    .on("change", function () {
      return toggle_submit_button($(this).prop("checked"));
    });
};

var prepare_move_company_group = function() {
  const modal_src_id =
    "#modal-body-new-batch-service-request #batch_service_request_source_company_autocomplete";
  const modal_dest_id =
    "#modal-body-new-batch-service-request #batch_service_request_destination_company_autocomplete";
  const req_checkbox = $("#batch_service_request_agree_move_company");
  req_checkbox.attr("disabled", true);
  const submit_request = $("#new-form-submit-batch-service-request");
  submit_request.attr("disabled", true);

  submit_request.off("click").on("click", function (e) {
    if ($(modal_src_id).val() === "") {
      display_src_error();
      return e.preventDefault();
    }else if ($(modal_src_id).val() !== "" && $(modal_dest_id).val() !== "" &&
      $(modal_src_id).val() === $(modal_dest_id).val()){
      validate_branches_match(modal_src_id, modal_dest_id, e);
      return e.preventDefault();
    }else if ($(modal_dest_id).val() === "") {
      display_dest_branch_error();
      return e.preventDefault();
    }else {
      remove_src_error();
      remove_dest_branch_error();
      if ($("#batch_service_request_agree_move_company").is(":checked")) {
        $("#modal-body-new-batch-service-request form").submit();
        return avoid_multiple_submit(
          "new_batch_service_request",
          "new-form-submit-batch-service-request"
        );
      }
    }
  });

  $("#batch_service_request_source_company_autocomplete").on(
    "railsAutocomplete.select",
    function (e, data) {
      if (data.item.label === I18n.t("no_results_found")) {
        $("#batch_service_request_source_company_autocomplete").val("");
        $("#batch_service_request_source_company_autocomplete").attr(
          "placeholder",
          I18n.t("autocomplete_source_company_message")
        );
        return false;
      } else {
        const src_type = data.item["source-type"];
        $("#source_type").val(src_type);
        req_checkbox.prop("disabled", false);
        $(modal_dest_id).prop("disabled", false);
        return validate_branches_match(modal_src_id, modal_dest_id, e);
      }
    }
  );

  $("#batch_service_request_destination_company_autocomplete").on(
    "railsAutocomplete.select",
    function (e, data) {
      remove_match_error();
      if (data.item.label === I18n.t("no_results_found")) {
        $("#batch_service_request_destination_company_autocomplete").val("");
        $("#batch_service_request_destination_company_autocomplete").attr(
          "placeholder",
          I18n.t("autocomplete_destination_company_message")
        );
        return false;
      } else {
        req_checkbox.prop("disabled", false);
        return validate_branches_match(modal_src_id, modal_dest_id, e);
      }
    }
  );

  return $("#batch_service_request_agree_move_company")
    .off("change")
    .on("change", function () {
      return toggle_submit_button($(this).prop("checked"));
    });
}

var prepare_account_transfer = function () {
  const modal_src_id =
    "#modal-body-new-batch-service-request #batch_service_request_source_company_autocomplete";
  const modal_dest_id =
    "#modal-body-new-batch-service-request #batch_service_request_destination_company_autocomplete";
  const source_vfnn =
    "#modal-body-new-batch-service-request #batch_service_request_source_vfnn";
  const destination_vfnn =
    "#modal-body-new-batch-service-request #batch_service_request_destination_vfnn";

  const srcVfnnExists = $(source_vfnn).length > 0;
  const transfer_checkbox = $("#batch_service_request_agree_account_transfer");
  transfer_checkbox.attr("disabled", true);
  const submit_request = $("#new-form-submit-batch-service-request");
  submit_request.attr("disabled", true);

  if ($(modal_src_id).val() === "") {
    if (srcVfnnExists) {
      $(source_vfnn).attr("disabled", true);
    } else {
      $(modal_dest_id).attr("disabled", true);
    }
  }

  if (srcVfnnExists && $(source_vfnn).val() === "") {
    $(modal_dest_id).attr("disabled", true);
  }

  if ($(modal_dest_id).val() === "") {
    $(destination_vfnn).attr("disabled", true);
  }

  submit_request.off("click").on("click", function (e) {
    if ($(modal_src_id).val() === "") {
      display_src_error();
      if (srcVfnnExists && $(source_vfnn).val() !== null) {
        remove_src_vfnn_error();
      }
      return e.preventDefault();
    } else if (srcVfnnExists && $(source_vfnn).val() === null) {
      display_src_vfnn_error();
      return e.preventDefault();
    } else if (
      $(modal_dest_id).val() === "" ||
      ($(modal_dest_id).val() !== "" &&
        $(modal_src_id).val() === $(modal_dest_id).val())
    ) {
      display_dest_branch_error();
      if (srcVfnnExists && $(source_vfnn).val() !== null) {
        remove_src_vfnn_error();
      }
      return e.preventDefault();
    } else if ($(destination_vfnn).val() === null) {
      display_vfnn_error();
      if (srcVfnnExists && $(source_vfnn).val() !== null) {
        remove_src_vfnn_error();
      }
      return e.preventDefault();
    } else {
      remove_src_error();
      if (
        $(modal_src_id).val() === "" ||
        $(modal_dest_id).val() === "" ||
        $(modal_src_id).val() !== $(modal_dest_id).val()
      ) {
        remove_dest_branch_error();
      }
      remove_vfnn_error();
      if (srcVfnnExists && $(source_vfnn).val() !== null) {
        remove_src_vfnn_error();
      }
      remove_match_error();
      if ($("#batch_service_request_agree_account_transfer").is(":checked")) {
        $("#modal-body-new-batch-service-request form").submit();
        return avoid_multiple_submit(
          "new_batch_service_request",
          "new-form-submit-batch-service-request"
        );
      }
    }
  });

  $("#batch_service_request_source_company_autocomplete").on(
    "railsAutocomplete.select",
    function (e, data) {
      if (data.item.label === I18n.t("no_results_found")) {
        $("#batch_service_request_source_company_autocomplete").val("");
        $("#batch_service_request_source_company_autocomplete").attr(
          "placeholder",
          I18n.t("autocomplete_source_company_message")
        );
        return false;
      } else {
        const src_branch_agency_id = data.item["source-agency-id"];
        const src_type = data.item["source-type"];
        $("#source_agency_id").val(src_branch_agency_id);
        $("#source_type").val(src_type);

        if (srcVfnnExists) {
          $(source_vfnn).prop("disabled", false);
          return filter_vfnn_by_branch_id(src_branch_agency_id, source_vfnn);
        } else {
          $(modal_dest_id).prop("disabled", false);
          return validate_branches_match(modal_src_id, modal_dest_id, e);
        }
      }
    }
  );

  $("body").on("change", "#batch_service_request_source_vfnn", function (e) {
    if (srcVfnnExists && $(source_vfnn).val() !== null) {
      $(modal_dest_id).prop("disabled", false);
    }
    validate_branches_match(modal_src_id, modal_dest_id, e);
    return e.preventDefault();
  });

  $("#batch_service_request_destination_company_autocomplete").on(
    "railsAutocomplete.select",
    function (e, data) {
      remove_match_error();
      if (data.item.label === I18n.t("no_results_found")) {
        $("#batch_service_request_destination_company_autocomplete").val("");
        $("#batch_service_request_destination_company_autocomplete").attr(
          "placeholder",
          I18n.t("autocomplete_destination_company_message")
        );
        return false;
      } else {
        const dest_branch_agency_id = data.item["destination-agency-id"];
        const dest_type = data.item["destination-type"];
        $("#destination_agency_id").val(dest_branch_agency_id);
        $("#destination_type").val(dest_type);

        filter_vfnn_by_branch_id(dest_branch_agency_id, destination_vfnn);
        $(destination_vfnn).prop("disabled", false);
        remove_dest_branch_error();
        validate_branches_match(modal_src_id, modal_dest_id, e);
        return e.preventDefault();
      }
    }
  );

  $("body").on(
    "change",
    "#batch_service_request_destination_vfnn",
    function () {
      transfer_checkbox.prop("disabled", false);
      if ($(destination_vfnn).val() !== null) {
        return remove_vfnn_error();
      }
    }
  );

  return $("#batch_service_request_agree_account_transfer")
    .off("change")
    .on("change", function () {
      return toggle_submit_button($(this).prop("checked"));
    });
};

var validate_branches_match = function (modal_src_id, modal_dest_id, e) {
  if (
    $(modal_src_id).val() !== "" &&
    $(modal_src_id).val() === $(modal_dest_id).val()
  ) {
    if (
      !$("#batch_service_request_destination_company_autocomplete")
        .next()
        .hasClass("help-block")
    ) {
      display_match_error();
    }
    return e.preventDefault();
  } else if ($(modal_src_id).val() !== $(modal_dest_id).val()) {
    return remove_match_error();
  }
};

var filter_vfnn_by_branch_id = function (agency_id, vfnn) {
  const vfnn_select = $(vfnn);
  const vfnn_options = $(vfnn + " " + "option");
  let selected_vfnn = "";

  vfnn_options.each(function () {
    if ($(this).attr("data-agency-id") === agency_id.toString()) {
      if ($(this).prop("selected")) {
        selected_vfnn = $(this).val();
      }
      return $(this).prop("selected", false).show().prop("disabled", false);
    } else {
      return $(this).prop("selected", false).hide().attr("disabled", true);
    }
  });

  return vfnn_select.val(selected_vfnn).trigger("change");
};

var display_dest_branch_error = () =>
  $("div.batch_service_request_destination_company_autocomplete").addClass(
    "has-error"
  );

var display_sg_error = () =>
  $("div.batch_service_request_service_grade_autocomplete").addClass(
    "has-error"
  );

var display_vfnn_error = () =>
  $("div.batch_service_request_destination_vfnn").addClass("has-error");

var display_src_vfnn_error = () =>
  $("div.batch_service_request_source_vfnn").addClass("has-error");

var display_match_error = function () {
  $("div.batch_service_request_destination_company_autocomplete").addClass(
    "has-error"
  );
  return $(
    "input#batch_service_request_destination_company_autocomplete"
  ).after(
    "<span class='help-block'>" + I18n.t("similar_companies_error") + "</span>"
  );
};

var remove_dest_branch_error = () =>
  $("div.batch_service_request_destination_company_autocomplete").removeClass(
    "has-error"
  );

var remove_vfnn_error = () =>
  $("div.batch_service_request_destination_vfnn").removeClass("has-error");

var remove_src_vfnn_error = () =>
  $("div.batch_service_request_source_vfnn").removeClass("has-error");

var remove_match_error = function () {
  $("input#batch_service_request_destination_company_autocomplete")
    .next("span.help-block")
    .remove();
  return $(
    "div.batch_service_request_destination_company_autocomplete"
  ).removeClass("has-error");
};

var refresh = () =>
  $.get(
    "/batch_service_requests/display_request_completion.json",
    function (obj, status, xhr) {
      if (sessionExpired(xhr)) {
        return;
      }
      if (obj['flash_msg_txt'].length > 0) {
        return show_alert(obj['flash_msg_txt']);
      } else {
        return setTimeout(refresh, 15000);
      }
    }
  );

const document_ready = function () {
  const crud_options = {
    "batch-service-request": {
      new: batch_service_request_new_init,
    },
  };
  return init_datatable(crud_options);
};

var init_datatable = (crud_options) =>
  dt.initDatatable($("#batch-service-requests-table"), {}, crud_options);

jQuery(function () {
  run_ontag("batch_service_requests", function () {
    document_ready();
    if ($("#created").length) {
      setTimeout(refresh, 15000);
    }
    return $(document).on("pjax:end", document_ready);
  });
});
