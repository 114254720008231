/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var dateDiffDays,
  dateRangeCheck,
  hide_message,
  resetFilter,
  validate_error,
  validate_reset;

dateDiffDays = function (from, to) {
  var diff;
  diff = new Date(todate(to) - todate(from));
  return diff / 1000 / 60 / 60 / 24;
};

validate_error = function (msg) {
  $("#filter_alert").show_error(msg);
  return $('form#new_oh2000_event_export input[type="submit"]').attr(
    "disabled",
    "disabled"
  );
};

validate_reset = function () {
  $("#filter_alert").hide_msgs();
  return $('form#new_oh2000_event_export input[type="submit"]').prop(
    "disabled",
    false
  );
};

hide_message = function () {
  if ($("div#message-area div.alert-danger").length > 0) {
    return $("div#message-area div.alert-danger").hide();
  }
};

dateRangeCheck = function () {
  var end_input, start_input;
  start_input = $("#start_time").val();
  end_input = $("#end_time").val();
  if (start_input && end_input) {
    if (end_input < start_input) {
      hide_message();
      return validate_error(I18n.t("start_date_gt_end_date"));
    } else if (dateDiffDays(start_input, end_input) > 31) {
      hide_message();
      return validate_error(I18n.t("export_date_range"));
    } else {
      return validate_reset();
    }
  } else {
    return validate_reset();
  }
};

resetFilter = function () {
  var endDate, startDate;
  endDate = $(".datetimepicker-input").attr("data-range-end");
  startDate = $(".datetimepicker-input").attr("data-range-start");
  if ($(".datetimepicker-input").length > 0) {
    return $("#new_oh2000_event_export .datetimepicker")
      .datetimepicker({
        format: "YYYY-MM-DD HH:mm:ss",
        ignoreReadonly: true,
        maxDate: new Date(endDate.replace(/\s+/, "T")),
        minDate: new Date(startDate.replace(/\s+/, "T")),
        useCurrent: false
      })
      .on("dp.change", dateRangeCheck);
  }
};

jQuery(function () {
  return run_ontag("oh2000_event_exports", function () {
    resetFilter();
    if ($(".alert.alert-cookie").length > 0) {
      return $("#filter_alert .alert.alert-cookie").hide();
    }
  });
});
