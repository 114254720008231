/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
jQuery(function () {
  return run_ontag("ultralinks", function () {
    if ($(".ac-control").length) {
      $(".ac-control").slider();
      $(".front-door").on("switch-change", function (e, d) {
        return console.log(d.value);
      });
      $(".porch-light").on("switch-change", function (e, d) {
        return console.log(d.value);
      });
      return $(".ac-control").on("slideStop", function (ev) {
        return console.log(ev.value);
      });
    }
  });
});
