/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var initExport;

initExport = function () {
  return $("form#export-form").submit(function () {
    $('form#user_preference_form [name="_method"]').remove();
    $("form#user_preference_form :input")
      .not(":submit")
      .clone()
      .hide()
      .appendTo("form#export-form");
    $("form#filters :input")
      .not(":submit")
      .clone()
      .hide()
      .appendTo("form#export-form");
    return true;
  });
};

jQuery(function () {
  return run_ontag("customer_exports", function () {
    initExport();
    return preferences_init();
  });
});
