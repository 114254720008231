/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var init, marquee, previewInit, richEditorInit;

init = function () {
  delete_confirm_init("notices");
  $("#new-notice-btn")
    .off("click")
    .on("click", function (e) {
      return $.ajax({
        url: "/notices/new",
        success: function (data) {
          if (!sessionExpired(data)) {
            $("#new-notice").empty().html(data).modal();
            init_validation();
            richEditorInit();
            return previewInit();
          }
        },
      });
    });
  $(".notice-edit")
    .off("click")
    .on("click", function (e) {
      var rid;
      rid = $(this).attr("id").split("-")[1];
      return $.ajax({
        url: "/notices/" + rid + "/edit",
        success: function (data) {
          if (!sessionExpired(data)) {
            $("#edit-notice").empty().html(data).modal();
            init_validation();
            richEditorInit();
            return previewInit();
          }
        },
      });
    });
  return $(".notice-show")
    .off("click")
    .on("click", function (e) {
      var rid;
      rid = $(this).attr("id").split("-")[1];
      return $.ajax({
        url: "/notices/" + rid,
        success: function (data) {
          if (!sessionExpired(data)) {
            return $("#show-notice").empty().html(data).modal();
          }
        },
      });
    });
};

richEditorInit = function () {
  $("#notice_content").wysihtml5({
    image: false,
    color: true,
    html: true,
    stylesheets: ["/assets/wysiwyg-color.css"],
  });
  return $(".fileupload").fileupload({
    uploadtype: "image",
    name: "notice[icon]",
  });
};

marquee = "";

previewInit = function () {
  var preview_html;
  initToolTip("#preview_box");
  preview_html =
    '<div class="notice_block" id="preview_area"><img src="" class="notice_img"> <div class="notice_text"></div></div>';
  $("#notice-preview")
    .off("click")
    .on("click", function (e) {
      var icon;
      $("#preview_label").toggleClass("hide");
      $("#stop_label").toggleClass("hide");
      $("#preview_box div.controls").empty().html(preview_html);
      icon = "";
      if ($(".fileupload-preview.fileupload-exists img").length > 0) {
        icon = $(".fileupload-preview.fileupload-exists img").attr("src");
      } else if ($(".fileupload-new.thumbnail img").length > 0) {
        icon = $(".fileupload-new.thumbnail img").attr("src");
      }
      $("#preview_area").children("img").attr("src", icon);
      $("#preview_area div.notice_text").html($("#notice_content").val());
      return (marquee = $("#preview_area").marquee());
    });
  return $("#notice-stop")
    .off("click")
    .on("click", function (e) {
      $("#stop_label").toggleClass("hide");
      $("#preview_label").toggleClass("hide");
      return marquee.trigger("stop").remove();
    });
};
