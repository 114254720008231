/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var remove_disable, root, submit_control;

remove_disable = function () {
  return $(".btn-primary.user_preference_submit").prop("disabled", false);
};

submit_control = function () {
  $(".btn-primary.user_preference_submit").attr("disabled", "disabled");
  return $(".custom_filter").each(function () {
    if ($(this).val() !== "") {
      remove_disable();
    }
  });
};

root = window //typeof exports !== "undefined" && exports !== null ? exports : this;

root.preferences_init = function () {
  var active_pane, endDate, startDate;
  submit_control();
  $(".preferences").bootstrapduallistbox({
    showfilterinputs: false,
    infotext: false,
    nonselectedlistlabel: I18n.t("available_columns"),
    selectedlistlabel: I18n.t("displayed_columns"),
    infotextempty: "",
    selectorminimalheight: 120,
  });
  $(".preferences").on("change", function () {
    return remove_disable();
  });
  active_pane = ".tab-pane.active";
  $(".reset_pref")
    .off("click")
    .on("click", function () {
      $("#customer_filter_rule_country_code").prop("selectedIndex", 0);
      $("#customer_filter_rule_state").prop("selectedIndex", 0);
      $("#customer_filter_rule_state").attr("disabled", "disabled");
      $("#customer_filter_rule_include_distributor").prop("checked", false);
      $(".preferences", active_pane).html(
        $("#preference_default_id", active_pane).html()
      );
      $(".preferences", active_pane).trigger("bootstrapduallistbox.refresh");
      $("#pref_alert").hide_msgs();
      $("#user_preference_preferred_timezone")
        .val("")
        .trigger("chosen:updated");
      if ($("#user_preference_preferred_report_channel_format").length) {
        $(
          "#user_preference_preferred_report_channel_format"
        )[0].selectedIndex = 0;
      }
      $(".filter_form").find("input:text").val("");
      $(".filter_form input:radio:first").trigger("click");
      $(".filter_form select option:first-child").attr("selected", "selected");
      $(".btn-toggle").prop("checked", false);
      $(".btn-toggle").attr("checked", false);
      $(".filter_form").hide();
      $("#user_preference_preferred_login_page").prop("selectedIndex", 0);
      $("#user_preference_mfa_authenticated").prop("selectedIndex", 0);
      $("#user_preference_reset_dashboard").prop("checked", false);
      $("#user_preference_interface_outage_alert").prop("checked", false);
      return remove_disable();
    });
  $(".btn-primary")
    .off("click")
    .on("click", function () {
      var alert_div, form, maxvol, selected;
      form = $("form", active_pane);
      if (
        $(form).find("#user_preference_max_columns_displayed")[0] !== void 0
      ) {
        maxvol = parseInt(
          $(form).find("#user_preference_max_columns_displayed")[0].value
        );
        alert_div = $(form).find("#pref_alert");
        selected = $(".preferences option:selected", active_pane).length;
        if (selected > maxvol) {
          alert_div.show_error(
            I18n.t("maximum_columns", {
              num: maxvol.toString(),
            })
          );
          return false;
        } else if (selected === 0) {
          alert_div.show_error(I18n.t("select_at_least_one_column"));
          return false;
        } else {
          return alert_div.hide_msgs();
        }
      }
    });
  $(".btn-toggle")
    .off("click")
    .on("click", function () {
      var checked;
      checked = $(this).attr("checked");
      if (checked || checked === "undefined") {
        $(this).attr("checked", false);
        $(".filter_form").hide();
        return remove_disable();
      } else {
        $(this).attr("checked", true);
        $(".custom_filter").val("").trigger("element:validate:pass");
        $(".filter_form").show();
        $(".btn-primary.user_preference_submit").attr("disabled", "disabled");
        return $("form").enableClientSideValidations();
      }
    });
  $(".custom_filter").on("change", submit_control);
  $(".custom_filter").on("keyup", submit_control);
  $("#user_preference_preferred_timezone").change(remove_disable);
  $("#user_preference_preferred_report_channel_format").change(remove_disable);
  $("#user_preference_preferred_login_page").change(remove_disable);
  $("#user_preference_reset_dashboard").change(remove_disable);
  $("#user_preference_interface_outage_alert").change(remove_disable);  
  endDate = $(".datetimepicker input").attr("data-range-end");
  startDate = $(".datetimepicker input").attr("data-range-start");
  if ($(".datetimepicker-input").length > 0) {
    $("#user_preference_form .datetimepicker")
      .datetimepicker({
        format: "YYYY-MM-DD HH:mm:ss",
        ignoreReadonly: true,
        maxDate: new Date(endDate.replace(/\s+/, "T")),
        useCurrent: false
      })
      .on("click", submit_control);
  }
  $(".clear-date")
    .off("click")
    .on("click", function () {
      $("#" + $(this).attr("for")).val("");
      $("#" + $(this).attr("for"))
        .next(".bootstrap-datetimepicker-widget")
        .hide();
      return submit_control();
    });
  country_select_init(
    "customer_filter_rule",
    "#customer_filter_rule_country_code"
  );
  return $("#customer_filter_rule_country_code").trigger("change");
};

jQuery(function () {
  return run_ontag("user_preferences", function () {
    preferences_init();
    return initFloatLabels(false);
  });
});
