/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var document_ready, init_datatable, init_search;

document_ready = function () {
  init_datatable();
  return init_search();
};

var init_datatable = () =>
  dt.initDatatable($("#user-access-logs-table"));

init_search = function () {
  return $(".searchtoggle.user_access_logs").on(
    "on_search_toggled",
    function () {
      init_advsearch("/user_access_logs/advfind");
      create_advfind_buttons("user_access_log");
    }
  );
};

jQuery(function () {
  return run_ontag("user_access_logs", function () {
    return document_ready();
  });
});

// ---
// generated by coffee-script 1.9.2
