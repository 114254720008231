/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var document_ready,
  enable_refresh,
  initDownload,
  init_datatable,
  init_search,
  refresh;

initDownload = function () {
  return $("a.oh2000-event-export-download")
    .off("click")
    .on("click", function () {
      $.fileDownload($(this).attr("href"), {
        data: {
          format: "xlsx",
        },
        successCallback: function (url) {
          show_alert(I18n.t("oh2000_event_download_successful"));
          return enable_refresh();
        },
        failCallback: function (url) {
          return show_error(I18n.t("oh2000_event_download_failed"));
        },
      });
      return false;
    });
};

enable_refresh = function () {
  if ($("#message-area a.close").attr("data-refresh") !== "enabled") {
    $("#message-area a.close").attr("data-refresh", "enabled");
    return $("#message-area a.close").on("click", function () {
      return setTimeout(refresh, 30000);
    });
  }
};

refresh = function () {
  var loadtime;
  loadtime = parseInt($("#notif").attr("data-loadtime"));
  return $.get(
    "/oh2000_events/download_oh2000_event_export.json",
    function (obj, status, xhr) {
      var download_path, event, latest, loadDate;
      if (sessionExpired(xhr)) {
        return;
      }
      download_path = obj.download_path;
      if (download_path !== void 0) {
        show_alert(
          '<a class="oh2000-event-export-download" href="' +
            download_path +
            '">' +
            I18n.t("oh2000_event_export_completed") +
            "</a>"
        );
        initDownload();
        return;
      }
      event = obj.oh2000_event;
      if (event !== void 0) {
        latest = new Date(event.uatime);
        loadDate = new Date(loadtime);
        if (latest > loadDate) {
          $("div#message-area div:first-child").toggleClass("alert-info", true);
          show_alert(
            '<a href="/oh2000_events">' +
              I18n.t("new_events_available") +
              "</a>"
          );
          enable_refresh();
          return;
        }
      }
      return setTimeout(refresh, 30000);
    }
  );
};

document_ready = function () {
  init_datatable();
  return init_search();
};

init_datatable = function () {
  var options;
  options = {
    fnRowCallback: function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
      return $(nRow)
        .children()
        .each(function (index, td) {
          var color;
          if (index === 7) {
            color = td.innerText === "Acked" ? "#009900" : "#FF0000";
            return $(td).css("color", color);
          }
        });
    },
  };
  return dt.initDatatable($("#oh2000-event-table"), options);
};

init_search = function () {
  return $(".searchtoggle.oh2000_events").on("on_search_toggled", function () {
    init_advsearch("/oh2000_events/advfind");
    create_advfind_buttons("oh2000_event");
  });
};

jQuery(function () {
  return run_ontag("oh2000_events", function () {
    document_ready();
    if ($("#created").length) {
      return setTimeout(refresh, 30000);
    }
  });
});
