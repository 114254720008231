/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var document_ready,
  init_datatable,
  pjaxAlert,
  pjax_alert_cookie,
  pjax_alert_showtimes,
  retrieve_config_values,
  ultralink_config_new_init;

jQuery(function () {
  return run_ontag("ultralink_configs", function () {
    document_ready();
    if (document.getElementById("spinner")) {
      return (window.timeout = setTimeout(retrieve_config_values, 3000));
    } else {
      if (!$.support.pjax) {
        return pjaxAlert();
      }
    }
  });
});

retrieve_config_values = function () {
  if (document.getElementById("spinner")) {
    if ($.support.pjax) {
      return $.pjax({
        url: document.URL,
        container: "[data-pjax-container]",
        push: false,
      });
    } else {
      return pjaxAlert();
    }
  }
};

document_ready = function () {
  var crud_options;
  crud_options = {
    "ultralink-config": {
      new: ultralink_config_new_init,
    },
  };
  return init_datatable(crud_options);
};

ultralink_config_new_init = function () {
  return avoid_multiple_submit(
    "new_ultralink_config",
    "new-form-submit-ultralink-config"
  );
};

var init_datatable =  (crud_options) =>
  dt.initDatatable($("#ultralink-config-table"), {}, crud_options);

pjax_alert_cookie = "browser-suggest-alert-displayed";

pjax_alert_showtimes = 3;

pjaxAlert = function () {
  var displayed;
  if (!$.support.pjax) {
    displayed = getCookie(pjax_alert_cookie);
    if (displayed !== "0") {
      show_alert(I18n.t("use_browser_latest_version"));
      displayed =
        displayed === "" ? pjax_alert_showtimes : parseInt(displayed, 10) - 1;
      return setCookie(pjax_alert_cookie, displayed, 10);
    }
  }
};
