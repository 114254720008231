/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
jQuery(function () {
  return run_ontag("ipcameras_index", function () {
    return init_object_crud({
      "ip-camera": {},
    });
  });
});
