/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var pjaxAlert, pjax_alert_cookie, pjax_alert_showtimes, retrieve_config_values;

jQuery(function () {
  return run_ontag("ultralinks_list_config", function () {
    if (document.getElementById("spinner")) {
      return (window.timeout = setTimeout(retrieve_config_values, 3000));
    } else {
      if (!$.support.pjax) {
        return pjaxAlert();
      }
    }
  });
});

retrieve_config_values = function () {
  if (document.getElementById("spinner")) {
    if ($.support.pjax) {
      return $.pjax({
        url: document.URL,
        container: "[data-pjax-container]",
        push: false,
      });
    } else {
      return pjaxAlert();
    }
  }
};

pjax_alert_cookie = "browser-suggest-alert-displayed";

pjax_alert_showtimes = 3;

pjaxAlert = function () {
  var displayed;
  if (!$.support.pjax) {
    displayed = getCookie(pjax_alert_cookie);
    if (displayed !== "0") {
      show_alert(I18n.t("use_browser_latest_version"));
      displayed =
        displayed === "" ? pjax_alert_showtimes : parseInt(displayed, 10) - 1;
      return setCookie(pjax_alert_cookie, displayed, 10);
    }
  }
};
