var refresh,
fetch_latest;

refresh = function () {
  if (document.getElementById("spinner")) {
    fetch_latest(false)
  }
};


fetch_latest = function (refresh_flag) {
  if ($.support.pjax) {
    return $.pjax({
      url: window.location.pathname,
      container: "[data-pjax-container]",
      push: false,
      data: {refresh_flag: refresh_flag},
    }).done(document_ready);
  } else {
    return (window.location.href = document.URL);
  }
};

const document_ready = function () {
  if (document.getElementById("spinner")) {
    window.timeout = setTimeout(refresh, 2000);
  } 
};

jQuery(function () {
  run_ontag("ultralinks_diagnostic_reports", function () {
    document_ready();
    $("body").on('click', '.refresh_device_status', function(e){
      fetch_latest(true);
      return false;
    });
   });
});
