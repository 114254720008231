var document_ready,
  get_template_data,
  init_access,
  init_companies,
  init_company_select,
  init_datatable,
  init_publish_dates,
  edit_page_data_load,
  init_regions;

document_ready = function () {
  init_datatable();
  init_companies();
  init_access();
  init_regions();
  init_publish_dates();
  return get_template_data();
};

// Here we are loading companies dropdown data after edit page load
edit_page_data_load = function() {
  $(".mail_master_sub_button").prop('disabled', true);
  mail_master_object = $("#mail_master_id").val()
  if (!(mail_master_object == null)) {
    const data = {
      company_type: $("#mail_master_company_type").val(),
      sales_region: $("#mail_master_sales_region").val(),
      mail_master_id: $("#mail_master_id").val()
    }
    reload_company_data(data, "mail_master");
  }
}

init_publish_dates = function () {
  endDate = new Date($("#mm_datetimepicker").attr("data-range-end"));
  export_time = $("#mail_master_publish_date").val();
  $("#mm_datetimepicker").datetimepicker({
    format: "YYYY-MM-DD HH:mm:ss",
    ignoreReadonly: true,
    maxDate: endDate,
    minDate: export_time,
  });
  return $(".clear-date")
    .off("click")
    .on("click", function () {
      $("#" + $(this).attr("for")).val("");
      return $("#" + $(this).attr("for"))
        .next(".bootstrap-datetimepicker-widget")
        .hide();
    });
};


get_template_data = function () {
  return $("#notification_template").change(function () {
    var temp_file_name;
    temp_file_name = $("#notification_template option:selected").text();
    if (temp_file_name === "Create Custom Notification") {
      tinyMCE.activeEditor.setContent("");
      return tinymce.activeEditor.selection.setContent(
        "Please write notification here."
      );
    } else {
      $.ajax({
        type: "GET",
        url:
          "/notifications/read_notification_data?file_name=" + temp_file_name,
        success: function (data, status, xhr) {
          tinyMCE.activeEditor.setContent("");
          return tinymce.activeEditor.selection.setContent(data["response"]);
        },
      });
      return false;
    }
  });
};

init_datatable = function (crud_options) {
  return dt.initDatatable($("#mail-masters-table"), {});
};

init_access = function () {
  var $mail_master_company_type, defaultSelectOptions;
  defaultSelectOptions = {
    maxHeight: 200,
    numberDisplayed: 3,
    nonSelectedText: I18n.t("none_selected"),
    allSelectedText:
      I18n.t("agency") + ", " + I18n.t("branch") + ", " + I18n.t("distributor"),
  };
  $mail_master_company_type = $("#mail_master_company_type");
  $mail_master_company_type.multiselect(defaultSelectOptions);

  $("#mail_master_sales_region").on("change", function (e) {
    if ($("#mail_master_sales_region").val().length > 0 || $("#mail_master_company_type").val().length > 0 ) {
      $("#alert_message").hide();
    } else {
      $("#alert_message").show();
    }

  });

  $mail_master_company_type.on("change", function (e) {
    if ($("#mail_master_company_type").val().length > 0 || $("#mail_master_sales_region").val().length > 0) {
      $("#alert_message").hide();
    } else {
      $("#alert_message").show();
    }
    var access, agency_display, branch_display, distributor_display;
    access = $(this).val();
    if (access === null) {
      access = [];
    }
    agency_display = access && access.indexOf("0") >= 0 ? "block" : "none";
    $(".agency-wrapper").css("display", agency_display);
    branch_display = access && access.indexOf("1") >= 0 ? "block" : "none";
    $(".branch-wrapper").css("display", branch_display);
    distributor_display = access && access.indexOf("2") >= 0 ? "block" : "none";
    $(".distributor-wrapper").css("display", distributor_display);
    sub_dealer_display = access && access.indexOf("3") >= 0 ? "block" : "none";
    $(".sub-dealer-wrapper").css("display", sub_dealer_display);
  });
  $mail_master_company_type.trigger("change");
  return $("#searchbar").on("advsearch_loaded", function () {
    return $("#mail_master_query_company_type").multiselect(defaultSelectOptions);
  });
};

init_regions = function () {
  var defaultSelectOptions;
  defaultSelectOptions = {
    maxHeight: 200,
    numberDisplayed: 3,
    nonSelectedText: I18n.t("none_selected"),
    allSelectedText:
      I18n.t("americas") + ", " + I18n.t("emea") + ", " + I18n.t("oceania"),
  };
  $("#mail_master_sales_region").multiselect(defaultSelectOptions);
  return $("#searchbar").on("advsearch_loaded", function () {
    return $("#mail_master_query_sales_region").multiselect(defaultSelectOptions);
  });
};

init_companies = function () {
  init_company_select("agency", 0);
  init_company_select("branch", 1);
  init_company_select("distributor", 2);
  return init_company_select("sub_dealer", 3);
};

init_company_select = function (company, accessId) {
  var $notification_company_id, defaultSelectOptions;
  defaultSelectOptions = {
    maxHeight: 200,
    numberDisplayed: 3,
    nonSelectedText: I18n.t("none_selected"),
    enableCaseInsensitiveFiltering: true,
    filterPlaceholder: I18n.t('search'),
    includeSelectAllOption: true
  };
  $notification_company_id = $("#mail_master_" + company + "_id");
  return $notification_company_id.multiselect(defaultSelectOptions);
};

function reload_company_data_init() {
  $("#mail_master_sales_region").on("change", function (e) {
    if ($("#mail_master_company_type").val().length > 0 ) {
      const data = {
        company_type: $("#mail_master_company_type").val(),
        sales_region: $("#mail_master_sales_region").val(),
        mail_master_id: $("#mail_master_id").val()
      }
      reload_company_data(data, "mail_master");
    }
  });

  $("#mail_master_company_type").on("change", function (e) {
    if ($("#mail_master_company_type").val().length > 0 && $("#mail_master_sales_region").val().length > 0) {
      const data = {
        company_type: $("#mail_master_company_type").val(),
        sales_region: $("#mail_master_sales_region").val(),
        mail_master_id: $("#mail_master_id").val()
      }
      reload_company_data(data, "mail_master");
    }
  });
}
function initTinyMCE() {
  if (typeof tinyMCE != 'undefined') {
    tinyMCE.init({
      selector: "textarea.tinymce",
      skin: false,
      content_css: false,
      menubar: false,
      toolbar: ["code preview | undo redo | styleselect | bold italic | outdent indent | blockquote hr | alignleft aligncenter alignright alignjustify | numlist bullist | table","fontselect fontsizeselect | forecolor backcolor | codesample | uploadimage link image media | toc | visualblocks searchreplace"],
      plugins: "code,codesample,lists,advlist,hr,preview,searchreplace,table,toc,visualblocks",
      branding: false,
      browser_spellcheck: true,
      plugin_preview_height: 600,
      plugin_preview_width: 800,
      height: 500,
      fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt"
    });
  } else {
    setTimeout(initTinyMCE, 50);
  }
};

jQuery(function () {
  return run_ontag("mail_masters", function () {
    document_ready();
    reload_company_data_init();
    initTinyMCE();

    $(document).on("pjax:end", document_ready);
    edit_page_data_load();
    if ($.support.pjax) {
      $.pjax.defaults.scrollTo = false;
      $.pjax.defaults.timeout = false;
      return $(document).pjax(".pagination a", "[data-pjax-container]");
    }
  });


});

