import "./alarm_receiver_interfaces";
import "./article_assets";
import "./articles";
import "./batch_service_requests";
import "./companies";
import "./customer_dashboard";
import "./customer_exports";
import "./customers";
import "./datatables";
import "./devices";
import "./dialogs";
import "./distributor_links";
import "./download_jobs";
import "./enduser";
import "./gentelella_extended";
import "./ipcameras";
import "./js_cookie";
import "./misc";
import "./mail_masters";
import "./notices";
import "./oh2000_event_exports";
import "./oh2000_events";
import "./oh2000_panels";
import "./perfmon_samples";
import "./quick_registrations";
import "./remote_consoles";
import "./report_channels";
import "./reports";
import "./service_grades";
import "./sessions";
import "./support_contact_users";
import "./systems";
import "./terms_and_conditions";
import "./ultralink_camera_statuses";
import "./ultralink_config";
import "./ultralink_configs";
import "./ultralink_event_exports";
import "./ultralink_events";
import "./ultralinks";
import "./ultralink_software_versions";
import "./ultrawan";
import "./unassociated_ultralinks";
import "./user_access_logs";
import "./user_preferences";
import "./user_registrations";
import "./user_requests";
import "./users";
import "./vfnns";
import "./video_trigger";
import "./license_keys";
import "./diagnostic_reports";
import "./onboarding_customers";
import "./sim_managements";
import "./software_license_agreements";
