/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var document_ready,
  filter_ari2_id_by_agency_id,
  filter_ari_id_by_agency_id,
  hide_vfnn_type_for_self_monitoring,
  init_datatable,
  init_search,
  vfnn_edit_init,
  vfnn_new_init;

filter_ari_id_by_agency_id = function (mode) {
  var agency_id, ari_options, ari_select;
  agency_id = $(
    "#modal-body-" + mode + "-alarm-receiver #alarm_receiver_agency_id"
  ).val();
  ari_select = $(
    "#modal-body-" +
      mode +
      "-alarm-receiver #alarm_receiver_destination_profile_alarm_receiver_interface_id"
  );
  ari_options = $(
    "#modal-body-" +
      mode +
      "-alarm-receiver #alarm_receiver_destination_profile_alarm_receiver_interface_id option"
  );
  ari_options.each(function () {
    if ($(this).val() === "") {
      if (mode === "new") {
        return $(this).attr("selected", true);
      }
    } else if (
      $(this).attr("data-agency-id") === agency_id ||
      !(agency_id === "" || $(this).attr("data-agency-id"))
    ) {
      if (mode === "new") {
        $(this).prop("selected", false);
      }
      return $(this).show().prop("disabled", false);
    } else {
      if (mode === "new") {
        $(this).prop("selected", false);
      }
      return $(this).hide().attr("disabled", true);
    }
  });
  if (agency_id === "") {
    ari_select.attr("readonly", true);
  } else {
    ari_select.attr("readonly", false);
  }
  return ari_select.trigger("change");
};

filter_ari2_id_by_agency_id = function (mode) {
  var agency_id, ari_options, ari_select;
  agency_id = $(
    "#modal-body-" + mode + "-alarm-receiver #alarm_receiver_agency_id"
  ).val();
  ari_select = $(
    "#modal-body-" +
      mode +
      "-alarm-receiver #alarm_receiver_destination_profile_alarm_receiver_interface_id_2"
  );
  ari_options = $(
    "#modal-body-" +
      mode +
      "-alarm-receiver #alarm_receiver_destination_profile_alarm_receiver_interface_id_2 option"
  );
  ari_options.each(function () {
    if ($(this).val() === "") {
      if (mode === "new") {
        return $(this).attr("selected", true);
      }
    } else if ($(this).val() === "None") {
      if (mode === "new") {
        return $(this).prop("selected", false).hide();
      }
    } else if (
      $(this).attr("data-agency-id") === agency_id ||
      !(agency_id === "" || $(this).attr("data-agency-id"))
    ) {
      if (mode === "new") {
        $(this).prop("selected", false);
      }
      return $(this).show().prop("disabled", false);
    } else {
      if (mode === "new") {
        $(this).prop("selected", false);
      }
      return $(this).hide().attr("disabled", true);
    }
  });
  if (agency_id === "") {
    ari_select.attr("readonly", true);
  } else {
    ari_select.attr("readonly", false);
  }
  return ari_select.trigger("change");
};

hide_vfnn_type_for_self_monitoring = function (mode) {
  var is_self_monitoring;
  is_self_monitoring = $(
    "#modal-body-" + mode + "-alarm-receiver #alarm_receiver_agency_id"
  )
    .find("option:selected")
    .attr("data-is-self-monitoring");
  if (is_self_monitoring === "true") {
    return $(
      "#modal-body-" + mode + "-alarm-receiver .alarm_receiver_vfnn_type"
    ).hide();
  } else {
    return $(
      "#modal-body-" + mode + "-alarm-receiver .alarm_receiver_vfnn_type"
    ).show();
  }
};

vfnn_edit_init = function () {
  filter_ari_id_by_agency_id("edit");
  filter_ari2_id_by_agency_id("edit");
  return hide_vfnn_type_for_self_monitoring("edit");
};

vfnn_new_init = function () {
  $("#modal-body-new-alarm-receiver #alarm_receiver_agency_id")
    .off("change")
    .on("change", function () {
      filter_ari_id_by_agency_id("new");
      filter_ari2_id_by_agency_id("new");
      return hide_vfnn_type_for_self_monitoring("new");
    });
  return $(".alarm_receiver_agency_id").val("").trigger("change");
};

document_ready = function () {
  var crud_options;
  crud_options = {
    "alarm-receiver": {
      edit: vfnn_edit_init,
      new: vfnn_new_init,
    },
  };
  init_datatable(crud_options);
  return init_search();
};

var init_datatable = (crud_options) =>
  dt.initDatatable($("#alarm-receivers-table"), {}, crud_options);


init_search = function () {
  return $(".searchtoggle.vfnns").on("on_search_toggled", function () {
    init_advsearch("/vfnns/advfind");
    create_advfind_buttons("vfnn");
  });
};

jQuery(function () {
  return run_ontag("vfnns", function () {
    document_ready();
    $(document).on("pjax:end", document_ready);
    if ($.support.pjax) {
      $.pjax.defaults.scrollTo = false;
      $.pjax.defaults.timeout = false;
      return $(document).pjax(".pagination a", "[data-pjax-container]");
    }
  });
});
