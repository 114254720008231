/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/
var key_prompt,
  login_form_init,
  login_form_submit,
  login_key_init,
  new_key_init;

key_prompt = function (msg) {
  var submit_control;
  $('input[name="name"]').attr("readonly", true);
  $('input[name="password"]').attr("readonly", true);
  $("#login_first").toggleClass("hide", true);
  show_alert(msg);
  $("#key-input").toggleClass("hide", false);
  $("#login-submit").attr("disabled", true);
  submit_control = function () {
    if ($("#key").val() === "") {
      return $("#login-submit").attr("disabled", true);
    } else {
      return $("#login-submit").prop("disabled", false);
    }
  };
  $("#key").off("change").on("change", submit_control);
  return $("#key").off("keyup").on("keyup", submit_control);
};

login_key_init = function (data) {
  var result;
  result = data;
  switch (result["result"]) {
    case 6:
    case 7:
      return (location.href = result["home"]);
    case 1:
      return show_error(result["message"]);
    case 2:
      return key_prompt(result["message"]);
    case 3:
    case 4:
      show_error(result["message"]);
      return $("#key").val("");
    case 5:
      show_error(result["message"]);
      $("#key").attr("readonly", true);
      $("#login-submit").attr("disabled", true);
      return $("#newkey-btn").addClass("hide");
    default:
      return alert(I18n.t("login_again"));
  }
};

login_form_submit = function () {
  hide_msgs();
  return $.ajax({
    type: "POST",
    url: $(".login-form form").attr("action"),
    data: $(".login-form form").serialize(),
    success: login_key_init,
    dataType: "json",
  });
};

new_key_init = function () {
  if (navigator.cookieEnabled === false) {
    show_error(I18n.t("enable_cookies"));
    return $("#login-submit").attr("disabled", true);
  } else {
    $("#login-submit").attr("disabled", false);
    return $("#newkey-btn")
      .off("click")
      .on("click", function () {
        $("#key").val("");
        return login_form_submit();
      });
  }
};

login_form_init = function () {
  $('#pass01').on('blur input focus', function() {
    var url  = window.location.href;
    if ( !($.trim($("#user01").val()) == "") && $("#user_key").val().length == 0 ) {
      $.ajax({
        url: "/fetch_geo_path",
        data: $("#user01").serialize(),
        type: "GET",
        dataType: "JSON",
        success: function (data, status, xhr) {
          if (data.result.length > 0 ) {
            $(location).attr('href', data.result+"/?user="+data.login_key);
          }
        },
        error: function (xhr, status, errors) {
          
        },
        dataType: "json",
      });
    }
  });

  return $("#login-submit")
    .off("click")
    .on("click", function () {
      login_form_submit();
      return false;
    });
};

jQuery(function () {
  return run_ontag("sessions", function () {
    login_form_init();
    new_key_init();
    return $('input[name="name"]').trigger("focus");
  });
});
