/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var document_ready = function document_ready() {
  var crud_options = {};
  init_datatable(crud_options);
  // alter heading so its not confusing to users what view they're in
  var admin_article_heading =
    I18n.t("article_assets") + " (" + I18n.t("admin") + ")";
  $(".top_nav .nav .page-title").text(admin_article_heading);

  var clipboard = new Clipboard(".btn-copy-article-asset");
};

var init_datatable = function init_datatable(crud_options) {
  if (crud_options == null) {
    crud_options = {};
  }
  return dt.initDatatable($("#article-assets-table"), {}, crud_options);
};

jQuery(function () {
  return run_ontag("article_assets", function () {
    document_ready();
    $(document).on("pjax:end", document_ready);
    if ($.support.pjax) {
      $.pjax.defaults.scrollTo = false;
      $.pjax.defaults.timeout = false;
      return $(document).pjax(".pagination a", "[data-pjax-container]");
    }
  });
});
