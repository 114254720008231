/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/
var document_ready,
  hide_search,
  init_access,
  init_companies,
  init_company_select,
  init_datatable,
  init_publish_dates,
  init_regions,
  edit_page_data_load,
  init_search;

document_ready = function () {
  var $delete_directory_modal;
  init_search();
  init_datatable();
  init_publish_dates();
  init_companies();
  init_access();
  init_regions();
  $delete_directory_modal = $("#modal-body-delete-article-directory").closest(
    ".modal"
  );
  return $delete_directory_modal.on("shown", function (e) {
    var delete_text, delete_url, descendant_count;
    delete_url = $delete_directory_modal.find("form").attr("action");
    descendant_count = $(
      ".btn-delete-article-directory[url='" + delete_url + "']"
    ).data("descendant-count");
    if (descendant_count > 0) {
      delete_text = I18n.t("delete_article_directory", {
        model_name: I18n.t("article_directory").toLowerCase(),
      });
      return $delete_directory_modal.find("#default-notice").text(delete_text);
    }
  });
};

// Here we are loading companies dropdown data after edit page load
edit_page_data_load = function() {
  $(".article_sub_button").prop('disabled', true);
  article_obj = $("#article_id").val()
  if (!(article_obj == null)) {
    const data = {
      company_type: $("#article_accessible_to").val(),
      sales_region: $("#article_sales_region").val(),
      article_id: $("#article_id").val()
    }
    reload_company_data(data, "article");
  }
}

init_search = function () {
  return $(".searchtoggle.articles").on("on_search_toggled", function () {
    var admin_article_heading;
    if (location.pathname.startsWith("/admin/content_library")) {
      init_advsearch("/admin/content_library/advfind");
      admin_article_heading = I18n.t("articles") + " (" + I18n.t("admin") + ")";
      $(".top_nav .nav .page-title").text(admin_article_heading);
    } else {
      init_advsearch("/content_library/advfind");
    }
    create_advfind_buttons("article");
  });
};

init_datatable = function (crud_options) {
  var options;
  if (crud_options == null) {
    crud_options = {};
  }
  options = {
    fnRowCallback: function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
      return $(nRow).on(
        "click",
        (function (_this) {
          return function () {
            var $td, href_target;
            href_target = "";
            $td = $(nRow).find("td").last();
            href_target = $td.find("a").attr("href");
            if (href_target === void 0 || href_target === "#") {
              href_target = $(nRow).find("td a").attr("href");
            }
            document.location.href = href_target;
          };
        })(this)
      );
    },
  };
  return dt.initDatatable($("#articles-table"), options, crud_options);
};

init_publish_dates = function () {
  $(".datetimepicker").datetimepicker({
    format: "YYYY-MM-DD HH:mm:ss",
    ignoreReadonly: true,
    useCurrent: false
  });
  return $(".clear-date")
    .off("click")
    .on("click", function () {
      $("#" + $(this).attr("for")).val("");
      return $("#" + $(this).attr("for"))
        .next(".bootstrap-datetimepicker-widget")
        .hide();
    });
};

init_access = function () {
  var $article_accessible_to, defaultSelectOptions;
  defaultSelectOptions = {
    maxHeight: 200,
    numberDisplayed: 3,
    nonSelectedText: I18n.t("none_selected"),
    allSelectedText:
      I18n.t("agency") + ", " + I18n.t("branch") + ", " + I18n.t("distributor"),
    buttonTitle: function() {},
  };
  $article_accessible_to = $("#article_accessible_to");
  $article_accessible_to.multiselect(defaultSelectOptions);
  $('#article_accessible_to').parent().attr('data-original-title', I18n.t('article_accessible_to_help_text'));
  $article_accessible_to.on("change", function (e) {
    var access, agency_display, branch_display, distributor_display;
    access = $(this).val();
    if (access === null) {
      access = [];
    }
    agency_display = access && access.indexOf("0") >= 0 ? "block" : "none";
    $(".agency-wrapper").css("display", agency_display);
    branch_display = access && access.indexOf("1") >= 0 ? "block" : "none";
    $(".branch-wrapper").css("display", branch_display);
    distributor_display = access && access.indexOf("2") >= 0 ? "block" : "none";
    $(".distributor-wrapper").css("display", distributor_display);
  });
  $article_accessible_to.trigger("change");
  return $("#searchbar").on("advsearch_loaded", function () {
    return $("#article_query_accessible_to").multiselect(defaultSelectOptions);
  });
};

init_regions = function () {
  var defaultSelectOptions;
  defaultSelectOptions = {
    maxHeight: 200,
    numberDisplayed: 3,
    nonSelectedText: I18n.t("none_selected"),
    allSelectedText:
      I18n.t("americas") + ", " + I18n.t("emea") + ", " + I18n.t("oceania"),
    buttonTitle: function() {},
  };
  $("#article_sales_region").multiselect(defaultSelectOptions);
  $('#article_sales_region').parent().attr('data-original-title', I18n.t('article_sales_region_help_text'));
  return $("#searchbar").on("advsearch_loaded", function () {
    return $("#article_query_sales_region").multiselect(defaultSelectOptions);
  });
};

init_companies = function () {
  init_company_select("agency", 0);
  init_company_select("branch", 1);
  return init_company_select("distributor", 2);
};

init_company_select = function (company, accessId) {
  var $article_company_id, defaultSelectOptions;
  defaultSelectOptions = {
    maxHeight: 200,
    numberDisplayed: 3,
    nonSelectedText: I18n.t("none_selected"),
    enableCaseInsensitiveFiltering: true,
    filterPlaceholder: I18n.t('search'),
    includeSelectAllOption: true,
    buttonTitle: function() {},
  };
  $article_company_id = $("#article_" + company + "_id");
  $article_company_id.multiselect(defaultSelectOptions);
  return $article_company_id.closest('span').attr('data-original-title', I18n.t('article_'+company+'_help_text') );
};

hide_search = function () {
  $(".mobile-search-box").css("visibility", "hidden");
  return $(".search-box").css("visibility", "hidden");
};

function reload_company_data_init() {
  $("#article_sales_region").on("change", function (e) {
    if ($("#article_accessible_to").val().length > 0 ) {
      const data = {
        company_type: $("#article_accessible_to").val(),
        sales_region: $("#article_sales_region").val(),
        article_id: $("#article_id").val()
      }
      reload_company_data(data, "article");
    }
  });

  $("#article_accessible_to").on("change", function (e) {
    if ($("#article_accessible_to").val().length > 0 && $("#article_sales_region").val().length > 0) {
      const data = {
        company_type: $("#article_accessible_to").val(),
        sales_region: $("#article_sales_region").val(),
        article_id: $("#article_id").val()
      }
      reload_company_data(data, "article");
    }
  });
}

function initTinyMCE() {
  if (typeof tinyMCE != 'undefined') {
    tinyMCE.init({
      selector: "textarea.tinymce",
      skin: false,
      content_css: false,
      menubar: false,
      toolbar: ["code preview | undo redo | styleselect | bold italic | outdent indent | blockquote hr | alignleft aligncenter alignright alignjustify | numlist bullist | table","fontselect fontsizeselect | forecolor backcolor | codesample | uploadimage link image media | toc | visualblocks searchreplace"],
      plugins: "advlist,code,codesample,hr,image,link,lists,media,preview,searchreplace,table,toc,visualblocks",
      branding: false,
      browser_spellcheck: true,
      plugin_preview_height: 600,
      plugin_preview_width: 800,
      height: 500,
      fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt"
    });
  } else {
    setTimeout(initTinyMCE, 50);
  }
};

jQuery(function () {
  run_ontag("articles", function () {
    document_ready();
    reload_company_data_init();
    initTinyMCE();
    $(document).on("pjax:end", document_ready);
    edit_page_data_load();
    if ($.support.pjax) {
      $.pjax.defaults.scrollTo = false;
      $.pjax.defaults.timeout = false;
      return $(document).pjax(".pagination a", "[data-pjax-container]");
    }
  });
  run_ontag("articles_show", function () {
    return hide_search();
  });
  run_ontag("articles_new", function () {
    return hide_search();
  });
  return run_ontag("articles_edit", function () {
    return hide_search();
  });
});
