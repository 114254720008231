document_ready = function () {
  init_agreement_date();
  return country_select_init("software_license_agreement", "#software_license_agreement_country_code" );
};

init_agreement_date = function () {
  startDate = new Date($("#software_license_agreement_effective_date").attr("data-range-start"));  
  $(".datetimepicker").datetimepicker({
    format: "YYYY-MM-DD",
    ignoreReadonly: true,
    useCurrent: false,
    minDate: startDate,    
  });
  return $(".clear-date")
    .off("click")
    .on("click", function () {
      $("#" + $(this).attr("for")).val("");
      return $("#" + $(this).attr("for"))
        .next(".bootstrap-datetimepicker-widget")
        .hide();
    });
  };


jQuery(function() {
  run_ontag('software_license_agreements', function() {
    document_ready();   
  });
});