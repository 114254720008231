/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var addSpinner,
  countFormatter,
  document_ready,
  init_charts,
  init_datatable,
  init_search,
  init_systems,
  labelFormatter,
  refresh_charts,
  removeSpinner,
  render_no_data_label,
  showTooltip,
  showAnalyticalTooltip;

this.interval = 0;

labelFormatter = function (label, slice) {
  return (
    '<div style="border:1px solid grey;font-size:8pt;text-align:center;padding:5px;color:white;background:black;opacity:0.6;">' +
    label +
    " : " +
    Math.round(slice.percent) +
    "%</div>"
  );
};

countFormatter = function (label, item) {
  return (
    '<div style="border:1px solid grey;font-size:8pt;text-align:center;padding:5px;color:white;background:black;opacity:0.6;">' +
    label +
    " : " +
    item.data[0][1] +
    "</div>"
  );
};

document_ready = function () {
  var crud_options;
  crud_options = {
    "system-event": {},
  };
  init_datatable(crud_options);
  return init_search();
};

showTooltip = function (item, label, value) {
  if (item) {
    this.tooltipEvent.text(label);
    this.tooltipValue.text(value);
    this.tooltip.stop(0, 1).fadeIn(100).offset({
      left: item.pageX,
      top: item.pageY,
    });
  } else {
    this.tooltip.stop(0, 1).fadeOut(200);
  }
};

var showAnalyticalTooltip = function (item, chartType) {
  if (item) {
    if (chartType == 'responseTime')
    {
      var time, count, average_rt, max_rt, series_item; 
      series_item = item.series.data[item.dataIndex]
      //time = formatTime(new Date(series_item[0]));
      count = series_item[1];
      average_rt = series_item[2];
      max_rt = series_item[3];
      this.tooltipTime.text('');
      this.tooltipCount.text(I18n.t("request_count")+': '+count);
      this.tooltipAverage.text(I18n.t("request_time_avg")+': '+average_rt);
      this.tooltipMax.text(I18n.t("request_time_max")+': '+max_rt);
      this.analytical_tooltip.stop(0, 1).fadeIn(100).offset({
        left: item.pageX,
        top: item.pageY,
      });
    }
    else if (chartType == 'responseTimeByURL')
    {
      var url, series_item; 
      series_item = item.series.ticks[item.dataIndex];
      url = series_item[2];
      this.tooltipTime.text(url);
      this.tooltipCount.text('');
      this.tooltipAverage.text('');
      this.tooltipMax.text('');
      this.analytical_tooltip.stop(0, 1).fadeIn(100).offset({
        left: item.pageX,
        top: item.pageY,
      });      
    }
  } else {
    this.analytical_tooltip.stop(0, 1).fadeOut(200);
  }
};

init_charts = function () {
  var dataValue,
    date_from,
    date_to,
    eventAckChartOh,
    event_chart,
    event_stats_chart,
    eventsAgencyPlot,
    eventsPerIpChart,
    formatTime,
    getOptionsFromView,
    ohAckEventPlot,
    ohIpEventPlot,
    opInfoChart,
    opInfoPlot,
    opinfo,
    serviceGradePlot,
    ticks,
    ulAckEventPlot,
    view;
    this.tooltip = $("#chartTooltip");
    this.tooltipEvent = this.tooltip.find(".event");
    this.tooltipValue = this.tooltip.find(".value");
    this.tooltip.hide();
    this.analytical_tooltip = $("#analyticalChartTooltip")
    this.tooltipTime = this.analytical_tooltip.find(".time");
    this.tooltipCount = this.analytical_tooltip.find(".count");
    this.tooltipAverage = this.analytical_tooltip.find(".average");
    this.tooltipMax = this.analytical_tooltip.find(".max");
    this.analytical_tooltip.hide();    
  if ($("#eventStatsChart").length > 0) {
    event_stats_chart = $("#eventStatsChart");
    view = $(".table-filter .active").data("view");
    dataValue = event_stats_chart.data("value");
    opinfo = [dataValue[0]];
    date_from = new Date(parseInt(dataValue[1]));
    date_to = new Date(parseInt(dataValue[2]));
    getOptionsFromView = function () {
      if (view === "day") {
        return {
          xaxis: {
            mode: "time",
            timeBase: "milliseconds",
            minTickSize: [1, "hour"],
            min: date_from.getTime(),
            max: date_to.getTime(),
            twelveHourClock: true,
          },
          grid: {
            hoverable: true,
            autoHighlight: true,
          },
        };
      } else if (view === "week") {
        return {
          xaxis: {
            mode: "time",
            minTickSize: [1, "day"],
            min: date_from.getTime(),
            max: date_to.getTime(),
            dayNames: ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"],
            tickFormatter: function (val, axis) {
              return new Date(val).toString().split(" ")[0];
            },
          },
          grid: {
            hoverable: true,
            autoHighlight: true,
            mouseActiveRadius: 1,
          },
        };
      }
    };
    $.plot("#eventStatsChart", opinfo, getOptionsFromView());
    formatTime = function (date) {
      var ampm, dayOfWeek, hours, minutes, strTime;
      dayOfWeek = function (dow) {
        var days;
        days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        return days[dow];
      };
      hours = date.getUTCHours();
      minutes = date.getUTCMinutes();
      ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      strTime = hours + ":" + minutes + " " + ampm;
      if (view) {
        return (strTime = dayOfWeek(date.getUTCDay()) + " - " + strTime);
      }
    };
    event_stats_chart.on("plothover", function (event, pos, item) {
      var label, value;
      if (item) {
        label = formatTime(new Date(item.datapoint[0]));
        value = item.datapoint[1];
      }
      return showTooltip(item, label, value);
    });
  }
  if ($("#systemEventChart").length > 0) {
    event_chart = $("#systemEventChart");
    opinfo = event_chart.data("value");
    ticks = opinfo[0].ticks;
    $.plot("#systemEventChart", opinfo, {
      series: {
        bars: {
          show: true,
        },
      },
      bars: {
        align: "center",
        barWidth: 0.5,
      },
      xaxis: {
        ticks: ticks,
      },
      grid: {
        hoverable: true,
        autoHighlight: true,
        mouseActiveRadius: 1,
      },
    });
    event_chart.on("plothover", function (event, pos, item) {
      var label, value;
      if (item) {
        label = ticks[item.dataIndex][1];
        value = item.datapoint[1];
      }
      return showTooltip(item, label, value);
    });
  }
  if ($("#opInfoChart").length > 0) {
    opInfoChart = $("#opInfoChart");
    opinfo = opInfoChart.data("value");
    opInfoPlot = $.plot("#opInfoChart", opinfo, {
      series: {
        pie: {
          show: true,
          innerRadius: 0.5,
          label: {
            show: true,
            radius: 2 / 3,
            formatter: labelFormatter,
            threshold: 0.1,
          },
          background: {
            opacity: 0.8,
            color: "#000",
          },
        },
      },
      legend: {
        show: false,
      },
      grid: {
        hoverable: opinfo.length > 0,
      },
    });
    render_no_data_label(opInfoPlot);
  }
  if ($("#serviceGradeChart").length > 0) {
    opinfo = $("#serviceGradeChart").data("value");
    serviceGradePlot = $.plot("#serviceGradeChart", opinfo, {
      series: {
        pie: {
          show: true,
          innerRadius: 0.5,
          label: {
            show: true,
            radius: 2 / 3,
            formatter: labelFormatter,
            threshold: 0.1,
          },
          background: {
            opacity: 0.8,
            color: "#000",
          },
        },
      },
      legend: {
        show: false,
      },
      grid: {
        hoverable: true,
      },
    });
    render_no_data_label(serviceGradePlot);
  }
  if ($("#eventsPerAgencyChart").length > 0) {
    opinfo = $("#eventsPerAgencyChart").data("value");
    eventsAgencyPlot = $.plot("#eventsPerAgencyChart", opinfo, {
      series: {
        pie: {
          show: true,
          innerRadius: 0.5,
          label: {
            show: true,
            radius: 2 / 3,
            formatter: labelFormatter,
            threshold: 0.1,
          },
          background: {
            opacity: 0.8,
            color: "#000",
          },
        },
      },
      legend: {
        show: false,
      },
      grid: {
        hoverable: opinfo.length > 0,
      },
    });
    render_no_data_label(eventsAgencyPlot);
  }
  if ($("#eventAckChart").length > 0) {
    opinfo = $("#eventAckChart").data("value");
    ulAckEventPlot = $.plot("#eventAckChart", opinfo, {
      series: {
        pie: {
          show: true,
          innerRadius: 0.5,
          label: {
            show: true,
            radius: 2 / 3,
            formatter: labelFormatter,
            threshold: 0.1,
          },
          background: {
            opacity: 0.8,
            color: "#000",
          },
        },
      },
      legend: {
        show: false,
      },
      grid: {
        hoverable: opinfo.length > 0,
      },
    });
    render_no_data_label(ulAckEventPlot);
  }
  if ($("#eventAckChartOh").length > 0) {
    eventAckChartOh = $("#eventAckChartOh");
    opinfo = eventAckChartOh.data("value");
    ohAckEventPlot = $.plot("#eventAckChartOh", opinfo, {
      series: {
        pie: {
          show: true,
          innerRadius: 0.5,
          label: {
            show: true,
            radius: 2 / 3,
            formatter: labelFormatter,
            threshold: 0.1,
          },
          background: {
            opacity: 0.8,
            color: "#000",
          },
        },
      },
      legend: {
        show: false,
      },
      grid: {
        hoverable: opinfo.length > 0,
      },
    });
    render_no_data_label(ohAckEventPlot);
  }
  if ($("#eventsPerIpChart").length > 0) {
    eventsPerIpChart = $("#eventsPerIpChart");
    opinfo = eventsPerIpChart.data("value");
    ohIpEventPlot = $.plot("#eventsPerIpChart", opinfo, {
      series: {
        pie: {
          show: true,
          innerRadius: 0.5,
          label: {
            show: true,
            radius: 2 / 3,
            formatter: labelFormatter,
            threshold: 0.1,
          },
          background: {
            opacity: 0.8,
            color: "#000",
          },
        },
      },
      legend: {
        show: false,
      },
      grid: {
        hoverable: opinfo.length > 0,
      },
    });
    render_no_data_label(ohIpEventPlot);
  }

  if ($("#portalUsageChart").length > 0) {
    let sg_opinfo = $("#portalUsageChart").data("value");
    if (!Array.isArray(sg_opinfo)) {
      sg_opinfo = [];
    }
    const portalUsagePlot = $.plot("#portalUsageChart", sg_opinfo, {
      tooltip: {
        show: sg_opinfo.length > 0,
        content: "%s: %n (%p.2%)",
        defaultTheme: false,
      },
      series: {
        pie: {
          show: sg_opinfo.length > 0,
          radius: 0.8,
          label: {
            show: false,
          },
          background: {
            opacity: 0.8,
            color: "#000",
          },
        },
      },
      legend: {
        show: sg_opinfo.length > 0,
        container: "#portalUsageLegend",
      },
      grid: {
        hoverable: sg_opinfo.length > 0,
        clickable: sg_opinfo.length > 0,
        borderWidth: (sg_opinfo.length > 0) ? 0.5 : 0
      },
    });
    if (portalUsagePlot.getData().length === 0) {
      render_no_data_label(portalUsagePlot);
    }
  }

 if ($("#responseTimeChart").length > 0) {
    this.analytical_tooltip = $("#analyticalChartTooltip");
    this.tooltipTime = this.analytical_tooltip.find(".time");
    this.tooltipCount = this.analytical_tooltip.find(".count");
    this.tooltipAverage = this.analytical_tooltip.find(".average");
    this.tooltipMax = this.analytical_tooltip.find(".max");
    this.analytical_tooltip.hide();
    event_stats_chart = $("#responseTimeChart");
    dataValue = event_stats_chart.data("value");
    let dataCheck = false;

    if (Array.isArray(dataValue) && dataValue[0].data) {
      if (dataValue[0].data.length !== 0) {
        $.each(dataValue[0].data, function (i, el) {
          if (el[0] > 0) {
            dataCheck = true;
            return false;
          }
        });
      }
    }

    if (Array.isArray(dataValue) && dataCheck) {
      rt_opinfo = [dataValue[0]];
      date_from = new Date(parseInt(dataValue[1]));
      date_to = new Date(parseInt(dataValue[2]));
    } else {
      rt_opinfo = [];
      date_from = new Date();
      date_to = new Date();
    }
    const responseTimePlot = $.plot("#responseTimeChart", rt_opinfo, 
        {
          xaxis: {
            show: dataCheck,
            mode: "time",
            timeBase: "milliseconds",
            timezone: "browser",
            tickSize: [2, "minute"],
            minTickSize: [2, "minute"],
            min: date_from.getTime(),
            max: date_to.getTime()
          },
          yaxis: {
            show: dataCheck,
          },
          lines: {
            show: dataCheck
          },
          points: {
            show: dataCheck
          },
          grid: {
            clickable: false,
            hoverable: dataCheck,
            borderWidth: (dataCheck)? 0.5 : 0
          }
        }
      );
    formatTime = function (date) {
      var ampm, dayOfWeek, hours, minutes, strTime;
      dayOfWeek = function (dow) {
        var days;
        days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        return days[dow];
      };
      hours = date.getUTCHours();
      minutes = date.getUTCMinutes();
      ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      strTime = hours + ":" + minutes + " " + ampm;
      //if (view) {
        return (strTime = dayOfWeek(date.getUTCDay()) + " - " + strTime);
      //}
    };
    event_stats_chart.on("plothover", function (event, pos, item) {
      return showAnalyticalTooltip(item, 'responseTime');
    });
    if  (!dataCheck) {
      render_no_data_label(responseTimePlot);
    }    
  }

 if ($("#responseTimeByUrlBarChart").length > 0) {
    const chart = $("#responseTimeByUrlBarChart");
    let site_bargraph = chart.data("value");
    let dataCheck = false;
    this.analytical_tooltip = $("#analyticalChartTooltip");
    this.tooltipTime = this.analytical_tooltip.find(".time");
    this.tooltipCount = this.analytical_tooltip.find(".count");
    this.tooltipAverage = this.analytical_tooltip.find(".average");
    this.tooltipMax = this.analytical_tooltip.find(".max");
    this.analytical_tooltip.hide();    

    if (Array.isArray(site_bargraph) && site_bargraph[0].data) {
      if (site_bargraph[0].data.length !== 0) {
        $.each(site_bargraph[0].data, function (i, el) {
          if (el[0] > 0) {
            dataCheck = true;
            return false;
          }
        });
      }
    }

    if (!Array.isArray(site_bargraph) || !dataCheck) {
      site_bargraph = [{ ticks: [] }];
    }
    const { ticks } = site_bargraph[0];

    const responseTimeByUrlPlot = $.plot($("#responseTimeByUrlBarChart"), dataCheck ? site_bargraph : [], {
      series: {
        bars: {
          show: ticks.length > 0,
          horizontal: true,
        },
      },
      bars: {
        align: "center",
        barWidth: 0.5,
        lineWidth: !dataCheck ? 0 : undefined,
      },
      xaxis: {
        show: dataCheck,
      },
      yaxis: {
        position: "left",
        show: dataCheck,
        ticks,
        tickColor: "#fff",
      },
      grid: {
        hoverable: true,
         show: dataCheck,
        autoHighlight: true,
        mouseActiveRadius: 1,
        clickable: dataCheck,
        borderWidth: 0,
        markings: dataCheck
          ? [{ xaxis: { from: 0, to: 0 }, color: "#eee" }]
          : undefined,
      },
    });
    $("#responseTimeByUrlBarChart").on("plothover", function (event, pos, item) {
      return showAnalyticalTooltip(item, 'responseTimeByURL');
    });
    if (ticks.length === 0 || !dataCheck) {
      render_no_data_label(responseTimeByUrlPlot);
    }
  }

  return setTimeout(refresh_charts, 120000);
};

render_no_data_label = function (graphPlot) {
  var active_tab, canvas, ctx, pagination, x, y;
  if (graphPlot.getData().length === 0) {
    canvas = graphPlot.getCanvas();
    ctx = canvas.getContext("2d");
    x = canvas.width / 3;
    y = canvas.height / 3;
    ctx.textAlign = "center";
    ctx.font = "12px sans-serif";
    ctx.fillText(I18n.t("no_data_available"), x, y);
  }
  active_tab = $(".nav-tabs .active a").attr("href");
  pagination = $(".pagination");
  if (active_tab === "#dashboard") {
    pagination.css("visibility", "hidden");
    events.start_polling();
    cleanURL("page");
    return init_charts();
  } else if (active_tab === "#alarm_receivers") {
    pagination.css("visibility", "inherit");
    events.stop_polling();
    return cleanURL("view");
  }
};

refresh_charts = function () {
  if ($.support.pjax) {
    if (!$("body").hasClass("spinner-overlay")) {
      return $.pjax({
        url: location.href,
        container: "[data-pjax]",
        push: false,
      });
    }
  } else {
    return show_alert(I18n.t("use_browser_latest_version"));
  }
};

addSpinner = function (xhr, options) {
  if (xhr.target.innerText.indexOf(I18n.t("alarm_receivers")) < 0) {
    return $("body").addClass("spinner-overlay");
  }
};

removeSpinner = function () {
  return $("body").removeClass("spinner-overlay");
};

init_datatable = function (crud_options) {
  var options;
  options = {
    fnRowCallback: function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
      return (nRow.style.color = aData[aData.length - 1]);
    },
  };
  return dt.initDatatable($("#systems-table"), options, crud_options);
};

init_search = function () {
  return $(".searchtoggle.systems").on("on_search_toggled", function () {
    init_advsearch("/systems/advfind");
    create_advfind_buttons("system");
  });
};

init_systems = function () {
  var $doc;
  $doc = $(document);
  document_ready();
  $doc.on("pjax:end", document_ready);
  if ($.support.pjax) {
    $.pjax.defaults.scrollTo = false;
    $.pjax.defaults.timeout = false;
    $doc.pjax(".pagination a", "[data-pjax-container]");
    $doc.pjax("#filters a", "[data-pjax]");
    $doc.on("pjax:send", "[data-pjax]", addSpinner);
    return $doc.on("pjax:complete", "[data-pjax]", removeSpinner);
  }
};

jQuery(function () {
  run_ontag("systems_index", function () {
    return init_systems();
  });
  run_ontag("systems_find", function () {
    return init_systems();
  });
  return run_ontag("systems_dashboard", function () {
    init_charts();
    init_object_crud();
    window.onresize = function () {
      return init_charts();
    };
    return $("#menu_toggle").on("click", function () {
      return window.setTimeout(function () {
        return init_charts();
      }, 0);
    });
  });
});

jQuery.browser = {};

(function () {
  jQuery.browser.msie = false;
  jQuery.browser.version = 0;
  if (navigator.userAgent.match(/MSIE ([0-9]+)\./)) {
    jQuery.browser.msie = true;
    jQuery.browser.version = RegExp.$1;
  }
})();
