/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
/*------------------------------------------------------------
Search & Filters functions
------------------------------------------------------------
 */
var dateDiffDays,
  dateRangeCheck,
  hide_message,
  initExport,
  resetFilter,
  validate_error,
  validate_reset;

dateDiffDays = function (from, to) {
  var diff;
  diff = new Date(todate(to) - todate(from));
  return diff / 1000 / 60 / 60 / 24;
};

validate_error = function (msg) {
  $("#filter_alert").show_error(msg);
  return $('form#export-form input[type="submit"]').attr(
    "disabled",
    "disabled"
  );
};

validate_reset = function () {
  $("#filter_alert").hide_msgs();
  return $('form#export-form input[type="submit"]').prop("disabled", false);
};

hide_message = function () {
  if ($("div#message-area div.alert-danger").length > 0) {
    return $("div#message-area div.alert-danger").hide();
  }
};

dateRangeCheck = function () {
  var end_input, start_input;
  start_input = $("#start_time").val();
  end_input = $("#end_time").val();
  if (start_input && end_input) {
    if (end_input < start_input) {
      hide_message();
      return validate_error(I18n.t("start_date_gt_end_date"));
    } else if (dateDiffDays(start_input, end_input) > 31) {
      hide_message();
      return validate_error(I18n.t("export_date_range"));
    } else {
      return validate_reset();
    }
  } else {
    return validate_reset();
  }
};

resetFilter = function () {
  var endDate, startDate;
  endDate = $("#filters .datetimepicker-input").attr("data-range-end");
  startDate = $("#filters .datetimepicker-input").attr("data-range-start");
  if ($("#filters .datetimepicker-input").length > 0) {
    return $("#filters .datetimepicker")
      .datetimepicker({
        format: "YYYY-MM-DD HH:mm:ss",
        ignoreReadonly: true,
        maxDate: new Date(endDate.replace(/\s+/, "T")),
        minDate: new Date(startDate.replace(/\s+/, "T")),
        useCurrent: false
      })
      .on("dp.change", dateRangeCheck);
  }
};

/*------------------------------------------------------------
File download related functions
------------------------------------------------------------
 */

/*
This version is not used, it's direct download
initExport = ->
  $("form#export-form").submit ->
    $("#export-indicator").modal {backdrop: 'static'}
    $("#export-indicator .progress").toggleClass('hide', false)
    show_alert I18n.t("preparing_file")

    url = "/" + $(this).attr('action')
     * remove hidden _method because it use put
    $('form#user_preference_form [name="_method"]').remove()
    params = $("form#user_preference_form").serialize() + '&' + $("form#filters").serialize() + '&format=xlsx'

    $.fileDownload url, {
      httpMethod: 'POST',
      data: params,
      successCallback : (url) ->
         * show_alert(I18n.t("succeed_to_export"))
        $("#export-indicator").modal('hide')
      ,
      failCallback : (url) ->
        show_error(I18n.t("failed_to_export"))
        $("#export-indicator .progress").toggleClass('hide', true)
    }
    return false
 */

initExport = function () {
  if ($(".alert.alert-cookie").length > 0) {
    $("#filter_alert .alert.alert-cookie").hide();
  }
  return $("form#export-form").submit(function () {
    $('form#user_preference_form [name="_method"]').remove();
    $("form#user_preference_form :input")
      .not(":submit")
      .clone()
      .hide()
      .appendTo("form#export-form");
    $("form#filters :input")
      .not(":submit")
      .clone()
      .hide()
      .appendTo("form#export-form");
    return true;
  });
};

jQuery(function () {
  return run_ontag("ultralink_event_exports", function () {
    resetFilter();
    initExport();
    return preferences_init();
  });
});
