/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const init_charts = function () {
  $("#signalStrengthChartInfo").hide();
  $("#siteAvailabilityChartInfo").hide();
  $("#siteConnectivityChartInfo").hide();

  $(".page-title").html(I18n.t("system_dashboard"));
  if ($("#firmwareVersionChart").length > 0) {
    const firmwareVersionChart = $("#firmwareVersionChart");
    let firmwareVersioninfo = firmwareVersionChart.data("value");
    if (!Array.isArray(firmwareVersioninfo)) {
      firmwareVersioninfo = [];
    }
    let dataCheck = false;
    if (firmwareVersioninfo && firmwareVersioninfo.length > 0)
    {
      dataCheck = true;
    }
    if (dataCheck)
    {
      const firmwareVersionPlot = $.plot(
        "#firmwareVersionChart",
        firmwareVersioninfo,
        {
          tooltip: {
            show: true,
            content: "%s: %n (%p.2%)",
            defaultTheme: false,
          },
          series: {
            pie: {
              show: firmwareVersioninfo.length > 0,
              radius: 0.8,
              label: {
                show: false,
              },
              background: {
                opacity: 0.8,
                color: "#000",
              },
            },
          },
          legend: {
            show: firmwareVersioninfo.length > 0,
            container: "#firmwareVersionLegend",
          },
          grid: {
            hoverable: firmwareVersioninfo.length > 0,
            clickable: firmwareVersioninfo.length > 0,
            borderWidth: 0
          },
        }
      );

      if (firmwareVersionPlot.getData().length === 0) {
        render_no_data_label(firmwareVersionPlot);
      }

      firmwareVersionChart
        .off("plotclick")
        .on("plotclick", function (event, pos, item) {
          if (item) {
            return (window.location.href =
              "/customers/find?customer_query[firmware_status]=" +
              item.series.label);
          }
        });
    }
    else
    {
      $("#firmwareVersionChart").closest("div.dashboard-graph").hide();
    }
  }

  if ($("#firmwareVersionRecorderChart").length > 0) {
    const firmwareVersionRecorderChart = $("#firmwareVersionRecorderChart");
    let firmwareVersionRecorderinfo = firmwareVersionRecorderChart.data("value");
    if (!Array.isArray(firmwareVersionRecorderinfo)) {
      firmwareVersionRecorderinfo = [];
    }
    let dataCheck = false;
    if (firmwareVersionRecorderinfo && firmwareVersionRecorderinfo.length > 0)
    {
      dataCheck = true;
    }
    if (dataCheck)
    {
      const firmwareVersionRecorderPlot = $.plot(
        "#firmwareVersionRecorderChart",
        firmwareVersionRecorderinfo,
        {
          tooltip: {
            show: true,
            content: "%s: %n (%p.2%)",
            defaultTheme: false,
          },
          series: {
            pie: {
              show: firmwareVersionRecorderinfo.length > 0,
              radius: 0.8,
              label: {
                show: false,
              },
              background: {
                opacity: 0.8,
                color: "#000",
              },
            },
          },
          legend: {
            show: firmwareVersionRecorderinfo.length > 0,
            container: "#firmwareVersionRecorderLegend",
          },
          grid: {
            hoverable: firmwareVersionRecorderinfo.length > 0,
            clickable: firmwareVersionRecorderinfo.length > 0,
            borderWidth: 0
          },
        }
      );

      if (firmwareVersionRecorderPlot.getData().length === 0) {
        render_no_data_label(firmwareVersionRecorderPlot);
      }

      firmwareVersionRecorderChart
        .off("plotclick")
        .on("plotclick", function (event, pos, item) {
          if (item) {
            return (window.location.href =
              "/customers/find?customer_query[site_type]=1&customer_query[swver]=" +
              item.series.label);
          }
        });
    }
    else
    {
      $("#firmwareVersionRecorderChart").closest("div.dashboard-graph").hide();
    }
  }

  if ($("#serviceGradeChart").length > 0) {
    let sg_opinfo = $("#serviceGradeChart").data("value");
    if (!Array.isArray(sg_opinfo)) {
      sg_opinfo = [];
    }
    const serviceGradePlot = $.plot("#serviceGradeChart", sg_opinfo, {
      tooltip: {
        show: true,
        content: "%s: %n (%p.2%)",
        defaultTheme: false,
      },
      series: {
        pie: {
          show: sg_opinfo.length > 0,
          radius: 0.8,
          label: {
            show: false,
          },
          background: {
            opacity: 0.8,
            color: "#000",
          },
        },
      },
      legend: {
        show: sg_opinfo.length > 0,
        container: "#serviceGradeLegend",
      },
      grid: {
        hoverable: sg_opinfo.length > 0,
        clickable: sg_opinfo.length > 0,
        borderWidth: 0
      },
    });

    if (serviceGradePlot.getData().length === 0) {
      render_no_data_label(serviceGradePlot);
    }

    $("#serviceGradeChart")
      .off("plotclick")
      .on("plotclick", function (event, pos, obj) {
        if (obj) {
          return (window.location.href =
            "/customers/find?customer_query[service_grade]=" +
            obj.series.label +
            "&customer_query[exact_match]=true");
        }
      });
  }

  if ($("#centralStationAccountChart").length > 0) {
    const centralStationPieChart = $("#centralStationAccountChart");
    let cs_pie_opinfo = centralStationPieChart.data("value");
    if (!Array.isArray(cs_pie_opinfo)) {
      cs_pie_opinfo = [];
    }

    if (cs_pie_opinfo.length > 1) {
      $("#centralStationAccountChart")
        .closest("div.dashboard-graph")
        .css("display", "block");
      const centralStationPlot = $.plot(
        "#centralStationAccountChart",
        cs_pie_opinfo,
        {
          tooltip: {
            show: true,
            content: "%s: %n (%p.2%)",
            defaultTheme: false,
          },
          series: {
            pie: {
              show: cs_pie_opinfo.length > 1,
              radius: 0.8,
              label: {
                show: false,
              },
              background: {
                opacity: 0.5,
                color: "#000",
              },
            },
          },
          legend: {
            show: cs_pie_opinfo.length > 1,
            container: "#centralStationAccountLegend",
          },
          grid: {
            hoverable: cs_pie_opinfo.length > 1,
            clickable: cs_pie_opinfo.length > 1,
            borderWidth: 0
          },
        }
      );
    } else {
      $("#centralStationAccountChart").closest("div.dashboard-graph").hide();
    }

    //render_no_data_label(centralStationPlot) if centralStationPlot.getData().length == 0

    $("#centralStationAccountChart")
      .off("plotclick")
      .on("plotclick", function (event, pos, obj) {
        if (obj) {
          return (window.location.href =
            "/customers/find?customer_query[agency_name]=" +
            obj.series.label +
            "&customer_query[exact_match]=true");
        }
      });
  }

  // Sites availability pie chart
  if ($("#siteAvailabilityChart").length > 0) {
    graphsFilter['show_site_availability_view'](active_filter_view('siteAvailabilityChart', 'month'))
  }

  // Sites added recently pie chart for system admin start
  if ($("#sitesAddedRecentlyChart").length > 0) {
    graphsFilter['show_sites_added_recently_view'](active_filter_view('sitesAddedRecentlyChart', 'month'))
  }

  if ($("#sitesAddedRecentlyBarChart").length > 0) {
    const chart = $("#sitesAddedRecentlyBarChart");
    let site_bargraph = chart.data("value");
    let dataCheck = false;

    if (site_bargraph && site_bargraph.length > 0) {
      if (site_bargraph[0] && site_bargraph[0].data.length !== 0) {
        $.each(site_bargraph[0].data, function (i, el) {
          if (el[0] > 0) {
            dataCheck = true;
            return false;
          }
        });
      }
    }

    if (!Array.isArray(site_bargraph) || site_bargraph.length == 0) {
      site_bargraph = [{ ticks: [] }];
    }
    const { ticks } = site_bargraph[0];

    const sitesAddedBarPlot = $.plot($("#sitesAddedRecentlyBarChart"), site_bargraph, {
      series: {
        bars: {
          show: ticks.length > 0,
          horizontal: true,
        },
      },
      bars: {
        align: "center",
        barWidth: 0.5,
        lineWidth: !dataCheck ? 0 : undefined,
      },
      xaxis: {
        show: false,
      },
      yaxis: {
        position: "left",
        show: dataCheck,
        ticks,
        tickColor: "#fff",
      },
      grid: {
        hoverable: true,
        autoHighlight: true,
        mouseActiveRadius: 1,
        clickable: dataCheck,
        borderWidth: 0,
        markings: dataCheck
          ? [{ xaxis: { from: 0, to: 0 }, color: "#eee" }]
          : undefined,
      },
    });

    if (ticks.length === 0 || !dataCheck) {
      render_no_data_label(sitesAddedBarPlot);
    }

    chart.off("plotclick").on("plotclick", function (event, pos, item) {
      if (item) {
        return (window.location.href =
          "/customers/find?&customer_query[sites_added_in_week]=" +
          item.series.ticks[item.dataIndex][2]);
      }
    });

    $.each(sitesAddedBarPlot.getData()[0].data, function (i, el) {
      if (el[0] > 0) {
        const o = sitesAddedBarPlot.pointOffset({
          x: el[0],
          y: el[1],
        });

        $('<div class="data-point-label">' + el[0] + "</div>")
          .css({
            position: "absolute",
            right: -20,
            top: o.top - 5,
            display: "none",
          })
          .appendTo(sitesAddedBarPlot.getPlaceholder())
          .fadeIn("slow");
      }
    });
  }

  if ($("#signalStrengthChart").length > 0) {
    let signal_strength_opinfo = $("#signalStrengthChart").data("value");
    let dataCheck = false;
    if (!Array.isArray(signal_strength_opinfo)) {
      signal_strength_opinfo = [];
    }
    if (signal_strength_opinfo && signal_strength_opinfo.length > 0) {
      dataCheck = true;
      $("#signalStrengthChartInfo").show();
      $("#signalStrengthChartInfo span").text(I18n.t("signal_strength_info"));
    }
    if (dataCheck)
    {
      const signalStrengthPlot = $.plot("#signalStrengthChart", signal_strength_opinfo, {
        tooltip: {
          show: true,
          content: "%s: %n (%p.2%)",
          defaultTheme: false,
        },
        series: {
          pie: {
            show: signal_strength_opinfo.length > 0,
            radius: 0.8,
            label: {
              show: false,
            },
            background: {
              opacity: 0.8,
              color: "#000",
            },
          },
        },
        legend: {
          show: signal_strength_opinfo.length > 0,
          container: "#signalStrengthLegend",
        },
        grid: {
          hoverable: signal_strength_opinfo.length > 0,
          clickable: signal_strength_opinfo.length > 0,
          borderWidth: 0
        },
      });

      if (signalStrengthPlot.getData().length === 0) {
        render_no_data_label(signalStrengthPlot);
      }

      $("#signalStrengthChart")
        .off("plotclick")
        .on("plotclick", function (event, pos, obj) {
          if (obj) {
            return (window.location.href =
              "/customers/find?customer_query[signal_status]=" +
              obj.series.label);
          }
        });
    }
    else
    {
      $("#signalStrengthChart").closest("div.dashboard-graph").hide();
    }
  }

  if ($("#siteConnectivityChart").length > 0) {
    let connectivity_opinfo = $("#siteConnectivityChart").data("value");
    let dataCheck = false;
    if (!Array.isArray(connectivity_opinfo)) {
      connectivity_opinfo = [];
    }
    if (connectivity_opinfo && connectivity_opinfo.length > 0) {
      dataCheck = true;
      $("#siteConnectivityChartInfo").show();
      $("#siteConnectivityChartInfo span").text(I18n.t("connectivity_graph_info"));
    }

    if (dataCheck)
    {
      const siteConnectivityPlot = $.plot(
        "#siteConnectivityChart",
        connectivity_opinfo,
        {
          tooltip: {
            show: true,
            content: "%s: %n (%p.2%)",
            defaultTheme: false,
          },
          series: {
            pie: {
              show: connectivity_opinfo.length > 0,
              radius: 0.8,
              label: {
                show: false,
              },
              background: {
                opacity: 0.8,
                color: "#000",
              },
            },
          },
          legend: {
            show: connectivity_opinfo.length > 0,
            container: "#siteConnectivityLegend",
          },
          grid: {
            hoverable: connectivity_opinfo.length > 0,
            clickable: connectivity_opinfo.length > 0,
            borderWidth: 0
          },
        }
      );

      if (siteConnectivityPlot.getData().length === 0) {
        render_no_data_label(siteConnectivityPlot);
      }



      $("#siteConnectivityChart")
        .off("plotclick")
        .on("plotclick", function (event, pos, obj) {
          if (obj) {
            return (window.location.href =
              "/customers/find?customer_query[adminstate]=" + obj.series.label);
          }
        });
    }
    else
    {
      $("#siteConnectivityChart").closest("div.dashboard-graph").hide();
    }
  }

  // user requests bar chart graph start
  if ($("#userRequestBarChart").length > 0) {
    graphsFilter['show_user_request_view'](active_filter_view('userRequestBarChart', 'month'))
  }

  // user access log pie chart graph start
  if ($("#userAccessLogChart").length > 0) {
    graphsFilter['show_user_access_log_view'](active_filter_view('userAccessLogChart', 'month'))
  }

  if ($("#commTroubleEventBarChart").length > 0) {
    graphsFilter['show_comm_trouble_event_view'](active_filter_view('commTroubleEventBarChart', 'day'))
  }

  if ($("#tamperStatusBarChart").length > 0) {
    graphsFilter['show_tamper_status_view'](active_filter_view('tamperStatusBarChart', 'day'))
  }

  if ($("#powerStatusBarChart").length > 0) {
    graphsFilter['show_power_status_view'](active_filter_view('powerStatusBarChart', 'day'))
  }

  if ($("#eventRoutingLoadChart").length > 0) {
    graphsFilter['show_event_routing_chart_view'](active_filter_view('eventRoutingLoadChart', 'day'))
  }

  if ($("#dataUsageBarChart").length > 0) {
    const dataUsageChart = $("#dataUsageBarChart");
    let dataUsageInfo = dataUsageChart.data("value");
    if (!Array.isArray(dataUsageInfo)) {
      dataUsageInfo = [];
    }
    let dataCheck = false;
    if (dataUsageInfo && dataUsageInfo.length > 0)
    {
      dataCheck = dataUsageInfo[0]["dataCheck"]
    }
    if (dataCheck) {
      var dataset = [{ data: dataUsageInfo[0]["data"] }];
      var ticks   = dataUsageInfo[0]["ticks"]
      var flatData = Array.prototype.concat.apply([], dataUsageInfo[0]["data"]);
      var max_val = Math.max.apply(Math, flatData)

      const dataUsagePlot = $.plot($("#dataUsageBarChart"), dataset,{
        tooltip: {
          show: true,
          content: function(label, xval, yval, flotItem)
          {
              return ticks[xval][1]+': '+ yval;
          },
          defaultTheme: false
        },
        series: {
          bars: {
            show: true
          }
        },
        bars: {
          align: "center",
          barWidth: 0.5
        },
        xaxis: {
          show: true,
          ticks: ticks,
          tickLength: 0
        },
        yaxis: {
          show: true,
          tickDecimals: 0,
          min: 0,
          max: max_val
        },
        grid: {
          hoverable: true,
          autoHighlight: true,
          mouseActiveRadius: 1,
          clickable: true,
          borderWidth: 0,
          backgroundColor: { colors: ["#ffffff", "#EDF5FF"] }
        },
        colors: ["#CB4B4B","#4DA74D"]
      });

      dataUsageChart.off("plotclick").on("plotclick", function (event, pos, item) {
        var label;
        if (item) {
          label = ticks[item.dataIndex][1];
          return (window.location.href =
              "/customers/find?customer_query[data_usage_status]="+ label.replace(' ','_'));
        }
      });

    } else {
      $("#dataUsageBarChart").closest("div.dashboard-graph").hide();
    }
  }

  // panel types graph code start here
  if ($("#panelTypesChart").length > 0) {
    let pt_info = $("#panelTypesChart").data("value");
    if (!Array.isArray(pt_info)) {
      pt_info = [];
    }
    let dataCheck = false;
    if (pt_info && pt_info.length > 0)
    {
      dataCheck = true;
    }
    if (dataCheck) {
      const panelTypesPlot = $.plot("#panelTypesChart", pt_info, {
        tooltip: {
          show: true,
          content: "%s: %n (%p.2%)",
          defaultTheme: false,
        },
        series: {
          pie: {
            show: pt_info.length > 0,
            radius: 0.8,
            label: {
              show: false,
            },
            background: {
              opacity: 0.8,
              color: "#000",
            },
          },
        },
        legend: {
          show: pt_info.length > 0,
          container: "#panelTypesLegend",
        },
        grid: {
          hoverable: pt_info.length > 0,
          clickable: pt_info.length > 0,
          borderWidth: 0
        },
      });

      $("#panelTypesChart")
        .off("plotclick")
        .on("plotclick", function (event, pos, obj) {
          if (obj) {
            return (window.location.href =
              "/customers/find?customer_query[product_type]=" + obj.series.label + "&customer_query[exact_match]=true")
          }
        });
    } else {
      $("#panelTypesChart").closest("div.dashboard-graph").hide();
    }
  }
  // end

  if ($("#activeUsersChart").length > 0) {
    let active_opinfo = $("#activeUsersChart").data("value");
    if (!Array.isArray(active_opinfo)) {
      active_opinfo = [];
    }
    const activeUserPlot = $.plot("#activeUsersChart", active_opinfo, {
      tooltip: {
        show: true,
        content: "%s: %n (%p.2%)",
        defaultTheme: false,
      },
      series: {
        pie: {
          show: active_opinfo.length > 0,
          radius: 0.8,
          label: {
            show: false,
          },
          background: {
            opacity: 0.8,
            color: "#000",
          },
        },
      },
      legend: {
        show: active_opinfo.length > 0,
        container: "#activeUsersLegend",
      },
      grid: {
        hoverable: active_opinfo.length > 0,
        clickable: active_opinfo.length > 0,
        borderWidth: 0
      },
    });

    if (activeUserPlot.getData().length === 0) {
      render_no_data_label(activeUserPlot);
    }

    $("#activeUsersChart")
      .off("plotclick")
      .on("plotclick", function (event, pos, obj) {
        if (obj) {
          return (window.location.href =
            "/users/find?user_query[active_user]=" + obj.series.query_label );
        }
      });
  }

  // Active companies bar chart for system admin users only - Start
  if ($("#activeCompaniesBarChart").length > 0) {
    const chart = $("#activeCompaniesBarChart");
    let demo_active_companies = []
    let non_demo_active_companies = []

    let ac_bargraphs = chart.data("value");
    let dataCheck = false;

    if (ac_bargraphs && ac_bargraphs.length > 0) {
      demo_active_companies = ac_bargraphs[0].demo;
      non_demo_active_companies = ac_bargraphs[0].non_demo;

      if (demo_active_companies && demo_active_companies.length !== 0) {
        $.each(demo_active_companies, function (i, el) {
          if (el[1] > 0) {
            dataCheck = true;
            return false;
          }
        });
      }
      if (!dataCheck){
        if (non_demo_active_companies && non_demo_active_companies.length !== 0) {
          $.each(non_demo_active_companies, function (i, el) {
            if (el[1] > 0) {
              dataCheck = true;
              return false;
            }
          });
        }
      }
    }

    if (!Array.isArray(ac_bargraphs)) {
      ac_bargraphs = [{ ticks: [] }];
    }

    if (dataCheck){
      const ticks  = ac_bargraphs[0].ticks;
      const activeCompanyPlot = $.plot($("#activeCompaniesBarChart"),
        [
          { data: non_demo_active_companies,
            label: "Non Demo",
            bars: {
              barWidth: 0.3,
              lineWidth: 1,
              align: "right",
            }
          },
          { data: demo_active_companies,
            label: "Demo",
            bars: {
              barWidth: 0.3,
              lineWidth: 1,
              align: "left",
            },
          }
        ], {
        tooltip: {
          show: true,
          content: function(label, xval, yval, flotItem)
          {
              label = ticks[xval][1] + " ("+ label +")";
              return label+': '+ yval;
          },
          defaultTheme: false
        },
        series: {
          bars: {
            show: ticks.length > 0
          },
        },
        xaxis: {
          show: true,
          ticks: ticks,
          tickLength: 0,
          mode: 'categories'
        },
        yaxis: {
          show: true,
          tickDecimals: 0
        },
        grid: {
          hoverable: true,
          autoHighlight: true,
          mouseActiveRadius: 1,
          clickable: true,
          borderWidth: 0,
          backgroundColor: { colors: ["#ffffff", "#EDF5FF"] }
        },
        colors: ["#4DA74D","#CB4B4B"],
        legend: {
            show: true,
            container: "#activeCompaniesLegend"
          },
      });

      chart.off("plotclick").on("plotclick", function (event, pos, item) {
        if (item) {
          var label = ticks[item.dataIndex][2];
          var label_category = item.series.label;
          var is_demo = (label_category.toLowerCase() == "demo") ? true : false;
          return (window.location.href =
            "/companies/find?company_query[active_companies]=" + label + "&company_query[is_demo]="+ is_demo);
        }
      });
    }
    else {
      $("#activeCompaniesBarChart").closest("div.dashboard-graph").hide();
    }
  }
  // end

  if ($("#activeCompaniesChart").length > 0) {
    let active_opinfo = $("#activeCompaniesChart").data("value");
    if (!Array.isArray(active_opinfo)) {
      active_opinfo = [];
    }
    const activeUserPlot = $.plot("#activeCompaniesChart", active_opinfo, {
      tooltip: {
        show: true,
        content: "%s: %n (%p.2%)",
        defaultTheme: false,
      },
      series: {
        pie: {
          show: active_opinfo.length > 0,
          radius: 0.8,
          label: {
            show: false,
          },
          background: {
            opacity: 0.8,
            color: "#000",
          },
        },
      },
      legend: {
        show: active_opinfo.length > 0,
        container: "#activeCompaniesLegend",
      },
      grid: {
        hoverable: active_opinfo.length > 0,
        clickable: active_opinfo.length > 0,
        borderWidth: 0
      },
    });

    if (activeUserPlot.getData().length === 0) {
      render_no_data_label(activeUserPlot);
    }

    $("#activeCompaniesChart")
      .off("plotclick")
      .on("plotclick", function (event, pos, obj) {
        if (obj) {
          return (window.location.href =
            "/companies/find?company_query[active_companies]=" + obj.series.company_type_title );
        }
      });
  }

  if ($("#systemPerformanceChart").length > 0) {
    let performance_info = $("#systemPerformanceChart").data("value");
    let dataCheck = false;
    if (!Array.isArray(performance_info)) {
      performance_info = [];
    }
    if (performance_info && performance_info.length > 0) {
      dataCheck = true;
    }

    const systemPerformancePlot = $.plot(
      "#systemPerformanceChart",
      performance_info,
      {
        tooltip: {
          show: true,
          content: "%s: %n (%p.2%)",
          defaultTheme: false,
        },
        series: {
          pie: {
            show: performance_info.length > 0,
            radius: 0.8,
            label: {
              show: false,
            },
            background: {
              opacity: 0.8,
              color: "#000",
            },
          },
        },
        legend: {
          show: performance_info.length > 0,
          container: "#systemPerformanceLegend",
        },
        grid: {
          hoverable: performance_info.length > 0,
          clickable: performance_info.length > 0,
          borderWidth: 0
        },
      }
    );

    if (systemPerformancePlot.getData().length === 0) {
      render_no_data_label(systemPerformancePlot);
    }

    $("#systemPerformanceChart")
      .off("plotclick")
      .on("plotclick", function (event, pos, obj) {
        if (obj) {
          return (window.location.href =
            "/customers/find?customer_query[systemstate]=" + obj.series.label);
        }
      });
  }

  if ($("#activeInterfaceChart").length > 0) {
    let interface_info = $("#activeInterfaceChart").data("value");
    let dataCheck = false;
    if (!Array.isArray(interface_info)) {
      interface_info = [];
    }
    if (interface_info && interface_info.length > 0) {
      dataCheck = true;
    }

    const activeInterfacePlot = $.plot(
      "#activeInterfaceChart",
      interface_info,
      {
        tooltip: {
          show: true,
          content: "%s: %n (%p.2%)",
          defaultTheme: false,
        },
        series: {
          pie: {
            show: interface_info.length > 0,
            radius: 0.8,
            label: {
              show: false,
            },
            background: {
              opacity: 0.8,
              color: "#000",
            },
          },
        },
        legend: {
          show: interface_info.length > 0,
          container: "#activeInterfaceLegend",
        },
        grid: {
          hoverable: interface_info.length > 0,
          clickable: interface_info.length > 0,
          borderWidth: 0
        },
      }
    );

    if (activeInterfacePlot.getData().length === 0) {
      render_no_data_label(activeInterfacePlot);
    }

    $("#activeInterfaceChart")
      .off("plotclick")
      .on("plotclick", function (event, pos, obj) {
        if (obj) {
          return (window.location.href =
            "/customers/find?customer_query[interfacetype]=" + obj.series.interface_type);
        }
      });
  }
};

var graphsFilter = {};
var filter_types = ['day', 'week', 'month']

graphsFilter['show_user_access_log_view'] = function(chart_view) {
  let ual_opinfo = $("#userAccessLogChart").data("value");
  if (!Array.isArray(ual_opinfo) || ual_opinfo.length == 0) {
    ual_opinfo = [];
  } else {
    ual_opinfo = ual_opinfo[0][chart_view]
  }
  ual_opinfo = data_undefined_check(ual_opinfo);

  const userAccessLogPlot = $.plot("#userAccessLogChart", ual_opinfo, {
    tooltip: {
      show: true,
      content: "%s: %n (%p.2%)",
      defaultTheme: false,
    },
    series: {
      pie: {
        show: ual_opinfo.length > 0,
        radius: 0.8,
        label: {
          show: false,
        },
        background: {
          opacity: 0.8,
          color: "#000",
        },
      },
    },
    legend: {
      show: ual_opinfo.length > 0,
      container: "#userAccessLogLegend",
    },
    grid: {
      hoverable: ual_opinfo.length > 0,
      clickable: ual_opinfo.length > 0,
      borderWidth: 0
    },
  });

  if (userAccessLogPlot.getData().length === 0) {
    render_no_data_label(userAccessLogPlot);
  }

  $("#userAccessLogChart")
    .off("plotclick")
    .on("plotclick", function (event, pos, obj) {
      if (obj) {
        return (window.location.href =
          "/user_access_logs/find?user_access_log_query[acstype]=" +
          ual_opinfo[obj.seriesIndex].log_number +
          "&user_access_log_query[request_from]=dashboard&user_access_log_query[log_view]="+chart_view);
      }
    });
};

graphsFilter['show_sites_added_recently_view'] = function(chart_view) {
  let sites_added_info = $("#sitesAddedRecentlyChart").data("value");
  if (!Array.isArray(sites_added_info) || sites_added_info.length == 0) {
    sites_added_info = [];
  } else {
    sites_added_info = sites_added_info[0][chart_view]
  }
  sites_added_info = data_undefined_check(sites_added_info);

  const sitesAddedPlot = $.plot("#sitesAddedRecentlyChart", sites_added_info, {
    tooltip: {
      show: true,
      content: "%s: %n (%p.2%)",
      defaultTheme: false,
    },
    series: {
      pie: {
        show: sites_added_info.length > 0,
        radius: 0.8,
        label: {
          show: false,
        },
        background: {
          opacity: 0.8,
          color: "#000",
        },
      },
    },
    legend: {
      show: sites_added_info.length > 0,
      container: "#sitesAddedRecentlyLegend",
    },
    grid: {
      hoverable: sites_added_info.length > 0,
      clickable: sites_added_info.length > 0,
      borderWidth: 0
    },
  });
  if (sitesAddedPlot.getData().length === 0) {
    render_no_data_label(sitesAddedPlot);
  }
  $("#sitesAddedRecentlyChart")
    .off("plotclick")
    .on("plotclick", function (event, pos, item) {
      if (item) {
        if (item.series.label !== "Added Recently") {
          const sitedata = sites_added_info[item.seriesIndex];
          return (window.location.href =
            "/customers/find?customer_query[sales_region]=" +
            sitedata.sales_region +
            "&customer_query[filter_type]=" +
            chart_view +
            "&customer_query[first_registration]=Added Recently");
        } else {
          return (window.location.href =
            "/customers/find?&customer_query[first_registration]=Added Recently");
        }
      }
    });
};

graphsFilter['show_site_availability_view'] = function(chart_view) {
  const siteAvailabilityChart = $("#siteAvailabilityChart");
  let site_avail_data = siteAvailabilityChart.data("value");
  if (!Array.isArray(site_avail_data) || site_avail_data.length == 0) {
    sa_opinfo = [];
  } else {
    sa_opinfo = site_avail_data[0][chart_view]
  }
  let dataCheck = pieGraphDataCheck(site_avail_data)

  if (dataCheck)
  {
    sa_opinfo = data_undefined_check(sa_opinfo)
    const siteAvailabilityPlot = $.plot("#siteAvailabilityChart", sa_opinfo, {
      tooltip: {
        show: true,
        content: "%s: %n (%p.2%)",
        defaultTheme: false,
      },
      series: {
        pie: {
          show: sa_opinfo.length > 0,
          radius: 0.8,
          label: {
            show: false,
          },
          background: {
            opacity: 0.8,
            color: "#000",
          },
        },
      },
      legend: {
        show: sa_opinfo.length > 0,
        container: "#siteAvailabilityLegend",
      },
      grid: {
        hoverable: sa_opinfo.length > 0,
        clickable: sa_opinfo.length > 0,
        borderWidth: 0
      },
    });

    if (siteAvailabilityPlot.getData().length === 0) {
      render_no_data_label(siteAvailabilityPlot);
    }

    siteAvailabilityChart
      .off("plotclick")
      .on("plotclick", function (event, pos, item) {
        if (item) {
          const sitedata = sa_opinfo[item.seriesIndex];
          return (window.location.href =
            "/customers/find?customer_query[filter_type]="+ chart_view +"&customer_query[availability]="+sitedata.index);
        }
      });

    $("#siteAvailabilityChartInfo").show();
    $("#siteAvailabilityChartInfo span").text(I18n.t("availability_graph_info"));
  }
  else
  {
    $("#siteAvailabilityChart").closest("div.dashboard-graph").hide();
  }
};

graphsFilter['show_user_request_view'] = function(chart_view) {
  let us_chart = $("#userRequestBarChart");
  let ur_opinfo = $("#userRequestBarChart").data("value");
  let data = ur_opinfo[0][chart_view]['data'];
  let dataset = [{data: data, color: "#5482FF" }];
  let ticks = ur_opinfo[0][chart_view]['ticks'];
  let dataCheck = false;

  if (dataset && dataset.length > 0) {
    if (dataset[0] && dataset[0].data && dataset[0].data.length !== 0) {
      $.each(dataset[0].data, function (i, el) {
        if (el[0] >= 0) {
          dataCheck = true;
          return false;
        }
      });
    }
  }

  if (!Array.isArray(dataset) || dataset.length == 0 || dataset[0].data === undefined) {
    dataset = [{ ticks: [] }];
    ticks = []
  }

  let options = {
      tooltip: {
          show: true,
          content: function(label, xval, yval, flotItem)
          {
              return ticks[xval][1]+': '+ yval;
          },
          defaultTheme: false
      },
      series: {
          bars: {
              show: true
          }
      },
      bars: {
          align: (dataCheck && dataset[0].data.length < 5) ? "center" : "left",
          barWidth: 0.3,
          lineWidth: 1
      },
      xaxis: {
          ticks: ticks,
          mode: "categories",
          tickLength: 1
      },
      yaxis: {
          tickDecimals: 0,
          min: 0
      },
      legend: {
          show: false,
      },
      grid: {
          hoverable: true,
          borderWidth: 0,
          autoHighlight: true,
          mouseActiveRadius: 1,
          clickable: dataCheck,
          backgroundColor: { colors: ["#ffffff", "#EDF5FF"] }
      }
  };

  const userRequestPlot =  $.plot($("#userRequestBarChart"), dataset, options);

    if (ticks.length === 0 || !dataCheck) {
      render_no_data_label(userRequestPlot);
    }

    us_chart.off("plotclick").on("plotclick", function (event, pos, obj) {
      if (obj) {
        return (window.location.href =
          "/user_requests/find?user_request_query[request_type]=" +
          ur_opinfo[0][chart_view]["request_type"][ticks[obj.dataIndex][0]][1] +
          "&user_request_query[request_from]=dashboard&user_request_query[event_view]="+chart_view);
      }
    });

};

graphsFilter['show_comm_trouble_event_view'] = function(chart_view) {
  const chart = $("#commTroubleEventBarChart");
  let comm_bargraph_event = []
  let comm_bargraph_restore = []
  let comm_bargraph = chart.data("value");
  let dataCheck = barGraphDataCheck(comm_bargraph);

  if (!Array.isArray(comm_bargraph)) {
    comm_bargraph = [{ ticks: [] }];
  }

  if (dataCheck) {
    const ticks  = comm_bargraph[0].ticks;
    const comm_bargraph_event = comm_bargraph[0][chart_view].data_event;
    const comm_bargraph_restore = comm_bargraph[0][chart_view].data_restore;
    const commTroubleEventPlot = $.plot($("#commTroubleEventBarChart"),
      [{ data: comm_bargraph_event,
         label: "Event",
          bars: {
            barWidth: 0.3,
            lineWidth: 1,
            align: "right",
          },
       },
      { data: comm_bargraph_restore,
        label: "Restore",
          bars: {
            barWidth: 0.3,
            lineWidth: 1,
            align: "left",
          },
       },
      ], {
      series: {
        bars: {
          show: ticks.length > 0
        },
      },
      xaxis: {
        show: true,
        ticks: ticks,
        tickLength: 0,
        mode: 'categories'
      },
      yaxis: {
        show: true,
        tickDecimals: 0,
        min: 0
      },
      grid: {
        hoverable: true,
        autoHighlight: true,
        mouseActiveRadius: 1,
        clickable: true,
        borderWidth: 0,
        backgroundColor: { colors: ["#ffffff", "#EDF5FF"] }
      },
      colors: ["#CB4B4B","#4DA74D"],
      legend: {
          show: true,
          container: "#commTroubleEventLegend"
        },
    });

    if (ticks.length === 0 || !dataCheck) {
      render_no_data_label(commTroubleEventPlot);
    }

    chart.off("plotclick").on("plotclick", function (event, pos, item) {
      if (item) {
        return (window.location.href =
          "/customers/find?&customer_query[event_code]="
          +ticks[item.dataIndex][2]+"&customer_query[qualifier_type]="+item.series.label+"&customer_query[event_view]="+chart_view);
      }
    });
  }
  else {
    $("#commTroubleEventBarChart").closest("div.dashboard-graph").hide();
  }
}

graphsFilter['show_tamper_status_view'] = function(chart_view) {
  const chart = $("#tamperStatusBarChart");
  let tamper_bargraph_event = []
  let tamper_bargraph_restore = []
  let tamper_bargraph = chart.data("value");
  let dataCheck = barGraphDataCheck(tamper_bargraph);

  if (!Array.isArray(tamper_bargraph)) {
    tamper_bargraph = [{ ticks: [] }];
  }

  if (dataCheck){
    const ticks  = tamper_bargraph[0].ticks;
    const tamper_bargraph_event = tamper_bargraph[0][chart_view].data_event;
    const tamper_bargraph_restore = tamper_bargraph[0][chart_view].data_restore;
    const tamperStatusEventPlot = $.plot($("#tamperStatusBarChart"),
      [{ data: tamper_bargraph_event,
         label: "Event",
          bars: {
            barWidth: 0.3,
            lineWidth: 1,
            align: "right",
          },
       },
      { data: tamper_bargraph_restore,
        label: "Restore",
          bars: {
            barWidth: 0.3,
            lineWidth: 1,
            align: "left",
          },
       },
      ], {
      series: {
        bars: {
          show: ticks.length > 0
        },
      },
      xaxis: {
        show: true,
        ticks: ticks,
        tickLength: 0,
        mode: 'categories'
      },
      yaxis: {
        show: true,
        tickDecimals: 0,
        min: 0
      },
      grid: {
        hoverable: true,
        autoHighlight: true,
        mouseActiveRadius: 1,
        clickable: true,
        borderWidth: 0,
        backgroundColor: { colors: ["#ffffff", "#EDF5FF"] }
      },
      colors: ["#CB4B4B","#4DA74D"],
      legend: {
          show: true,
          container: "#tamperStatusLegend"
        },
    });

    if (ticks.length === 0 || !dataCheck) {
      render_no_data_label(tamperStatusEventPlot);
    }

    chart.off("plotclick").on("plotclick", function (event, pos, item) {
      if (item) {
        return (window.location.href =
          "/customers/find?&customer_query[event_code]="
          +ticks[item.dataIndex][2]+"&customer_query[qualifier_type]="+item.series.label+"&customer_query[event_view]="+chart_view);
      }
    });
  }
  else {
    $("#tamperStatusBarChart").closest("div.dashboard-graph").hide();
  }
};

graphsFilter['show_power_status_view'] = function(chart_view) {
  const chart = $("#powerStatusBarChart");
  let power_bargraph_event = []
  let power_bargraph_restore = []
  let power_bargraph = chart.data("value");
  let dataCheck = barGraphDataCheck(power_bargraph);

  if (!Array.isArray(power_bargraph)) {
    power_bargraph = [{ ticks: [] }];
  }

  if (dataCheck){
    const ticks  = power_bargraph[0].ticks;
    const power_bargraph_event   = power_bargraph[0][chart_view].data_event;
    const power_bargraph_restore = power_bargraph[0][chart_view].data_restore;
    const powerStatusEventPlot = $.plot($("#powerStatusBarChart"),
    [{ data: power_bargraph_event,
       label: "Event",
        bars: {
          barWidth: 0.3,
          lineWidth: 1,
          align: "right",
        },
     },
    { data: power_bargraph_restore,
      label: "Restore",
        bars: {
          barWidth: 0.3,
          lineWidth: 1,
          align: "left",
        },
     },
    ], {
    series: {
      bars: {
        show: ticks.length > 0
      },
    },
    xaxis: {
      show: true,
      ticks: ticks,
      tickLength: 0,
      mode: 'categories'
    },
    yaxis: {
      show: true,
      tickDecimals: 0,
      min: 0
    },
    grid: {
      hoverable: true,
      autoHighlight: true,
      mouseActiveRadius: 1,
      clickable: true,
      borderWidth: 0,
      backgroundColor: { colors: ["#ffffff", "#EDF5FF"] }
    },
    colors: ["#CB4B4B","#4DA74D"],
    legend: {
        show: true,
        container: "#powerStatusLegend"
      },
  });

  if (ticks.length === 0 || !dataCheck) {
    render_no_data_label(powerStatusEventPlot);
  }

  chart.off("plotclick").on("plotclick", function (event, pos, item) {
    if (item) {
      return (window.location.href =
        "/customers/find?&customer_query[event_code]="
        +ticks[item.dataIndex][2]+"&customer_query[qualifier_type]="+item.series.label+"&customer_query[event_view]="+chart_view);
    }
  });
  }
  else {
    $("#powerStatusBarChart").closest("div.dashboard-graph").hide();
  }
};

graphsFilter['show_event_routing_chart_view'] = function(chart_view) {
  event_stats_chart = $("#eventRoutingLoadChart");
  view = $(".event-route-filter .filterActive").data("view");
  dataValue = event_stats_chart.data("value");
  tooltip_opt = {
                  show: true,
                  content: function(label, xval, yval, flotItem)
                  {
                      return formatTime(new Date(xval))+': '+ yval;
                  },
                  defaultTheme: false
                }
  if (dataValue && dataValue.length > 0) {
    opinfo = [dataValue[0][chart_view][0]];
    date_from = new Date(parseInt(dataValue[0][chart_view][1]));
    date_to = new Date(parseInt(dataValue[0][chart_view][2]));
    getOptionsFromView = function () {
      if (view === "day") {
        return {
        tooltip: tooltip_opt,
          xaxis: {
            mode: "time",
            timeBase: "milliseconds",
            minTickSize: [1, "hour"],
            min: date_from.getTime(),
            max: date_to.getTime(),
            twelveHourClock: true,
          },
          yaxis: {
            min: 0
          },
          grid: {
            hoverable: true,
            autoHighlight: true,
          },
        };
      } else if (view === "week") {
        return {
        tooltip: tooltip_opt,
          xaxis: {
            mode: "time",
            minTickSize: [1, "day"],
            min: date_from.getTime(),
            max: date_to.getTime(),
            dayNames: ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"],
            tickFormatter: function (val, axis) {
              return new Date(val).toString().split(" ")[0];
            },
          },
          yaxis: {
            min: 0
          },
          grid: {
            hoverable: true,
            autoHighlight: true,
            mouseActiveRadius: 1,
          },
        };
      }
        else if (view === "month") {
        return {
        tooltip: tooltip_opt,
          xaxis: {
            mode: "time",
            minTickSize: [2, "day"]
          },
          yaxis: {
            min: 0
          },
          grid: {
            hoverable: true,
            autoHighlight: true,
            mouseActiveRadius: 1,
          },
        };
      }
    };
    $.plot("#eventRoutingLoadChart", opinfo, getOptionsFromView());
    formatTime = function (date) {
      var ampm, dayOfWeek, hours, minutes, strTime;
      dayOfWeek = function (dow) {
        var days;
        days = [I18n.t('reports_set.sunday'), I18n.t('reports_set.monday'), I18n.t('reports_set.tuesday'), I18n.t('reports_set.wednesday'),
                I18n.t('reports_set.thursday'), I18n.t('reports_set.friday'), I18n.t('reports_set.saturday')];
        return days[dow];
      };
      monthName = function (month) {
        var months;
        months = [I18n.t('reports_set.january'), I18n.t('reports_set.february'), I18n.t('reports_set.march'), I18n.t('reports_set.april'),
                  I18n.t('reports_set.may'), I18n.t('reports_set.june'), I18n.t('reports_set.july'), I18n.t('reports_set.august'),
                  I18n.t('reports_set.september'), I18n.t('reports_set.october'), I18n.t('reports_set.november'), I18n.t('reports_set.december')]
        return months[month];
      };
      hours = date.getUTCHours();
      minutes = date.getUTCMinutes();
      ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      strTime = hours + ":" + minutes + " " + ampm;
      if (view) {
        if (view != 'month' ){
          return (strTime = dayOfWeek(date.getUTCDay()) + " - " + strTime);
        }
        else {
          return (strTime = monthName(date.getUTCMonth()) + " " + date.getDate() + " - " + strTime);
        }
      }
    };
  } else {
    const eventRoutingLoadPlot = $.plot("#eventRoutingLoadChart", [], {});
    render_no_data_label(eventRoutingLoadPlot);
  }
};

var render_no_data_label = function (graphPlot) {
  const canvas = graphPlot.getCanvas();
  const ctx = canvas.getContext("2d");
  const x = canvas.width / 2;
  const y = canvas.height / 2;
  ctx.textAlign = "center";
  ctx.font = "12px sans-serif";
  return ctx.fillText(I18n.t("no_data_available"), x, y);
};

// Assign an empty array if any of the graph's data is undefined.
var data_undefined_check = function(graphData){
  return (typeof graphData !== 'undefined') ? graphData : [];
};

var barGraphDataCheck = function(graphData) {
  let dataCheck = false;
  if (graphData && graphData.length > 0) {
    $.each(filter_types, function (index, element) {
      if (typeof graphData[0][element] !== 'undefined') {
        bargraph_event   = graphData[0][element].data_event;
        bargraph_restore = graphData[0][element].data_restore;
        if (bargraph_event && bargraph_event.length !== 0) {
          $.each(bargraph_event, function (i, el) {
            if (el[1] > 0) {
              dataCheck = true;
              return false;
            }
          });
        }
        if (!dataCheck){
          if (bargraph_restore && bargraph_restore.length !== 0) {
            $.each(bargraph_restore, function (i, el) {
              if (el[1] > 0) {
                dataCheck = true;
                return false;
              }
            });
          }
        }
      }
    });
  }
  return dataCheck;
};

var pieGraphDataCheck = function(graphData) {
  let dataCheck = false;
  if (graphData && graphData.length > 0) {
    $.each(filter_types, function (index, element) {
      var filtered_data = graphData[0][element]
      if (filtered_data && filtered_data.length > 0) {
        dataCheck = true;
        return false;
      }
    });
  }
  return dataCheck;
};

var active_filter_view = function(chart_id, default_view){
  var active_view = $("#"+chart_id).parents('.chart-container').find('.filterActive').data('view');
  return (typeof active_view === 'undefined') ? default_view : active_view;
};

jQuery(() =>
  run_ontag("customers_dashboard", function () {
    init_charts();
    window.onresize = () => init_charts();
    $("#menu_toggle").on("click", () =>
      window.setTimeout(() => init_charts(), 0)
    );
    // Filtering a graph based on click
    $(".flt-onclick").on("click", function (e) {
      $(this).siblings().removeClass('filterActive').addClass('filterIcon');
      $(this).removeClass('filterIcon').addClass('filterActive');
      var dataView = $(this).attr("data-view");
      var dataName = $(this).attr("data-name");
      var graph_method = "show_"+dataName
      graphsFilter[graph_method](dataView)
      return false;
    });
    $("#sortable").sortable({
        update: function(e, ui) {
          $.ajax({
            url: $(this).data("url"),
            type: "PATCH",
            data: $(this).sortable('serialize'),
          });
        }
      });
  })
);
