/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

const initDownload = () => $('a.sim-management-export-download').off('click').on('click', function() {
  $.fileDownload($(this).attr("href"), {
    data: {format: 'xlsx'},
    successCallback(url) {
      show_alert(I18n.t("sim_management_download_successful"));
      return enable_refresh();
    }
    ,
    failCallback(url) {
      return show_error(I18n.t("sim_management_download_failed"));
    }
  });
  return false;
});

var enable_refresh = function() {
  if ($("#message-area a.close").attr("data-refresh") !== "enabled") {
    $("#message-area a.close").attr("data-refresh", "enabled");
    return $("#message-area a.close").on('click', () => setTimeout(refresh, 30000));
  }
};

var refresh = () => $.get('/sim_managements/download_sim_management_export.json', function(obj, status, xhr) {
  if (sessionExpired(xhr)) { return; }
  const {
    download_path
  } = obj;
  if (download_path !== undefined) {
    show_alert('<a class="sim-management-export-download" href="' +
               download_path +
               '">' + I18n.t("sim_management_export_completed") +
               '</a>');
    initDownload();
    return;
  }

  if (download_path === undefined) {
    return setTimeout(refresh, 30000);
  }
});

const document_ready = function() {
  init_datatable({});
  init_search();
  $("#sierra_account_type")
    .off("change")
    .on("change", function () {
      return (window.location.href = "/sim_managements?partner=" + $(this).val());
    });

  selected_sierra_account = $('#sierra_account').val();
  $("#sierra_account_type option[value='"+selected_sierra_account+"']").prop('selected', true);
};

var init_datatable = crud_options => dt.initDatatable($('#sim-details'), {}, {});

var init_search = () => $('.searchtoggle.sim_managements').on('on_search_toggled', function() {
  init_advsearch('/sim_managements/advfind');
  create_advfind_buttons('sim_managements');
});

jQuery(function() {
run_ontag('sim_managements', function() {
  document_ready();
  setTimeout(refresh, 30000);
  $(document).on('pjax:end', document_ready);
  if ($.support.pjax) {
    $.pjax.defaults.scrollTo = false;
    $.pjax.defaults.timeout = false;
    return $(document).pjax('.pagination a', '[data-pjax-container]');
  }
  });
});
