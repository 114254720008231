/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var company_type_select_change, company_type_select_init, edit_init, root;

root = window //typeof exports !== "undefined" && exports !== null ? exports : this;

root.get_next_no_init = function (elem) {
  elem = elem || "#available_number";
  elem = "input" + elem;
  return $("a#next_no").on("click", function () {
    var agency_company_id, d;
    agency_company_id = $(
      'input[name="company_link[agency_company_id]"]:checked'
    ).val();
    d = {
      current: $(elem).val(),
      prefix: $("#number_prefix").val(),
    };
    if (agency_company_id) {
      d.agency_company_id = agency_company_id;
    }
    $.ajax({
      url: $(this).attr("href"),
      data: d,
      success: function (data, status, xhr) {
        if (!sessionExpired(xhr)) {
          return $(elem)
            .filter(":visible")
            .val(data["next"])
            .trigger("change")
            .trigger("blur");
        }
      },
      error: function (xhr, status, errors) {
        return show_alert_pop(
          renderAjaxError(status, errors),
          $("a#next_no").parent()
        );
      },
      dataType: "json",
    });
    return false;
  });
};

edit_init = function () {
  add_validation("edit_user_registration", "available_number");
  company_type_select_init();
  country_select_init("user_registration");
  return get_next_no_init();
};

company_type_select_change = function () {
  if ($(this).val() === "2") {
    return $("#available_number").parent().parent().parent().show();
  } else {
    return $("#available_number").parent().parent().parent().hide();
  }
};

company_type_select_init = function () {
  return $('input[name="user_registration[company_type]"]').on(
    "change",
    company_type_select_change
  );
};

jQuery(function () {
  run_ontag("registrations", function () {
    return init_object_crud({
      "user-registration": {
        edit: edit_init,
        buttons: [
          {
            class: "btn-primary",
          },
          {
            class: "btn-danger",
          },
        ],
      },
    });
  });
  return run_ontag("registrations_new", function () {
    return country_select_init("user_registration");
  });
});
