/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

const initDownload = () => $('a.support-contact-user-export-download').off('click').on('click', function() {
  $.fileDownload($(this).attr("href"), {
    data: {format: 'xlsx'},
    successCallback(url) {
      show_alert(I18n.t("support_contact_download_successful"));
      return enable_refresh();
    }
    ,
    failCallback(url) {
      return show_error(I18n.t("support_contact_download_failed"));
    }
  });
  return false;
});

var enable_refresh = function() {
  if ($("#message-area a.close").attr("data-refresh") !== "enabled") {
    $("#message-area a.close").attr("data-refresh", "enabled");
    return $("#message-area a.close").on('click', () => setTimeout(refresh, 30000));
  }
};

var refresh = () => $.get('/support_contact_users/download_support_contact_export.json', function(obj, status, xhr) {
  if (sessionExpired(xhr)) { return; }
  const {
    download_path
  } = obj;
  if (download_path !== undefined) {
    show_alert('<a class="support-contact-user-export-download" href="' +
               download_path +
               '">' + I18n.t("support_contact_export_completed") +
               '</a>');
    initDownload();
    return;
  }

  if (download_path === undefined) {
    return setTimeout(refresh, 30000);
  }
});


const document_ready = function() {
  const crud_options = {
    'support-contact-user':{
    }
  };
  init_datatable(crud_options);
  return init_search();
};

var init_datatable = crud_options => dt.initDatatable($('#support-contacts-table'), {}, crud_options);

var init_search = () => $('.searchtoggle.support_contact_users').on('on_search_toggled', function() {
  init_advsearch('/support_contact_users/advfind');
  create_advfind_buttons('support_contact_user');
});

jQuery(function() {
run_ontag('support_contact_users', function() {
  document_ready();
  if ($("#created").length) {
    setTimeout(refresh, 30000);
  }
  $(document).on('pjax:end', document_ready);
  if ($.support.pjax) {
    $.pjax.defaults.scrollTo = false;
    $.pjax.defaults.timeout = false;
    return $(document).pjax('.pagination a', '[data-pjax-container]');
  }
  });
});
