/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var document_ready,
  hide_monthly_options,
  hide_weekly_recurrence,
  hide_preferences,
  init_datatable,
  report_export_new_init,
  report_export_edit_init,
  reset_monthly_options,
  toggle_monthly_option_fields,
  toggle_recurrence_fields;

toggle_recurrence_fields = function (checked) {
  if (checked) {
    reset_monthly_options();
    $("#modal-body-new-report #report_type_w").prop("checked", true);
    $("#modal-body-new-report .recurrence_fields").show();
    return $("#report_occurrences").prop("selectedIndex", 0);
  } else {
    $('input[name="report[recurrence_pattern]"]').attr("checked", false);
    $("#report_occurrences").val("");
    return $("#modal-body-new-report .recurrence_fields").hide();
  }
};

toggle_monthly_option_fields = function () {
  return $("#modal-body-new-report #report_monthly_options")
    .off("change")
    .on("change", function () {
      if (
        $("#modal-body-new-report #report_monthly_options").is(":checked") ===
        true
      ) {
        $("#modal-body-new-report #report_week_priority").attr(
          "disabled",
          false
        );
        return $("#modal-body-new-report #report_week_day").attr(
          "disabled",
          false
        );
      } else {
        $("#modal-body-new-report #report_week_priority").attr(
          "disabled",
          true
        );
        return $("#modal-body-new-report #report_week_day").attr(
          "disabled",
          true
        );
      }
    });
};

reload_recorder_list = function () {
  $(".report_filter input").prop("disabled", true);
  $.ajax({
    url: "/reports/filter_recorders",
    data: {
      recorder_model: $("input:checkbox[name='report[preference][]']:checked").map(
        function () { return this.value; }).get()
    },
    type: "GET",
    dataType: "JSON",
    success: function (data, status, xhr) {
      if (!sessionExpired(xhr)) {
       preselected_values = JSON.parse($("#selected_recorders").val())
       $("#recorder_preference_options #report_filter").multiselect('dataprovider', data.recorders);
       $("#recorder_preference_options #report_filter").multiselect('select', preselected_values);
      }
      $(".report_filter input").prop("disabled", false);
    },
    error: function (xhr, status, errors) {
      console.log("ajax error block");
      $(".report_filter input").prop("disabled", false);
    }
  });
};

hide_monthly_options = function () {
  $("div#monthly_options").hide();
  return $("#modal-body-new-report .report_recurrence_type")
    .off("change")
    .on("change", function () {
      if ($("#modal-body-new-report #report_type_w").is(":checked") === true) {
        $("#modal-body-new-report #report_monthly_options").prop(
          "checked",
          false
        );
        $("#modal-body-new-report #report_week_priority").attr(
          "disabled",
          true
        );
        $("#modal-body-new-report #report_week_day").attr("disabled", true);
        $("#modal-body-new-report #report_week_priority").val("0");
        $("#modal-body-new-report #report_week_day").val("0");
        return $("div#monthly_options").hide();
      } else {
        return $("div#monthly_options").show();
      }
    });
};

hide_weekly_recurrence = function () {
  if ($("#modal-body-new-report #report_report_type").val() === "3") {
    $("#modal-body-new-report div#recurrence_fields_non_admin").hide();
    if (
      $("#modal-body-new-report #report_recurrence").is(":checked") === true
    ) {
      $("#report_type_w").parent().hide();
      $("#report_type_m").prop("checked", true);
      return $("div#monthly_options").show();
    } else {
      $("#report_type_w").prop("checked", false);
      return $("#report_type_m").prop("checked", false);
    }
  } else if ( $("#modal-body-new-report #report_report_type").val() === "5") {
    $("#modal-body-new-report div#recurrence_fields_non_admin").show();
    if (
      $("#modal-body-new-report #report_recurrence").is(":checked") === true
    ) {
      $("#report_type_w").parent().show();
      $("#report_type_w").prop("checked", true);
      return $("div#monthly_options").hide();
    } else {
      $("#report_type_w").prop("checked", false);
      return $("#report_type_m").prop("checked", false);
    }
  } else {
    $("#modal-body-new-report div#recurrence_fields_non_admin").show();
    if (
      $("#modal-body-new-report #report_recurrence").is(":checked") === true
    ) {
      $("#report_type_w").parent().show();
      $("#report_type_w").prop("checked", true);
      return $("div#monthly_options").hide();
    } else {
      $("#report_type_w").prop("checked", false);
      return $("#report_type_m").prop("checked", false);
    }
  }
};

hide_preferences = function (mode) {
  if ($("#modal-body-"+mode+"-report #report_report_type").val() != "5") {
    $("#modal-body-"+mode+"-report div#diagnostic_preference_options").hide();
   }
  else if($("#modal-body-"+mode+"-report #report_report_type").val() === "5") {
    $("#modal-body-"+mode+"-report div#diagnostic_preference_options").show();
   }
  if ($("#modal-body-"+mode+"-report #report_report_type").val() != "4") {
    $("#modal-body-"+mode+"-report div#recorder_preference_options").hide();
   }
  else if($("#modal-body-"+mode+"-report #report_report_type").val() === "4") {
    reload_recorder_list_init();
    $("#modal-body-"+mode+"-report div#recorder_preference_options").show();
    $("#recorder_preference_options input:checkbox[name='report[preference][]']").trigger("change");
   }
};

reset_monthly_options = function () {
  $("#modal-body-new-report #report_week_priority").attr("disabled", true);
  $("#modal-body-new-report #report_week_day").attr("disabled", true);
  $("#modal-body-new-report #report_monthly_options").prop("checked", false);
  $("#modal-body-new-report #report_week_priority").val("0");
  return $("#modal-body-new-report #report_week_day").val("0");
};

report_export_edit_init = function () {
  hide_preferences('edit');
};

report_export_new_init = function () {
  var endDate, export_time;
  $("#new-form-submit-report").attr("disabled", false);
  $('input[name="report[recurrence_pattern]"]').attr("checked", false);
  $("#report_occurrences").val("");
  $("#modal-body-new-report .recurrence_fields").hide();
  $("#modal-body-new-report #report_recurrence")
    .off("change")
    .on("change", function () {
      toggle_recurrence_fields($(this).prop("checked"));
      toggle_monthly_option_fields();
      hide_monthly_options();
      return hide_weekly_recurrence();
    });
  endDate = new Date($("#report_datetimepicker").attr("data-range-end"));
  export_time = $("#report_export_at").val();
  $("#report_datetimepicker").datetimepicker({
    format: "YYYY-MM-DD HH:mm:ss",
    ignoreReadonly: true,
    maxDate: endDate,
    minDate: export_time,
  });
  $("#modal-body-new-report #report_report_type")
    .off("change")
    .on("change", function () {
      hide_weekly_recurrence();
      hide_preferences('new');
      reset_monthly_options();
      if ($("#report_report_type").val() === "1") {
        return $("#report_type_hint").html(
          '<font size="1"><i>' + I18n.t("system_stats_hint") + "</i></font>"
        );
      } else if ($("#report_report_type").val() === "2") {
        return $("#report_type_hint").html(
          '<font size="1"><i>' + I18n.t("app_usage_hint") + "</i></font>"
        );
      } else if ($("#report_report_type").val() === "3") {
        if ($("#sales_region").val() === "US") {
          return $("#report_type_hint").html(
            '<font size="1"><i>' + I18n.t("billing_details_us") + "</i></font>"
          );
        } else if ($("#sales_region").val() === "EU") {
          return $("#report_type_hint").html(
            '<font size="1"><i>' + I18n.t("billing_details_eu") + "</i></font>"
          );
        } else if ($("#sales_region").val() === "AU") {
          return $("#report_type_hint").html(
            '<font size="1"><i>' + I18n.t("billing_details_au") + "</i></font>"
          );
        } else {
          return $("#report_type_hint").html("");
        }
      } else if ($("#report_report_type").val() === "5") {
          return $("#report_type_hint").html(
            '<font size="1"><i>' + I18n.t("panel_diagnostics.hint_text") + "</i></font>"
          );
        } else {
          return $("#report_type_hint").html("");
        }
    });
  return avoid_multiple_submit("new_report", "new-form-submit-report");
};

function reload_recorder_list_init() {
  $("#recorder_preference_options input:checkbox[name='report[preference][]']").on("change", function (e) {
      reload_recorder_list();
  });
}

document_ready = function () {
  var crud_options;
  crud_options = {
    report: {
      new: report_export_new_init,
      edit: report_export_edit_init
    },
  };
  return init_datatable(crud_options);
};

init_datatable = function (crud_options) {
  return dt.initDatatable($("#reports-table"), {}, crud_options);
};

jQuery(function () {
  return run_ontag("reports", function () {
    reload_recorder_list_init();
    return document_ready();
  });
});
