jQuery(() =>
  run_ontag("license_keys", function () {
    $("#license_key").off('click').on('click', function() {
      let validate_form = $(ClientSideValidations.selectors.forms["#new_license_key"]).validate();
      if ($("#new_license_key").isValid(validate_form)){
        $.ajax({
          url: "/license_keys",
          type: "POST",
          data: $("#new_license_key").serialize(), 
          success(data) {
            if(data.status == "valid")
            {
              $("#license_key_result").html(I18n.t("license_key")+": "+data.response);
             }
             else{
              $("#license_key_result").html(I18n.t("error")+": "+data.response);
             }
            $("#license_key_result").show();
          }
        });
      }
    });

    $("#new_license_key").on('reset', function() {
        $("#license_key_result").html("");
        $("#license_key_result").hide();
    });
  })
);
