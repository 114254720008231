/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var camera_polling,
  document_ready,
  init_datatable,
  pjax_end,
  refresh_camera_status;

document_ready = function () {
  return $("#camera-statuses .nav-tabs a:first").tab("show");
};

camera_polling = {
  start: (function (_this) {
    return function (url) {
      if (url) {
        _this.remote_url = url;
      }
      clearTimeout(_this.interval);
      return (_this.interval = setTimeout(function () {
        return refresh_camera_status(url);
      }, 30000));
    };
  })(this),
  stop: (function (_this) {
    return function () {
      clearTimeout(_this.interval);
      return (_this.interval = 0);
    };
  })(this),
};

refresh_camera_status = (function (_this) {
  return function (remote_url) {
    localStorage.setItem("remoteURL", remote_url);
    $.pjax({
      url: remote_url,
      container: "#status_content",
    });
  };
})(this);

$(document).on(
  "shown.bs.tab",
  '#camera-statuses a[data-toggle="tab"]',
  (function (_this) {
    return function (e) {
      var remote_url;
      remote_url = $(e.currentTarget).attr("data-tab-remote");
      refresh_camera_status(remote_url);
    };
  })(this)
);

pjax_end = function (xhr, options) {
  var remote_url;
  init_datatable();
  remote_url = localStorage.getItem("remoteURL");
  if (xhr.statusText === "error") {
    camera_polling.stop();
  } else {
    camera_polling.start(remote_url);
  }
};

init_datatable = function () {
  var activeTab, cameraStatusTable;
  activeTab = $("#camera-statuses .nav li.active a").data("target");
  cameraStatusTable = $("#" + activeTab + "-table");
  return dt.initDatatable(cameraStatusTable);
};

jQuery(function () {
  return run_ontag("ultralink_camera_statuses", function () {
    document_ready();
    if ($.support.pjax) {
      $.pjax.defaults.scrollTo = false;
      $.pjax.defaults.timeout = false;
      $(document).pjax("a[data-pjax-cam-status]");
      return $(document).on("pjax:end", pjax_end);
    }
  });
});
