/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/
const initDownload = () => $('a.user-export-download').off('click').on('click', function() {
  $.fileDownload($(this).attr("href"), {
    data: {format: 'xlsx'},
    successCallback(url) {
      show_alert(I18n.t("user_download_successful"));
      return enable_refresh();
    }
    ,
    failCallback(url) {
      return show_error(I18n.t("user_download_failed"));
    }
  });
  return false;
});

var enable_refresh = function() {
  if ($("#message-area a.close").attr("data-refresh") !== "enabled") {
    $("#message-area a.close").attr("data-refresh", "enabled");
    return $("#message-area a.close").on('click', () => setTimeout(refresh, 30000));
  }
};

const filter_company_id_by_role_id = function(mode) {
  const role_group      = $("#modal-body-" + mode + "-user #user_role_id option:selected").attr("data-role-group");
  const role_level      = $("#modal-body-" + mode + "-user #user_role_id option:selected").attr("data-role-level");
  const role_value      = $("#modal-body-" + mode + "-user #user_role_id option:selected").val();
  if (role_level === 'l0') {
    if (role_group === I18n.t("branch")) { 
      $(".user_mode").hide();
      $("#user_mode").prop("checked", false);
      $('.user_mfa_authentication').show();
    }
  } else {
    $(".user_mode").show();
  }

  var is_system = $('#is_system').val();
  var auto_id = is_system === 'true' ?  "#user_company_id_autocomplete" : "#user_company_id"
  var company_select  = $("#modal-body-" + mode + "-user "+ auto_id);

  if (!role_group || (role_group === I18n.t("system"))) {
    $('#company_id, #company_name, #user_company_id_autocomplete').val('');
    return company_select.parent().hide();
  } else {
    if (is_system === 'true') {
      $("#modal-body-" + mode + "-user #user_company_id_autocomplete").attr('data-autocomplete', "/companies/role_based_companies?role_id=" + role_value);
      if (mode === "new") {
        $('#user_company_id_autocomplete').val('');
        $('div.user_company_id_autocomplete').removeClass("focused")
        return company_select.parent().show();
      } else if (mode === "edit") {
        var company_name = $('#modal-body-edit-user #company_name').val();
        if (company_name) {
          $('div.user_company_id_autocomplete').addClass("focused")
        } else {
          $('#company_id').val('');
          $('div.user_company_id_autocomplete').removeClass("focused")
        }
        $('#modal-body-edit-user #user_company_id_autocomplete').attr('value', company_name)
        $("#modal-body-edit-user #company_name").val('');
        return company_select.parent().show();
      }
    } else{
      const company_options = $("#modal-body-" + mode + "-user #user_company_id option");
      company_options.each(function() {
        if ($(this).val() === "") {
          if (mode === "new") {
            return $(this).attr("selected", true);
          }
        } else if (($(this).attr("data-company-type") === role_group && role_group === I18n.t("distributor")) && role_level === "l4" ) {
          if($(this).attr("data-company-region") === "EU" && $(this).attr("data-root-company") === "true") {
            $(this).show().prop("disabled", false);
          }
          else{
            $(this).hide().attr("disabled", true);
          }
        }
        else if ( role_level === 'l3' && role_group === I18n.t("branch") ) {
          if($(this).attr("data-company-type") === I18n.t("branch") && $(this).attr("data-root-company") === "true") {
            $(this).show().prop("disabled", false);
          }else {
            $(this).hide().prop("disabled", true);
          }
        }
        else if (($(this).attr("data-company-type") === I18n.t("regional_distributor") ) && role_level === "l4" && role_group === I18n.t("distributor") ) {
          return $(this).show().prop("disabled", false);
        }
        else if ($(this).attr("data-company-type") === role_group) {
          if (mode === "new") {
            $(this).prop("selected", false);
          }
          return $(this).show().prop("disabled", false);
        } else {
          $(this).prop("selected", false);
          return $(this).hide().attr("disabled", true);
        }
      });
      company_select.parent().show();
      return company_select.enableClientSideValidations().trigger('change');
    }
  }
};

const autocomplete_show_error = function(mode) {
  const form_selector = mode === 'new' ? '#new_user' : '[id^="edit_user_"]'
  const submit_user = $(form_selector);
  submit_user.off('submit').on('submit', function(e) {
    const user_role   = $("#modal-body-" + mode + "-user #user_role_id option:selected").attr("data-role-group");
    const company_val = $("#modal-body-" + mode + "-user #user_company_id_autocomplete");
    remove_company_error()
    if ((user_role !== I18n.t("system")) && company_val && company_val.val() === "") {
      display_company_error();
      return false
    } else {
      $(form_selector).find("input, select").each(function() {
        $(this).trigger("blur");
      });
      const formValidation = $(ClientSideValidations.selectors.forms[form_selector]).validate();
      return ($(form_selector).isValid(formValidation))
    }
  });
};

var display_company_error = function () {
  $("div.user_company_id_autocomplete").addClass(
    "has-error"
  );
  return $(
    "input#user_company_id_autocomplete"
  ).after(
    "<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>"
  );
};

var detect_backspace = () =>
  $("body").on(
    "keydown",
    "#user_company_id_autocomplete",
    function (e) {
      if (e.keyCode === 8 || e.keyCode === 46) {
        return $('#company_id').val('');
      }
    }
  );

var remove_company_error = function () {
  $("input#user_company_id_autocomplete")
    .next("span.help-block")
    .remove();
  return $(
    "div.user_company_id_autocomplete"
  ).removeClass("has-error");
};

const toggle_password_fields = function(checked, action) {
  if (checked) {
    $(`#${action}_user_password`).parent(".form-group").hide();
    $(`#${action}_user_password_confirmation`).parent(".form-group").hide();
    $('label[for="new_user_password"]').hide();
    return $('label[for="new_user_password_confirmation"]').hide();
  } else {
    $(`#${action}_user_password`).val("").parent(".form-group").show();
    $(`#${action}_user_password_confirmation`).val("").parent(".form-group").show();
    $('label[for="new_user_password"]').show();
    return $('label[for="new_user_password_confirmation"]').show();
  }
};

const toggle_mfa_authentication_field = function(checked) {
  if (checked) {
    return $('.user_mfa_authentication').hide();
  } else {
    return $('.user_mfa_authentication').show();
  }
};

const user_access_config_new_init = () => country_select_init("user_access_config", "#user_access_config_country_code");

const user_edit_init = function() {
  $("#modal-body-edit-user #user_role_id").off('change').on('change', () => filter_company_id_by_role_id("edit"));
  $("#modal-body-edit-user #user_role_id").trigger('change');
  $("#user_invite_join_system, #user_send_reset_password").off("change").on('change', function() {
    return toggle_password_fields($(this).prop("checked"), "edit");
  });
  $('#user_mode').off("change").on('change', function() {
    return toggle_mfa_authentication_field($(this).prop("checked"));
  });
  $('#user_company_id_autocomplete').on('railsAutocomplete.select', function(event, data) {
    $('#company_id').val(data.item["data-company-id"]);
  });
  if ($('#is_system').val() === 'true') {
    detect_backspace();
    autocomplete_show_error("edit")
  }
  return toggle_mfa_authentication_field($('#user_mode').prop("checked"));
};

const user_new_init = function() {
  $("#modal-body-new-user #user_role_id").off('change').on('change', () => filter_company_id_by_role_id("new"));
  $("#modal-body-new-user #user_role_id").trigger('change');
  $("#user_invite_join_system").off("change").on('change', function() {
    return toggle_password_fields($(this).prop('checked'), "new");
  });
  $('#user_mode').off("change").on('change', function() {
    return toggle_mfa_authentication_field($(this).prop("checked"));
  });
  $('#user_company_id_autocomplete').on('railsAutocomplete.select', function(event, data) {
    $('#company_id').val(data.item["data-company-id"]);
  });
  if ($('#is_system').val() === 'true') {
    detect_backspace();
    autocomplete_show_error("new")
  }
  return toggle_mfa_authentication_field($('#user_mode').prop("checked"));
};

const render_user_access_rule_state = function(mode) {
  const modal = `#modal-body-${mode}-user-access-rule`;
  const objtype = $(`${modal} #user_access_rule_objtype`);
  const sid = $(`${modal} .sid_field`);
  const vffn = $(`${modal} .vfnn_field`);

  switch (parseInt(objtype.val())) {
    case 0:
      sid.show();
      vffn.hide();
      sid.prop("disabled", false);
      vffn.attr("disabled", "disabled");
      return sid.enableClientSideValidations();
    case 1:
      vffn.show();
      sid.hide();
      vffn.prop("disabled", false);
      sid.attr("disabled", "disabled");
      return vffn.enableClientSideValidations();
    default:
      sid.hide();
      vffn.hide();
      sid.attr("disabled", "disabled");
      return sid.attr("disabled", "disabled");
  }
};

const user_access_rule_handler = function(mode) {
  const modal = `#modal-body-${mode}-user-access-rule`;
  const objtype = $(`${modal} #user_access_rule_objtype`);

  return objtype.on('change', () => render_user_access_rule_state(mode));
};

const user_access_rule_new_init = function() {
  render_user_access_rule_state('new');
  return user_access_rule_handler('new');
};

const user_access_rule_edit_init = function() {
  render_user_access_rule_state('edit');
  return user_access_rule_handler('new');
};

const document_ready = function() {
  const crud_options = {
    'user': {
      new:  user_new_init,
      edit: user_edit_init
    },
    'user-access-rule': {
      new:  user_access_rule_new_init,
      edit: user_access_rule_edit_init
    },
    'user-access-config': {
      new: user_access_config_new_init
    }
  };
  init_datatable(crud_options);
  init_search();
  if ($("#users-table").length > 0) {
    return $.ajax({
      url:  '/users/user_export_fail_alert',
      type: 'GET',
      "success"(data, status, xhr) {
        if (!sessionExpired(xhr)) {
          if (data["result"] !== "") {
            return show_error(data["result"]);
          }
        }
      }    
    });
  }
};

var refresh = () => $.get('/users/download_user_export.json', function(obj, status, xhr) {
  if (sessionExpired(xhr)) { return; }
  const {
    download_path
  } = obj;
  if (download_path !== undefined) {
    show_alert('<a class="user-export-download" href="' +
               download_path +
               '">' + I18n.t("user_export_completed") +
               '</a>');
    initDownload();
    return;
  }

  if (download_path === undefined) {
    return setTimeout(refresh, 30000);
  }
});

var init_datatable = crud_options => dt.initDatatable($('#users-table'), {}, crud_options);

var init_search = () => $('.searchtoggle.users').on('on_search_toggled', function() {
  init_advsearch('/users/advfind');
  create_advfind_buttons('user');
});


jQuery(function() {
run_ontag('users', function() {
  document_ready();
  if ($("#created").length) {
    setTimeout(refresh, 30000);
  }
  $(document).on('pjax:end', document_ready);
  if ($.support.pjax) {
    $.pjax.defaults.scrollTo = false;
    $.pjax.defaults.timeout = false;
    return $(document).pjax('.pagination a', '[data-pjax-container]');
  }
  });
});
