/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

var document_ready,
  enable_refresh,
  initDownload,
  init_datatable,
  init_search,
  refresh;

initDownload = function () {
  return $("a.export-download")
    .off("click")
    .on("click", function () {
      $.fileDownload($(this).attr("href"), {
        data: {
          format: "xlsx",
        },
        successCallback: function (url) {
          show_alert(I18n.t("download_successful"));
          return enable_refresh();
        },
        failCallback: function (url) {
          return show_error(I18n.t("download_failed"));
        },
      });
      return false;
    });
};

refresh = function () {
  var loadtime;
  if (document.getElementById("notif") !== null) {
    loadtime = parseInt($("#notif").attr("data-loadtime"));
    return $.get("/ultralink_events/last.json", function (obj, status, xhr) {
      var download_path, event, latest, loadDate;
      if (sessionExpired(xhr)) {
        return;
      }
      download_path = obj.download_path;
      if (download_path !== void 0) {
        show_alert(
          '<a class="export-download" href="' +
            download_path +
            '">' +
            I18n.t("export_completed") +
            "</a>"
        );
        enable_refresh();
        initDownload();
        return;
      }
      event = obj.ultralink_event;
      if (event !== void 0) {
        latest = new Date(event.uatime);
        loadDate = new Date(loadtime);
        if (latest > loadDate) {
          $("div#message-area div:first-child").toggleClass("alert-info", true);
          show_alert(
            '<a href="/ultralink_events">' +
              I18n.t("new_events_available") +
              "</a>"
          );
          enable_refresh();
          return;
        }
      }
      return setTimeout(refresh, 30000);
    });
  }
};

enable_refresh = function () {
  if ($("#message-area a.close").attr("data-refresh") !== "enabled") {
    $("#message-area a.close").attr("data-refresh", "enabled");
    return $("#message-area a.close").on("click", function () {
      return setTimeout(refresh, 30000);
    });
  }
};

document_ready = function () {
  init_datatable();
  init_search();
  return $(".btn-play-ultralink-event")
    .off("click")
    .on("click", function (e) {
      var url;
      e.preventDefault();
      url = $(this).attr("url");
      $("#videoclip-dialog").attr("url", url);
      $("#modal-body-videoclip")
        .html('<div class="spinner"></div>')
        .load(url, function (a, b, c) {
          if (!sessionExpired(c)) {
            $(".view-dialog").modal({
              dynamic: true,
            });
            $("#videoclip-dialog").trigger("shown");
          }
          return end;
        });
      $("#videoclip-dialog")
        .off("shown")
        .on("shown", function (e) {
          $("#panel_user").trigger("focus");
          return $("#btn-panel-login")
            .off("click")
            .on("click", function (e) {
              e.preventDefault();
              if (
                $("#panel_user").val().length > 0 &&
                $("#panel_passwd").val().length > 0
              ) {
                url =
                  $("#videoclip-dialog").attr("url") +
                  "?lgname=" +
                  $("#panel_user").val() +
                  "&lgpin=" +
                  $("#panel_passwd").val();
                return $("#modal-body-videoclip")
                  .html('<div class="spinner"></div>')
                  .load(url, function (a, b, c) {
                    if (!sessionExpired(c)) {
                      $(".view-dialog").modal({
                        dynamic: true,
                      });
                      $("#videoclip-dialog").trigger("shown");
                    }
                    return end;
                  });
              }
            });
        });
      $("#videoclip-dialog").modal("show");
      return false;
    });
};

init_datatable = function () {
  var options;
  dt.fnDrawChild = function (idx, cell, cellData, api, rowIdx) {
    var cell_style, color;
    cell_style = "";
    if (cell === "Status") {
      color =
        cellData === "Acked"
          ? "#009900"
          : cellData === "Auto-Acked"
          ? "orange"
          : "#FF0000";
      cell_style = 'style="color:' + color + ';"';
    }
    return (
      '<tr data-dtr-index="' +
      idx.column +
      '">' +
      '<td scope="row" class="dtr-title">' +
      cell.trim() +
      "</td> " +
      '<td class="dtr-data" ' +
      cell_style +
      "> : " +
      cellData +
      "</td>" +
      "</tr>"
    );
  };
  options = {
    fnRowCallback: (function (_this) {
      return function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        var headers, statusIdx, statusText;
        headers = $("#ulink-event-table thead tr th");
        statusIdx = -1;
        statusText = I18n.t("status");
        $.each(headers, function (i, v) {
          if (v.textContent.trim() === statusText) {
            return (statusIdx = i);
          }
        });
        nRow.className = nRow.className + " " + aData[aData.length - 1];
        if (statusIdx >= 0) {
          return $(nRow)
            .children()
            .each(function (index, td) {
              var color;
              if (index === statusIdx) {
                color =
                  td.innerText === "Acked"
                    ? "#009900"
                    : td.innerText === "Auto-Acked"
                    ? "orange"
                    : "#FF0000";
                return $(td).css("color", color);
              }
            });
        }
      };
    })(this),
  };
  return dt.initDatatable($("#ulink-event-table"), options);
};

init_search = function () {
  return $(".searchtoggle.ultralink_events").on(
    "on_search_toggled",
    function () {
      init_advsearch("/ultralink_events/advfind");
      create_advfind_buttons("ultralink_event");
    }
  );
};

jQuery(function () {
  return run_ontag("ultralink_events", function () {
    document_ready();
    if ($("#created").length) {
      setTimeout(refresh, 30000);
    }
    $(document).on("pjax:end", document_ready);
    if ($.support.pjax) {
      $.pjax.defaults.scrollTo = false;
      $.pjax.defaults.timeout = false;
      return $(document).pjax(".pagination a", "[data-pjax-container]");
    }
  });
});
