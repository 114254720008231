/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var document_ready, init_datatable, init_tree, root;

init_tree = function () {
  var $nodes, $tree, $tree_data, expand_option;
  $tree = $("#ultrawan-tree");
  if (!($tree_data = $tree.data("source"))) {
    return;
  }
  if ($tree_data !== void 0) {
    $tree
      .treeview({
        data: $tree_data,
      })
      .treeview("collapseAll");
    $nodes = $tree.treeview("getNodes");
  }
  expand_option = {};
  $(".tree_control")
    .off("click")
    .on("click", function () {
      var $elem, $icon;
      $elem = $(this);
      $icon = $elem.find("i");
      if ($(this).hasClass("expand_tree")) {
        $tree.treeview("expandAll", expand_option);
        $elem.removeClass("expand_tree").addClass("collapse_tree");
        return $icon
          .removeClass("glyphicon-chevron-up")
          .addClass("glyphicon-chevron-down");
      } else {
        $tree.treeview("collapseAll", expand_option);
        $elem.removeClass("collapse_tree").addClass("expand_tree");
        return $icon
          .removeClass("glyphicon-chevron-down")
          .addClass("glyphicon-chevron-up");
      }
    });
  $("#tree li.parent-node")
    .off("click")
    .on("click", function (e) {
      var node_id;
      node_id = $(this).data("nodeid").toString();
      $tree.treeview("toggleNodeExpanded", [getNode(node_id), expand_option]);
    });
  $("#tree li.parent-node .expand-icon")
    .off("click")
    .on("click", function (e) {
      e.stopPropagation();
      $(this).parent().trigger("click");
    });
  $("#tree li:not(.parent-node)")
    .off("click")
    .on("click", function (e) {
      var config_path;
      config_path = $(this).find("a:first").attr("href");
      if (config_path !== void 0) {
        $("body").addClass("spinner-overlay");
        $.pjax({
          url: config_path,
          container: "[data-pjax-container]",
          push: true,
        });
      }
    });
  return $("#tree li:not(.parent-node) a")
    .off("click")
    .on("click", function (e) {
      e.preventDefault();
    });
};

//root = typeof exports !== "undefined" && exports !== null ? exports : this;
root = window;

root.select_gateway_node = function () {
  var $tree,
    $tree_data,
    gateway_id,
    gateway_node,
    match,
    node_id,
    parent_node,
    parent_node_id,
    ultrawan_id;
  $tree = $("#ultrawan-tree");
  if ($tree.length !== 1) {
    return;
  }
  $tree_data = $tree.data("source");
  if ($tree_data && $tree_data.length > 1) {
    $(".tree_control").trigger("click");
    match = window.location.pathname.match(/\/vpn_config\/\d+/);
    if (match && match[0]) {
      gateway_id = match[0].split("/").pop();
      gateway_node = $tree.find("li[data-gateway_id='" + gateway_id + "']");
      ultrawan_id = gateway_node.data("ultrawan_id");
      parent_node = $tree.find("#" + ultrawan_id);
      if (parent_node.data("nodeid")) {
        parent_node_id = parent_node.data("nodeid").toString();
        $tree.treeview("expandNode", [getNode(parent_node_id), {}]);
      }
      if (gateway_node.data("nodeid")) {
        node_id = gateway_node.data("nodeid").toString();
        $tree.treeview("selectNode", [getNode(node_id), {}]);
      }
    }
  }
};

root.getNode = function (nodeid) {
  var $nodes, node;
  $nodes = $("#ultrawan-tree").treeview("getNodes");
  return (node = $nodes.filter(
    (function (_this) {
      return function (node) {
        return node.nodeId === nodeid;
      };
    })(this)
  ));
};

init_datatable = function (crud_options) {
  var options;
  options = {
    lengthChange: false,
    rowsGroup: ["ultrawan_subnet:name"],
  };
  return dt.initDatatable($("#ultrawan-nodes-table"), options, crud_options);
};

document_ready = function () {
  init_tree();
  init_object_crud({});
  $("#ultrawan_ultrawan_id").on("change", function (e) {
    var subnet;
    subnet = $("#ultrawan_ultrawan_id option:selected").data("subnet");
    return $("#ultrawan_subnet").val(subnet);
  });
  init_datatable();
  $("body").removeClass("spinner-overlay");
};

jQuery(function () {
  return run_ontag("ultrawans", function () {
    document_ready();
    if ($.support.pjax) {
      $(document).pjax("#tree a", "[data-pjax-container]");
      $(document).on("pjax:complete", document_ready);
      $.pjax.defaults.scrollTo = false;
      return ($.pjax.defaults.timeout = false);
    }
  });
});
