/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var document_ready;

document_ready = function () {
  create_advfind_buttons("service_grade");
  return init_object_crud({
    "service-grade": {},
  });
};

jQuery(function () {
  return run_ontag("service_grades", function () {
    document_ready();
    if ($.support.pjax) {
      $.pjax.defaults.scrollTo = 0;
      $.pjax.defaults.timeout = false;
      $.pjax.defaults.push = false;
      $(document).pjax(".pagination a", "[data-pjax-container]");
      return $(document).on("pjax:end", document_ready);
    }
  });
});
