/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var document_ready;

document_ready = function () {
  return $("#remote_console").on('load', function () {
    var cproxy, ct, i, img, j, obj, ref, results, sep, url;
    ct = $("#remote_console").contents().find("#ct");
    if (ct.length > 0) {
      obj = JSON.parse($("#remote_console")[0].contentWindow.getCameras());
      results = [];
      for (i = j = 0, ref = obj.cameras.length; j <= ref; i = j += 1) {
        if (obj.cameras[i].MAC == null) {
          break;
        }
        cproxy = "data-cproxy-" + obj.cameras[i].MAC;
        url = $("#remote_console").attr(cproxy);
        if (url !== void 0 && url.length > 0) {
          sep = url.indexOf("?") > 0 ? "&" : "?";
          url += sep + "MAC=" + obj.cameras[i].MAC;
          img = $("#remote_console")
            .contents()
            .find("#img" + (i + 1).toString());
          results.push(img.attr("src", url));
        } else {
          results.push(void 0);
        }
      }
      return results;
    }
  });
};

jQuery(function () {
  return run_ontag("remote_consoles", function () {
    var s;
    if (!/\d+\.\d+\.\d+\.\d+/.test(document.domain)) {
      s = document.domain.split(".");
      if (s.length > 2) {
        document.domain = s.slice(-2).join(".");
      }
    }
    return document_ready();
  });
});
