/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/
var root = window //typeof exports !== "undefined" && exports !== null ? exports : this;
root.alarm_receiver_interface_init = function (sender, action) {
  var p = "#modal-body-".concat(action, "-alarm-receiver-interface");
  var rp = $('input[name="alarm_receiver_interface[automation_option]"]', p);
  var checked = function checked() {
    return rp.filter(":checked");
  };
  var toggle = function toggle() {
    $(".automation_option", p).hide();
    if (checked().val() === "1") {
      return $(".serial_option", p).show();
    } else {
      return $(".ip_option").show();
    }
  };
  if (checked().length === 0) {
    $(rp[0]).attr("checked", true);
  }
  var automation_ops = $(
    'input[name="alarm_receiver_interface[automation_option]"]',
    p
  );
  if (automation_ops.filter(":checked").length === 0) {
    automation_ops.first().attr("checked", true);
  }
  toggle();
  return rp.off("click").on("click", toggle);
};

var client_certificate_new_init = function client_certificate_new_init() {
  return country_select_init(
    "client_certificate",
    "#client_certificate_country_code"
  );
};

var document_ready = function document_ready() {
  var crud_options = {
    "alarm-receiver-interface": {
      new: alarm_receiver_interface_init,
      edit: alarm_receiver_interface_init,
    },
    "client-certificate": {
      new: client_certificate_new_init,
    },
  };
  init_datatable(crud_options);
  return init_search();
};

var init_datatable = function init_datatable(crud_options) {
  options = {
    fnRowCallback: function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
      return (nRow.style.color = aData[aData.length - 1]);
    },
  };  
  return dt.initDatatable(
    $("#alarm-receiver-interfaces-table"),
    options,
    crud_options
  );
};

var init_search = function init_search() {
  return $(".searchtoggle.alarm_receiver_interfaces").on(
    "on_search_toggled",
    function () {
      init_advsearch("/alarm_receiver_interfaces/advfind");
      create_advfind_buttons("alarm_receiver_interface");
    }
  );
};

jQuery(function () {
  return run_ontag("alarm_receiver_interfaces", function () {
    document_ready();
    $(document).on("pjax:end", document_ready);
    if ($.support.pjax) {
      $.pjax.defaults.scrollTo = false;
      $.pjax.defaults.timeout = false;
      return $(document).pjax(".pagination a", "[data-pjax-container]");
    }
  });
});
