/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
jQuery(function () {
  $("#show_privacy_notice").on("click", function () {
    if (
      $("#sales_region").val() === "EU" &&
      $("#emea_privacy_notice").val() === "EU_PRIVACY_POLICY"
    ) {
      $("#emea_privacy_policy").removeClass("hidden");
      $("#accept_terms_and_policy").removeClass("hidden");
      $("#privacy_notice").addClass("hidden");
      $("#terms_and_conditions").addClass("hidden");
      $("#emea_privacy_notice").val("");
      $("#show_privacy_notice").addClass("hidden");
    } else if ($("#sales_region").val() === "EU") {
      $("#accept_terms_and_policy").addClass("hidden");
      $("#privacy_notice").removeClass("hidden");
      $("#emea_privacy_notice").val("EU_PRIVACY_POLICY");
      $("#terms_and_conditions").addClass("hidden");
    } else {
      $("#show_privacy_notice").addClass("hidden");
      $("#accept_terms_and_policy").removeClass("hidden");
      $("#privacy_notice").removeClass("hidden");
      $("#terms_and_conditions").addClass("hidden");
      $("#emea_privacy_notice").val("");
    };
    if ($("#sales_region_type").val() === "EU") {
      $("#emea_terms_and_conditions").removeClass("hidden");
      return $("#accept_terms_and_conditions").addClass("hidden");
    } else {
      $("#emea_terms_and_conditions").addClass("hidden");
      return $("#accept_terms_and_conditions").removeClass("hidden");
    }
  });
  return $("#emea_terms_and_conditions").on("click", function () {
    $("#accept_terms_and_conditions").removeClass("hidden");
    $("#terms_and_conditions").addClass("hidden");
    $("#emea_terms_and_conditions").addClass("hidden");
    return $("#emea_terms_conditions").removeClass("hidden");
  });
});
