/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var datetostr,
  day_ticks,
  document_ready,
  hour_ticks,
  initCharts,
  prepareData,
  prepareMultipleData,
  prepareRawData,
  shortdate,
  signal_ticks;

document_ready = function () {
  var signals, attr_obj, attr_val, signals_array = [], legend_label, legends_array = [];
  if ($("#signalStrength").length > 0) {
    for(i=0; i < $("#signalStrength")[0].attributes.length; ++i)
    {
      attr_obj = $("#signalStrength")[0].attributes[i];
      if(attr_obj.name.match("value_"))
      {
        attr_val = $("#signalStrength").attr(attr_obj.name);
        if (attr_val && attr_val != '[]' )
        {
          signals_array.push(JSON.parse(attr_val));
          legend_label = $("#signalStrength").attr("label_"+attr_obj.name.match(/\d+/)[0]);
          legends_array.push(legend_label);
        }
      }
    }
    return initCharts(
      "#signalStrength",
      signals_array,
      legends_array,
      $("#signalStrength").attr("start_day"),
      $("#signalStrength").attr("end_day")
    );
  }
};

signal_ticks = function () {
  var i, j, ticks;
  ticks = [];
  for (i = j = -120; j <= -40; i = j += 10) {
    ticks.push([i, i + "dBm"]);
  }
  return ticks;
};

hour_ticks = [
  [0, "00:00"],
  [1 * 60, "01:00"],
  [2 * 60, "02:00"],
  [3 * 60, "03:00"],
  [4 * 60, "04:00"],
  [5 * 60, "05:00"],
  [6 * 60, "06:00"],
  [7 * 60, "07:00"],
  [8 * 60, "08:00"],
  [9 * 60, "09:00"],
  [10 * 60, "10:00"],
  [11 * 60, "11:00"],
  [12 * 60, "12:00"],
  [13 * 60, "13:00"],
  [14 * 60, "14:00"],
  [15 * 60, "15:00"],
  [16 * 60, "16:00"],
  [17 * 60, "17:00"],
  [18 * 60, "18:00"],
  [19 * 60, "19:00"],
  [20 * 60, "20:00"],
  [21 * 60, "21:00"],
  [22 * 60, "22:00"],
  [23 * 60, "23:00"],
  [24 * 60, "24:00"],
];

datetostr = function (num) {
  if (num > 9) {
    return num.toString();
  } else {
    return "0" + num.toString();
  }
};

shortdate = function (date) {
  return datetostr(date.getDate()) + "/" + datetostr(date.getMonth() + 1);
};

day_ticks = function (start_date, end_date) {
  var days, end_day, i, start_day, ticks;
  start_day = todate(start_date);
  end_day = todate(end_date);
  days = Math.floor((Date.parse(end_date) - Date.parse(start_date)) / 86400000);
  ticks = [];
  i = 0;
  while (start_day <= end_day) {
    ticks.push([i * (12 * 60), shortdate(start_day)]);
    i++;
    ticks.push([i * (12 * 60), ""]);
    i++;
    start_day = new Date(start_day.getTime() + 86400000);
  }
  ticks.push([i * (12 * 60), shortdate(start_day)]);
  return ticks;
};

prepareData = function (signals, start_day, end_day) {
  return [
    {
      label: "Signal Strength",
      data: prepareRawData(signals, start_day, end_day),
    },
  ];
};

prepareRawData = function (signals, start_day, end_day) {
  var j, len, s, start_, stat, x;
  stat = [];
  start_ = todate(start_day);
  for (j = 0, len = signals.length; j < len; j++) {
    s = signals[j];
    x = (todate(s.time) - start_) / (1000 * 60);
    stat.push([x, parseInt(s.value)]);
  }
  return stat;
};

prepareMultipleData = function (multi_signals, legends_array, start_day, end_day) {
  var j, len, signals, stat, yaxis;
  stat = [];
  yaxis = 1;
  for (j = 0, len = multi_signals.length; j < len; j++) {
    signals = multi_signals[j];
    stat.push({
      label: legends_array[j],
      data: prepareRawData(signals, start_day, end_day),
      yaxis: 1,
    });
    yaxis += 1;
  }
  return stat;
};

initCharts = function (elem, signals_array, legends_array, start_day, end_day) {
  var x_ticks, y_ticks;
  if (!$(elem)) {
    return;
  }
  y_ticks = signal_ticks();
  x_ticks = start_day === end_day ? hour_ticks : day_ticks(start_day, end_day);
  $.plot(
    $(elem),
    signals_array.length == 1
      ? prepareData(signals_array[0], start_day, end_day)
      : prepareMultipleData(signals_array, legends_array, start_day, end_day),
    {
      colors: ["#27e524", "#0086C2", "#5674fb", "#f90a06"],
      xaxis: {
        show: true,
        position: "bottom",
        font: {
          color: "#999",
        },
        color: "rgba(255, 255, 255, 0.10)",
        ticks: x_ticks,
        min: 0,
        max: x_ticks[x_ticks.length - 1][0],
      },
      yaxes: [
        {
          show: true,
          position: "left",
          font: {
            color: "#999",
          },
          color: "rgba(255, 255, 255, 0.10)",
          ticks: y_ticks,
          min: y_ticks[0][0] - 10,
          max: y_ticks[y_ticks.length - 1][0],
        },
        {
          show: false,
        },
      ],
      series: {
        lines: {
          show: true,
        },
        points: {
          radius: 2,
          show: true,
        },
      },
      legend: {
        show: true,
        backgroundOpacity: 0,
      },
      grid: {
        borderWidth: 1,
        backgroundColor: {
          colors: ["#222", "#666"],
        },
      },
    }
  );
  $(".legendLabel").css("color", "#fff");
};

jQuery(function () {
  return run_ontag("ultralinks", function () {
    document_ready();
    window.onresize = function () {
      return document_ready();
    };
    $("#menu_toggle").on("click", function () {
      return window.setTimeout(function () {
        return document_ready();
      }, 0);
    });
    if ($.support.pjax) {
      return $(document).on("pjax:end", document_ready);
    }
  });
});
