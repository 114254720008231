// TinyMCE Core
import tinymce from "tinymce";
import "tinymce/themes/silver/theme";

// //TinyMCE Plugins
import "tinymce/icons/default/icons";
import "tinymce/plugins/advlist/plugin";
import "tinymce/plugins/code/plugin";
import "tinymce/plugins/codesample/plugin";
// import "tinymce/plugins/uploadimage/plugin";
// import "tinymce/plugins/autoresize/plugin";
// import "tinymce/plugins/code/plugin";
// import "tinymce/plugins/codesample/plugin";
import "tinymce/plugins/colorpicker/plugin";
import "tinymce/plugins/hr/plugin";
import "tinymce/plugins/image/plugin";
// import "tinymce/plugins/uploadimage/plugin";
import "tinymce/plugins/lists/plugin";
import "tinymce/plugins/media/plugin";
import "tinymce/plugins/link/plugin";
import "tinymce/plugins/preview/plugin";
import "tinymce/plugins/searchreplace/plugin";
import "tinymce/plugins/table/plugin";
import "tinymce/plugins/textcolor/plugin";
import "tinymce/plugins/toc/plugin";
import "tinymce/plugins/visualblocks/plugin";
// import "tinymce/icons/default";

// //TinyMCE skin
// require.context(
//   "file-loader?name=[path][name].[ext]&context=node_modules/tinymce!tinymce/skins/",
//   true,
//   /.*/
// );
