/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
jQuery(function () {
  return run_ontag("oh2000_panels", function () {
    if ($.support.pjax) {
      $.pjax.defaults.scrollTo = 0;
      $.pjax.defaults.timeout = false;
      $.pjax.defaults.push = false;
      return $(document).pjax(".pagination a", "[data-pjax-container]");
    }
  });
});
