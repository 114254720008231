/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var document_ready,
  get_ultralinks_affected,
  init_datatable,
  ultralink_software_version_edit_init;

get_ultralinks_affected = function () {
  var agency_id,
    first_acctno,
    hwver,
    last_acctno,
    swver,
    ultralink_software_version_id;
  ultralink_software_version_id = $(
    "#download_job_batch_ultralink_software_version_id"
  ).val();
  agency_id = $("#download_job_batch_agency_id").val();
  hwver = $("#download_job_batch_hwver").val();
  swver = $("#download_job_batch_swver").val();
  first_acctno = $("#download_job_batch_first_acctno").val();
  last_acctno = $("#download_job_batch_last_acctno").val();
  return $.ajax({
    type: "GET",
    dataType: "JSON",
    url:
      "/ultralink_software_versions/" +
      ultralink_software_version_id +
      "/ultralinks_affected",
    data: {
      download_job_batch: {
        agency_id: agency_id,
        hwver: hwver,
        swver: swver,
        first_acctno: first_acctno,
        last_acctno: last_acctno,
      },
    },
    asnyc: false,
    success: function (data) {
      $(".ultralinks_affected").html(data["message"]);
      if (data["last_acctno"]) {
        $("div.download_job_batch_last_acctno").addClass("error");
        return $("div.download_job_batch_last_acctno")
          .find(".controls")
          .append(data["last_acctno"]);
      }
    },
  });
};

ultralink_software_version_edit_init = function () {
  var agency_options,
    agency_select,
    batch_time,
    modal,
    swver_options,
    swver_select;
  batch_time = $("#download_job_batch_time").val();
  $("#datetimepicker").datetimepicker({
    format: "YYYY-MM-DD HH:mm:ss",
    ignoreReadonly: true,
    minDate: batch_time,
  });
  $("#clear_dj_input_fields").hide();
  $("div#modal-body-edit-ultralink-software-version input")
    .unbind("change")
    .change(function () {
      $("#download_job_batch_batch_upload").after(" ");
      if (this.id === "download_job_batch_batch_upload") {
        if (
          !(
            $("#download_job_batch_batch_upload")
              .val()
              .split(".")
              .pop()
              .toLowerCase() === "txt"
          )
        ) {
          $("#clear_dj_input_fields").hide();
          $(".file_size_check").hide();
          $("#download_job_batch_batch_upload").val(null);
          if (!($(".file_type_check").length > 0)) {
            $("#download_job_batch_batch_upload").after(
              '<span class="has-error"><span class="help-block file_type_check">' +
                I18n.t("invalid_avatar_type", {
                  type: ".txt",
                }) +
                "</span></span>"
            );
            return $(".not_blank").hide();
          } else {
            $(".file_type_check").show();
            return $(".not_blank").hide();
          }
        } else {
          $(".file_type_check").hide();
          $(".file_size_check").hide();
          $(".not_blank").hide();
          $("#download_job_batch_batch_upload").after(" ");
          if (
            $("#download_job_batch_batch_upload").get(0).files[0].size > 300000
          ) {
            $("#download_job_batch_batch_upload").after(
              '<span class="has-error"><span class="help-block file_size_check">' +
                I18n.t("company_branding_file_size_error", {
                  size: "300",
                }) +
                " MB . </span></span>"
            );
            $("#clear_dj_input_fields").hide();
            remove_disabled_batch_inputs();
          }
          if (
            $("#download_job_batch_batch_upload").get(0).files.length === 1 &&
            $("#download_job_batch_batch_upload").get(0).files[0].size < 300000
          ) {
            $("#download_job_batch_batch_upload").after("");
            return add_disabled_batch_inputs();
          } else {
            $("#edit-form-submit-ultralink-software-version").attr(
              "disabled",
              true
            );
            return $("#clear_dj_input_fields").hide();
          }
        }
      } else {
        return get_ultralinks_affected();
      }
    });
  $("div#modal-body-edit-ultralink-software-version select")
    .off("change")
    .on("change", function () {
      return get_ultralinks_affected();
    });
  $(".clear-date")
    .off("click")
    .on("click", function () {
      $("#" + $(this).attr("for")).val("");
      return $("#" + $(this).attr("for"))
        .next(".bootstrap-datetimepicker-widget")
        .hide();
    });
  modal = "#modal-body-edit-ultralink-software-version";
  agency_select = $(modal + " #download_job_batch_agency_id");
  agency_options = $(modal + " #download_job_batch_agency_id option");
  swver_select = $(modal + " #download_job_batch_swver");
  swver_options = $(modal + " #download_job_batch_swver option");
  agency_select.on("change", function () {
    var agency_id, has_item;
    agency_id = agency_select.find("option:selected").attr("data-agency-id");
    has_item = false;
    swver_options.each(function () {
      if ($(this).attr("data-agency-id") === agency_id) {
        has_item = true;
        return $(this).prop("selected", false).show().prop("disabled", false);
      } else {
        if ($(this).attr("data-agency-id") !== void 0) {
          return $(this).prop("selected", false).hide().attr("disabled", true);
        }
      }
    });
    if (agency_id && has_item) {
      swver_select.attr("readonly", false);
    } else {
      swver_select.attr("readonly", true);
    }
    if (has_item) {
      return swver_select
        .find("option:not([disabled])")
        .first()
        .attr("selected", "selected");
    } else {
      return swver_options
        .first()
        .show()
        .attr("disabled", false)
        .attr("selected", "selected");
    }
  });
  return agency_select.trigger("change");
};

document_ready = function () {
  var crud_options, init_search;
  crud_options = {
    "ultralink-software-version": {
      edit: ultralink_software_version_edit_init,
    },
  };
  init_datatable(crud_options);
  init_search = function () {};
  $(".searchtoggle.ultralink_software_versions").on(
    "on_search_toggled",
    function () {
      init_advsearch("/ultralink_software_versions/advfind");
      create_advfind_buttons("ultralink_software_version");
    }
  );
  return $(".document-upload").on("shown", function (e) {
    $("button#edit-form-submit-ultralink-software-version").attr(
      "disabled",
      "disabled"
    );
    return $("body").on(
      "change",
      "#ultralink_software_version_software_version_document",
      function () {
        var file_ext, myfile, upload_file_element;
        myfile = $(this).val();
        file_ext = myfile.split(".").pop();
        file_ext = file_ext.toLowerCase();
        upload_file_element = $(
          "#ultralink_software_version_software_version_document"
        );
        upload_file_element.next("span.help-block").remove();
        if (!(file_ext === "pdf")) {
          $(upload_file_element).parent().addClass("has-error");
          $(upload_file_element).after(
            '<span class="help-block">' +
              I18n.t("invalid_file_type") +
              "</span>"
          );
          return $("button#edit-form-submit-ultralink-software-version").attr(
            "disabled",
            true
          );
        } else if (this.files[0].size > 5 * 1024 * 1024) {
          $(upload_file_element).parent().addClass("has-error");
          $(upload_file_element).after(
            '<span class="help-block">' +
              I18n.t("ultralink_file_size_error") +
              "</span>"
          );
          return $("button#edit-form-submit-ultralink-software-version").attr(
            "disabled",
            true
          );
        } else {
          $(upload_file_element).parent().removeClass("has-error");
          return $("button#edit-form-submit-ultralink-software-version").attr(
            "disabled",
            false
          );
        }
      }
    );
  });
};

var init_datatable = (crud_options) =>  
  dt.initDatatable($("#ultralink-software-versions-table"), {}, crud_options);

jQuery(function () {
  return run_ontag("ultralink_software_versions", function () {
    return document_ready();
  });
});

// $(document).on("shown.bs.modal", ".modal", function () {
//   return $("form[data-client-side-validations]").resetClientSideValidations();
// });
