/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var document_ready,
  init_datatable,
  init_search,
  pjaxAlert,
  pjax_alert_cookie,
  pjax_alert_showtimes,
  refresh;

pjax_alert_cookie = "browser-suggest-alert-displayed";

pjax_alert_showtimes = 3;

pjaxAlert = function () {
  var displayed;
  if (!$.support.pjax) {
    displayed = getCookie(pjax_alert_cookie);
    if (displayed !== "0") {
      show_alert(I18n.t("use_browser_latest_version"));
      displayed =
        displayed === "" ? pjax_alert_showtimes : parseInt(displayed, 10) - 1;
      return setCookie(pjax_alert_cookie, displayed, 10);
    }
  }
};

refresh = function () {
  var refresh_checked;
  refresh_checked = $("#download_jobs_refresh_toggle").prop("checked");
  if (document.getElementById("download-job-table") && refresh_checked) {
    if ($.support.pjax) {
      return $.pjax({
        url: window.location.href,
        container: "[data-pjax-container]",
        push: false,
      });
    } else {
      return (window.location.href = document.URL);
    }
  } else {
    return window.clearTimeout(window.timeout);
  }
};

document_ready = function () {
  const Cookies = require('./js_cookie');
  var crud_options, refresh_checked, toggle_value;
  crud_options = {
    "download-job": {},
  };
  init_datatable(crud_options);
  init_search();
  toggle_value =
    $(".dropdown-menu.dropdown-usermenu.pull-right")
      .find("a")
      .attr("href")
      .split("=")
      .pop() + "_refresh";
  refresh_checked = $("#download_jobs_refresh_toggle").prop("checked");
  Cookies.set(toggle_value, refresh_checked) || {};
  if (document.getElementById("download-job-table") && refresh_checked) {
    window.timeout = setTimeout(refresh, 60000);
  } else {
    if (!$.support.pjax) {
      pjaxAlert();
    }
  }
  return $("#download_jobs_refresh_toggle").on("change", function () {
    var toggleValue;
    refresh_checked = $("#download_jobs_refresh_toggle").prop("checked");
    Cookies.set(toggle_value, refresh_checked) || {};
    window.clearTimeout(window.timeout);
    if (refresh_checked === true) {
      window.timeout = setTimeout(refresh, 60000);
    } else {
      window.clearTimeout(window.timeout);
    }
    return (toggleValue = Cookies.get(toggle_value));
  });
};

init_datatable = function (crud_options) {
  var options;
  options = {
    fnRowCallback: function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
      return (nRow.style.color = aData[aData.length - 1]);
    },
  };
  return dt.initDatatable($("#download-jobs-table"), options, crud_options);
};

init_search = function () {
  return $(".searchtoggle.download_jobs").on("on_search_toggled", function () {
    init_advsearch("/download_jobs/advfind");
    create_advfind_buttons("download_job");
  });
};

jQuery(function () {
  return run_ontag("download_jobs", function () {
    document_ready();
    if ($.support.pjax) {
      $.pjax.defaults.scrollTo = false;
      $.pjax.defaults.timeout = false;
      $(document).pjax("a[data-pjax]");
      return $(document).on("pjax:end", document_ready);
    }
  });
});
