/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var root;

root = window //typeof exports !== "undefined" && exports !== null ? exports : this;

root.panel_action_init = function () {
  initAjaxIndicator("#content-area", "status_indicator");
  return $("form.panel-action").submit(function () {
    var commit, params;
    showAjaxIndicator("status_indicator");
    params = $(this).serializeArray();
    commit = $('input[name="commit"]', this);
    params.push({
      name: "commit",
      value: commit.val(),
    });
    $.ajax({
      type: $(this).attr("method"),
      url: $(this).attr("action"),
      data: params,
      success: function (data, status, xhr) {
        hideAjaxIndicator("status_indicator");
        if (!sessionExpired(xhr)) {
          $("#content-area").empty().append(data);
          initToolTip();
          fadeInAlert();
          return closeAlertInit();
        }
      },
      error: function (xhr, status, errors) {
        show_alert_pop(renderAjaxError(status, errors), commit);
        return hideAjaxIndicator("status_indicator");
      },
    });
    return false;
  });
};

jQuery(function () {
  return run_ontag("panels", function () {
    return $(document).ready(function () {
      $("#sites").on("click", function () {
        $("#cbp-spmenu-s2").toggleClass("cbp-spmenu-open");
        return false;
      });
      return $("body").on("click", function () {
        return $(".cbp-spmenu-open").removeClass("cbp-spmenu-open");
      });
    });
  });
});
