/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const root = window //typeof exports !== 'undefined' && exports !== null ? exports : this;
// jQuery Datatable namespace
root.dt = (function() {
  // PRIVATE VARIABLES
  let _activeTable = null;
  let _activeDatatable = null;
  const _tableCollection = {};
  let _crud_options = {};

  // PRIVATE FUNCTIONS
  const _addTableCollection = function(dataTable, crud_options) {
    _tableCollection[dataTable.context[0].sTableId.toString()] = {
      dataTable,
      crud_options
    };
  };

  // IMPORTANT: if the columndefs visibility does not match that of the
  //            header in the html, datatable will raise an error
  //            i.e., Uncaught TypeError: Cannot read property 'style' of undefined
  const _getColumnDefinitions = function() {
    if (_activeTable !== null) {
      const col_config = [];
      _activeTable.data('columndefs').map(function(col) {
        const data = {
          className: col.className ? col.className : undefined,
          targets: col.targets,
          name: col.name,
          orderable: col.orderable === undefined ? false : col.orderable,
          ordersource: col.orderable === true ? col.ordersource : null,
          visible: col.visible === undefined ? true : col.visible
        };
        return col_config.push(data);
      });
      return col_config;
    }
  };

  // user to assign the ajax.url value to the datatable
  const _getDatasource = function() {
    if (_activeTable !== null) { return _activeTable.data('source'); }
  };

  // construct sorting parameter for the datatable
  const _getDefaultSort = function() {
    if (_activeTable !== null) {
      let direction = "asc";
      const orderCol = _activeTable.data('columndefs').filter(function(col) { if (col.default === true) { return col; } });
      if (orderCol.length > 0) {
        if (orderCol[0].direction) { ({
          direction
        } = orderCol[0]); }
        return [[orderCol[0].targets, direction]];
      }
      return [[0, direction]];
    }
  };

  // pagination i18n display
  const _setLanguage = () => ({
    paginate: {
      first: I18n.t("will_paginate.first_label"),
      previous: I18n.t("will_paginate.previous_label"),
      next: I18n.t("will_paginate.next_label"),
      last: I18n.t("will_paginate.last_label")
    },

    emptyTable: I18n.t("no_table_data"),
    processing: "<div></div>",
    lengthMenu: _setLengthDom()
  });

  // datatable layout
  const _setDom = () => '<"row view-filter"<"col-sm-12"rt<"row view-pager"<"col-sm-12"<"text-center"pl>>>>>';

  // lengthMenu
  var _setLengthDom = () => '<div class="form-group" style="display:none;float:left;">' +
    I18n.t("will_paginate.display_label")+' ' +
    '<select class="form-control">' +
      '<option value="10">10</option>' +
      '<option value="20">20</option>' +
      '<option value="30">30</option>' +
      '<option value="50">50</option>' +
      '<option value="100">100</option>' +
    '</select> '+ I18n.t("will_paginate.records_label")+'    ' +
  '</div>';
  // # smart grouping
  // _setGrouping = (targets, api) ->
  //   grouped_col = targets.filter (c) -> c.grouped == true
  //   if grouped_col.length > 0
  //     # NOTE: support for multi-column grouping?
  //     grouped_index = grouped_col[0].targets
  //     rows = api.rows(page: 'current').nodes()
  //     last = null
  //     groupText = $(api.column(grouped_index, page: 'current').header()).text()
  //     api.column(grouped_index, page: 'current').data().each (group, i) =>
  //       $aRow = $(rows).eq(i)
  //       if last != group
  //         $aRow.before '<tr class="grouping">
  //                       <td class="hidden-element">
  //                       <td class="group" colspan="' +
  //                       $aRow.children().length + '">' + groupText + ': ' + group +
  //                      '</td></tr>'
  //         last = group
  //       return

  const _setPagingType = function() {
    if (jQuery(window).width() <= 464) { return "full"; }
    return "simple_numbers";
  };

  // ajax error handling
  const _handleAjaxError = function(xhr,status,error) {
    // HTTP status codes needs to be 3-digits
    if (xhr.status.toString().length !== 3) { return; }
    if (__guardMethod__([301, 302, 401], 'include', o => o.include(xhr.status))) {
      return window.location = '/login';
    } else if (xhr.status === 404) {
      return window.location = '/404';
    } else if (xhr.status === 500) {
      return window.location = '/500';
    } else {
      // should we redirect or do nothing?
      return window.location = "/";
    }
  };
      // console.log 'Unable to process request! Err#' + xhr.status + " " + xhr.responseText

  const _getStateSavePermission = function() {
    if (_activeTable === null) { return true; }
    // enable only when not in the list
    const excluded_tables = ['#companies-table', '#users-table', '#user-requests-table','#reports-table','#batch-service-requests-table','#oh2000-event-table', '#mail-masters-table'];
    return excluded_tables.indexOf(`#${_activeTable.attr('id')}`) === -1;
  };

  const _child_row_renderer = function(api, rowIdx) {
    const data = api.cells(rowIdx, ':hidden').eq(0).map(function(cell) {
      const header = $(api.column(cell.column).header());
      const idx = api.cell(cell).index();
      if (header.hasClass('control') || header.hasClass('never')) { return ''; }
      cell = header.text();
      if (cell) {
        // Use a non-public DT API method to render the data for display
        // This needs to be updated when DT adds a suitable method for
        // this type of data retrieval
        let row;
        const dtPrivate = api.settings()[0];
        const cellData  = dtPrivate.oApi._fnGetCellData(dtPrivate, idx.row, idx.column, 'display');
        // will call overriden if any, otherwise parent.
        return row = dt.fnDrawChild(idx, cell, cellData, api, rowIdx);
      }
    }).toArray().join('');
    if (data) {
      const childRow = $(`<table class="table table-condensed \
borderless table-child" data-dtr-index="` + rowIdx + '"/>')
                  .append(data);
      const table_name = "#" +  api.table(0).context[0].sTableId;
      return childRow;
    }
  };

  const _setupTableCustomisations = function(_activeTable, _activeDatatable) {
    // setup sorting icon beside header text instead of the default :after
    // ------------------------------------------------
    _activeDatatable.columns().iterator('column', function(ctx, idx) {
      const column_header = $(_activeDatatable.column(idx).header());
      if (column_header.find(".btn").length === 0) {
        return column_header.append('<span class="sort-icon"/>');
      }
    });

    // handle expand/collapse for when row has grouping
    // ------------------------------------------------
    const group_selector = 'tbody tr td:first-child:not(".child, .dtr-data, .dtr-title")';
    return $(`#${_activeTable.attr('id')}`).on('click', group_selector, function(e) {
      const cell = $(this);
      const parent_row = cell.parent('tr');
      const drow = _activeDatatable.row(parent_row);

      // only resume if table is expandable/collapsible
      const visible_cols_len = parent_row.find('td').length;
      const total_cols_len = drow.columns()[0].length;
      if (visible_cols_len === total_cols_len) { return; }

      const expanded = parent_row.hasClass('parent');

      // get sibling rows of group
      const group_cell = parent_row.find('>:first-child');
      const selector   = expanded ? ':not(".parent")' : '.parent';
      const group_text = group_cell.text().toString();
      const group_sibling_rows = parent_row.siblings(selector)
                             .find('td:hidden')
                             .filter(function() {
                               return $(this).text() === group_text;
                             }).toArray();
      // set rowspan on expand/collapse
      let colspan = cell.attr('rowspan');
      let rowspan  = parseInt(cell.attr('rowspan'));
      const no_of_subs = group_sibling_rows.length + 1;
      rowspan  = expanded ? 1 : rowspan*no_of_subs;
      if (rowspan) {
        cell.attr('rowspan', rowspan);
        if (!group_cell.is(':visible')) { return; }
        return group_sibling_rows.map(function(td) {
          $(td).trigger('click');
          // set colspan on expand/collapse
          const cell2 = $(td).next('td');
          const orig_colspan = parseInt(cell2.attr('colspan')) || 1;
          colspan = expanded ? orig_colspan + 1 : orig_colspan - 1;
          return cell2.attr('colspan', colspan);
        });
      }
    });
  };

  // PUBLIC FUNCTIONS

  // expose overridable function to allow implementors to apply specific style to child row
  // TODO: refine by limiting what can be overriden. styling only perhaps instead of full row?
  const fnDrawChild = (idx, cell, cellData, api, rowIdx) => '<tr data-dtr-index="' + idx.column + '">' +
    '<td scope="row" class="dtr-title">' + cell.trim() + '</td> ' +
    '<td class="dtr-data"> : ' + cellData + '</td>' + '</tr>';

  const initDatatable = function(table, userDTOptions, crud_options) {
    if (userDTOptions == null) { userDTOptions = {}; }
    if (crud_options == null) { crud_options = {}; }
    _activeTable = table;
    _crud_options = crud_options;
    const _activeTableSelector = `#${_activeTable.attr('id')}`;
    const _goToDom = '<div class="form-group" style="margin:10px;float:right" id="goToPage">' +
          '<label>'+I18n.t("will_paginate.go_to_label")+'</label> ' +
          '<input type="text" class="form-control" id="datatableGoTo" value="" maxlength="6" size="3">' +
    '</div>';    
    if ($.fn.dataTable.isDataTable(_activeTableSelector)) {
      // table was previously initialized, get from dt api
      _activeDatatable = $(_activeTableSelector).DataTable();
    } else {
      init_object_crud(_crud_options); // always call - #633
      // initialize only if object is in page
      if ($(_activeTableSelector).length === 0) { return; }
      _crud_options = $.extend(crud_options, {'target-table': _activeTableSelector});
      const dtOptions = {
        info: false,
        lengthChange: true,
        processing: true,
        searching: false,
        ordering: true,
        serverSide: true,
        deferRender: true,
        sortClasses: false,
        stateSave: _getStateSavePermission(),
        pageLength: page_length,
        columnDefs: _getColumnDefinitions(),
        order: _getDefaultSort(),
        dom: _setDom(),
        pagingType: _setPagingType(),
        language: _setLanguage(),
        DT_RowId: 'id',
        responsive: {
          details: {
            renderer(api, rowIdx) { return _child_row_renderer(api, rowIdx); }
          },
        		target: 0,
        		type: 'inline'
        },
        ajax: {
          url: _getDatasource(),
          error(xhr,status,error) { return _handleAjaxError(xhr,status,error); }
        },
        // TODO: expose as callable super when overriden
        // drawCallback: (settings) -> $.fn.dataTable.defaults.drawCallback.call(@)
        preDrawCallback(settings) {
          const api = this.api();
          // Fix for dt state save issue
          // Load first page incase there is no data and it is not the first page
          if (!api.data().any() && (api.page() > 0)) {
            setTimeout((function() {
              api.state.clear();
              api.page(0).draw(false);
              }), 50);
            return false;
          }
        },
        drawCallback(settings) {
          init_object_crud(_crud_options);
          initToolTip();
        
          if ($('#ultrawan-tree').length > 0) {
            init_object_crud({'target-table': '.treeview'});
            select_gateway_node();
          }

          const table_name = _activeTableSelector;
          const pagination_parent_dom  = $(`${table_name}_wrapper .dataTables_paginate`).parent();
          if ($(`${table_name}_wrapper .paginate_button`).length > 3) {
            $(`${table_name}_wrapper .dataTables_paginate`).css('display', 'block');
            if ($(pagination_parent_dom).find($("#goToPage")).length === 0) {
              $(_goToDom).appendTo($(pagination_parent_dom));
            } else {
              $(pagination_parent_dom).find($("#goToPage")).css('display','block');
            }
          } else {
            $(`${table_name}_wrapper .dataTables_paginate`).css('display', 'none');
            $(pagination_parent_dom).find($("#goToPage")).css('display','none');
          }
        },
        fnInitComplete(oSettings, json) {
          _addTableCollection(_activeDatatable, _crud_options);
          const table_name = this.selector;
          this.css({
            "width": "100%",
            "cellspacing": "0"});
          if ($(`${table_name}_wrapper .paginate_button`).length > 3) {
            $(`${table_name}_wrapper .dataTables_paginate`).css('display', 'block');
          } else {
            $(`${table_name}_wrapper .dataTables_paginate`).css('display', 'none');
          }

          $(".dataTables_length .form-group").css('display', 'inline-block');
          init_object_crud(_crud_options);
          this.prop('hidden', false);
        }
      };
      // finally, initialize with the default options + user overriden options
      _activeDatatable = table.DataTable($.extend(dtOptions, userDTOptions));
      // consolidate extensions to be made on the dt
      _setupTableCustomisations(_activeTable, _activeDatatable);
    }

    return _activeDatatable;
  };

  // return datatable instance and init options based on table id
  const getTableDetails = table => _tableCollection[table];

  // only return public functions
  return {
    fnDrawChild,
    getTableDetails,
    initDatatable
  };
})();

const goToPage = function(pageNumber) {
  const activeTable = $.fn.dataTable.tables()[0];
  if (activeTable) {
    const activeTableId = activeTable.id;
    const activeDT = $(`#${activeTableId}`).DataTable();
    activeDT.page(pageNumber - 1).draw(false);
  } else {
    if ($('div').find('[data-pjax-container]').length > 0) {
      if ($.support.pjax) {
        let pjax_url;
        const params = new URLSearchParams(window.location.search);
        params.delete('page');
        if (params.toString() !=="") {
          pjax_url = window.location.pathname+'?'+params;
        } else {
          pjax_url = window.location.pathname;
        }
        $.pjax({
          url: pjax_url,
          data: {page: pageNumber},
          container: '[data-pjax-container]',
          push: true
        });
      }
    }
  }
};

jQuery(function() {
  // handler to bind child events dynamically created when row is expanded
  $('body').on('click', 'table.dataTable tbody tr', function() {
    const activeTable = $(this).parents('table.dataTable').attr('id').toString();
    const tableDetails = dt.getTableDetails(activeTable);
    const activeDT = tableDetails.dataTable;
    const activeRow = activeDT.row(this);
    if (activeRow.child.isShown()) {
      init_object_crud(tableDetails.crud_options);
      initToolTip();
    }
  });

  return $('body').on('keyup', 'input[id="datatableGoTo"]', function(e) {
    const pageNumber = this.value;
    if (isNaN(pageNumber) || (pageNumber <= 0)) { return; }
    return setTimeout(function() { 
      goToPage(pageNumber);
    }
    , 1000);
  });
});


function __guardMethod__(obj, methodName, transform) {
  if (typeof obj !== 'undefined' && obj !== null && typeof obj[methodName] === 'function') {
    return transform(obj, methodName);
  } else {
    return undefined;
  }
}