/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var document_ready, init_datatable, init_search;

document_ready = function () {
  var crud_options;
  crud_options = {
    "distributor-link": {},
  };
  init_datatable(crud_options);
  return init_search();
};

init_datatable = function (crud_options) {
  return dt.initDatatable($("#distributor-links-table"), {}, crud_options);
};

init_search = function () {
  return $(".searchtoggle.distributor_links").on(
    "on_search_toggled",
    function () {
      init_advsearch("/distributor_links/advfind");
      create_advfind_buttons("distributor_link");
    }
  );
};

jQuery(function () {
  return run_ontag("distributor_links", function () {
    return document_ready();
  });
});
